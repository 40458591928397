import React, { useEffect, useState } from 'react';
import CircleImg from '../../cicleImage/RoundProductBox';
import style from '../../../styles/footerlinks.module.css';
import { Link } from 'react-router-dom';
import Slider from '../../SliderwithoutMenu';
import { BASE_URL } from '../../../config';
import axios from 'axios';


export default function Product() {

  const [category, setCategory] = useState([]);
  
  useEffect(() => {
    fetchCategory();
  
  }, [])

  const fetchCategory = async () => {
    await axios.get(BASE_URL + '/api/product-categories').then(({ data }) => {
      setCategory(data.data);
    })
  }



  return (
    <div>

      <div className={`col-md-12 col-xs-12 nopadding ${style.productBox}`}>
        <Slider>
          {category && category.map((data) => (
            <Link to="/our-products" state={{data:data.id}}>
              <div className={style.circleImgCont}>
                <CircleImg data={data.image} name={data.name && data.name} />
              </div>
            </Link>
          ))}
        </Slider>
      </div>
    </div>
  );
}