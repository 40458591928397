import React, { useState, useEffect } from 'react';
import ProductBox from '../../ProductBox/itemBox';
import ImageSlider from '../../TopCategorySlider';
import axios from 'axios';
import { BASE_URL } from '../../../config';
import style from '../../../styles/categorybox.module.css'


function RecentSearches() {

    const [category, setCategory] = useState([]);

    useEffect(() => {
        fetchCategory();

    }, [])

    const fetchCategory = async () => {
        await axios.get(BASE_URL + '/api/product-categories').then(({ data }) => {
            setCategory(data.data);
        })
    }

    return (
        <ImageSlider name="Top" boldname="Category">
            {category.map((data) => (
                <div className={`col-md-3 col-sm-6 col-xs-12  ${style.box} `}>
                    <div className={style.boxHeight} >
                        <img className={style.boxHeight} src={BASE_URL+'/storage/'+data.image} />
                    </div>
                    <div className={style.heading2}> {data.name}</div>
                </div>
            ))}
        </ImageSlider>
    )
}

export default RecentSearches;