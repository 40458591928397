import React, { useState, useEffect, useContext } from "react";
import style from '../../../styles/Cart.module.css';
import CloseIcon from '@mui/icons-material/Close';
import Radio from '@mui/material/Radio';
import { Link } from 'react-router-dom';
import FormControlLabel from '@mui/material/FormControlLabel';
import HelpOutlineTwoToneIcon from '@mui/icons-material/HelpOutlineTwoTone';
import { Tooltip } from "@mui/material";
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import { BASE_URL } from "../../../config";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../App";
import { Button } from "react-bootstrap";



export function AddItem(props) {

    const { handleInc, handleDec, counter } = props;

    return (
        <div>
            <div className={`flex ${style.counter}`}>
                <div className={`margin-left-right10 pointer ${style.font}`} onClick={handleDec}>-</div>
                <div>{counter}</div>
                <div className={`margin-left-right10 pointer ${style.font}`} onClick={handleInc}>+</div>
            </div>
        </div>
    )
}


export default function () {

    const [promo, setPromo] = useState(false);
    const [promoData, setPromoData] = useState(false);
    // console.log(promoData)
    const [discount, setDiscount] = useState(false);
    const [cartData, setCartData] = useState([]);
    const [cartDataOff, setCartDataOff] = useState([])
    const [cartShipping, setCartShipping] = useState([]);
    const [cartShippingOff, setCartOffShipping] = useState([]);
    const [shippingData, setShippingData] = useState([]);
    const [shippingCost, setShippingCost] = useState([]);
    const [pincode, setPincode] = useState();
    const [shippingType, setShippingType] = useState();
    const [shoppingInfo, setShoppingInfo] = useState();
    const token = localStorage.getItem('token');
    let machineId = localStorage.getItem('MachineId');
    const navigate = useNavigate();
    const [error, setError] = useState();
    const [couponCode, setCouponCode] = useState();
    const [promoDiscount, setPromoDiscount] = useState();
    const {item,setItem} = useContext(UserContext);



    useEffect(() => {
        FetchItems();
        FetchItemsUnAuth();
        getMachineId();
    }, [])


    function getMachineId() {

        let machineId = localStorage.getItem('MachineId');

        if (!machineId) {
            machineId = crypto.randomUUID();
            localStorage.setItem('MachineId', machineId);
        }

    }

    const FetchItems = async () => {
        if(token){
        axios.get(BASE_URL + '/api/get-cart', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }).then(({ data }) => {
            // console.log("data")
            setCartData(data.data.cart_details);
            // console.log(data.data.cart_details)
            setCartShipping(data.data.cart);
            setItem(data.data.cart.item)
        })
    }
    }

    const FetchItemsUnAuth = async () => {
        if(!token){

        const data = { "device_id": machineId }
        await axios.post(BASE_URL + '/api/get-cart-unauth', data).then(({ data }) => {

            setCartDataOff(data.data.cart_details);
            setCartOffShipping(data.data.cart);
            setItem(data.data.cart.item)
        })
    }
    }

    function handleOfflineDelete(id) {

        setError('');
        const data = { "device_id": machineId }
        axios.post(BASE_URL + '/api/remove-item-from-cart-unauth/' + id, data).then(({ data }) => {
            FetchItemsUnAuth();
            if (data.status === "success") {
                setError('')
                Swal.fire({
                    text: data.message,
                    icon: "success"
                })
                navigate("/x")
                document.getElementById("delete").reset();
            }
            if (data.status === "error") {
                Swal.fire({
                    text: data.message,
                    icon: "error"
                })
            }
        }).catch(({ response }) => {
            setError(response.data)
            if (response.status === "422") {
                setError(response.data.message)
            }
        });
    }

    function handleIncrementUnauth(id) {
        setError('');
        const data = { "device_id": machineId }
        axios.post(BASE_URL + '/api/increase-cart-item-quantity-unauth/' + id, data).then(({ data }) => {
            FetchItemsUnAuth();
            if (data.status === "success") {
                setError('')
                Swal.fire({
                    text: data.message,
                    icon: "success"
                })

                document.getElementById("delete").reset();
            }
            if (data.status === "error") {
                Swal.fire({
                    text: data.message,
                    icon: "error"
                })
            }
        }).catch(({ response }) => {
            setError(response.data)
            if (response.status === "422") {
                setError(response.data.message)
            }
        });
    }

    function handleDecrementUnauth(id) {
        setError('');
        const data = { "device_id": machineId }
        axios.post(BASE_URL + '/api/decrease-cart-item-quantity-unauth/' + id, data).then(({ data }) => {
            FetchItemsUnAuth();
            if (data.status === "success") {
                setError('')
                Swal.fire({
                    text: data.message,
                    icon: "success"
                })

                document.getElementById("delete").reset();
            }
            if (data.status === "error") {
                Swal.fire({
                    text: data.message,
                    icon: "error"
                })
            }
        }).catch(({ response }) => {
            setError(response.data)
            if (response.status === "422") {
                setError(response.data.message)
            }
        });
    }

    function handleDelete(id) {
        // alert(id)
        setError('');
        axios.post(BASE_URL + '/api/remove-item-from-cart/' + id, {}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }).then(({ data }) => {
            FetchItems();
            if (data.status === "success") {
                setError('')
                Swal.fire({
                    text: data.message,
                    icon: "success"
                })
                navigate("/")
                document.getElementById("otherService").reset();
            }
            if (data.status === "error") {
                Swal.fire({
                    text: data.message,
                    icon: "error"
                })
            }
        }).catch(({ response }) => {
            setError(response.data)
            if (response.status === "422") {
                setError(response.data.message)
            }
        });
    }

    function handleIncrement(id) {
        setError('');
        axios.post(BASE_URL + '/api/increase-cart-item-quantity/' + id, {}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }).then(({ data }) => {
            FetchItems();
            if (data.status === "success") {

                setError('')
                Swal.fire({
                    text: data.message,
                    icon: "success"
                })
                navigate("/x")
                document.getElementById("delete").reset();
            }
            if (data.status === "error") {
                Swal.fire({
                    text: data.message,
                    icon: "error"
                })
            }
        }).catch(({ response }) => {
            setError(response.data)
            if (response.status === "422") {
                setError(response.data.message)
            }
        });
    }

    function handleDecrement(id) {
        setError('');
        axios.post(BASE_URL + '/api/decrease-cart-item-quantity/' + id, {}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }).then(({ data }) => {
            FetchItems();
            if (data.status === "success") {
                setError('')
                Swal.fire({
                    text: data.message,
                    icon: "success"
                })
                navigate("/x")
                document.getElementById("delete").reset();
            }
            if (data.status === "error") {
                Swal.fire({
                    text: data.message,
                    icon: "error"
                })
            }
        }).catch(({ response }) => {
            setError(response.data)
            if (response.status === "422") {
                setError(response.data.message)
            }
        });
    }


    function CheckShoppingbyDelivery(quantity, amount) {
       if(token) {    
        setError('');
        axios.post(BASE_URL + '/api/check-pincode-delivery', {
            "pincode": pincode,
            "cart_quantity": quantity,
            "cart_amount": amount,
            "pincode_billing":pincode
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }).then(({ data }) => {
            setShippingData(data)
            setShippingCost(data.shippingCost)

            if (data.success === true) {
                setError('')
                Swal.fire({
                    text: data.message,
                    icon: "success"
                })
               
                document.getElementById("otherService").reset();
            }
            if (data.success === false) {
                Swal.fire({
                    text: data.errors.pincode,
                    icon: "error"
                })
            }
        }).catch(({ response }) => {
            setError(response.data)
            if (response.status === "422") {
                setError(response.data.message)
            }
        });
    }
    else{
        Swal.fire("Please Login First to Check Delivery Charges")
        navigate('/login',
           { state: {
                path:'/cart'
            }})
        
    }
    }


    function handlepromo(e) {
        setError('');
        setDiscount(true)
        axios.post(BASE_URL + '/api/apply-coupon', { 'coupon_code': couponCode }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }).then(({ data }) => {
            setPromoData(data);
            setPromoDiscount(data.data.cart)
            if (!couponCode) {
                Swal.fire({
                    text: "Coupon Code is required  ",
                    icon: "error"
                })
            }
            if (data.success === true) {
                setError('')
                Swal.fire({
                    text: data.message,
                    icon: "success"
                })
            }
            if (data.success === false) {
                Swal.fire({
                    text: data.message,
                    icon: "error"
                })
            }
        }).catch(({ response }) => {
            setError(response.data)
            if (response.status === 422) {
                setError(response.data.message)
                Swal.fire({
                    text: response.errors,
                    icon: "error"
                })
            }
        });
    }


    function handleDeletePromo(coupon) {
        setError('');
        axios.post(BASE_URL + '/api/remove-coupon', { "coupon_id": coupon }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }).then(({ data }) => {
            if (data.status === "success") {
                setError('')
                Swal.fire({
                    text: data.message,
                    icon: "success"
                })
                navigate("/")
                document.getElementById("otherService").reset();
            }
            if (data.status === "error") {
                Swal.fire({
                    text: data.message,
                    icon: "error"
                })
            }
        }).catch(({ response }) => {
            setError(response.data)
            if (response.status === "422") {
                setError(response.data.message)
            }
        });
    }



    const ProceedCheckout = () => {
        {token ? (
            shoppingInfo ? 
        navigate('/checkout',
            {
                state: {
                    shippingtype: shippingType,
                    shipping: shoppingInfo.name,
                    cartamount:cartShipping.total_price,
                    gstAmount: shoppingInfo.total_gst_amount,
                    shippingAmount: shoppingInfo.TotalShipCost,
                    shippingCode: shoppingInfo.id,
                    promoDiscount: promoDiscount ? promoDiscount.discount_amount : 0,
                    amountafterDiscount: promoDiscount ? promoDiscount.total_price_after_discount : shoppingInfo.totalCartAmount,
                    preDiscount: cartShipping.pre_discount && cartShipping.pre_discount, 
                    pincode: pincode,
                    quantity:cartShipping.quantity,
                    cartGstTotal: shoppingInfo.totalCartAmount 
                }
            }

        ) : Swal.fire("Please Select Delivery Proceed Further") ): Swal.fire("Please Login First to Proceed Further")
        }
    }

    return (
        <div>
            <div className='col-md-12 col-xs-12 nopadding'>
                <nav aria-label="breadcrumb" >
                    <ol className='breadcrumb'>
                        <li class="breadcrumb-item  breadcrumb-padding"><a href="/"><span>Home</span></a><img src="/Icons/SigleSideArrow.png" className="breadcrumb-icon" /> </li>
                        <li class="breadcrumb-item  breadcrumb-padding"><a href="#"><span>Shop</span></a><img src="/Icons/SigleSideArrow.png" className="breadcrumb-icon" /> </li>
                        <li className="breadcrumb-item-active" aria-current="page">Cart</li>
                    </ol>
                </nav>
            </div>
                <div className={`col-md-12 col-xs-12 ${style.container}`}>
                    <div className="col-md-8 col-xs-12 mt-3">
                    {item == 0 ? <div className="center mt-4">
            <h1> Please add products in to the cart </h1><Button variant="contained" className="contact-submit mt-4" onClick={() => navigate('/')}>Continue Shopping</Button>
             </div> : 
                        <div className="table-responsive">
                            <table className="table">
                                <thead className="table-header font-15">
                                    <tr>
                                        <th scope="col">Product </th>
                                        <th scope="col">Price</th>
                                        <th scope="col">Dicount</th>
                                        <th scope="col">Quantity</th>
                                        <th scope="col">Total</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody className="table-body font-15">
                                    {token ? cartData && cartData.map((data) => (
                                        <tr>
                                            <th><div className='flex'><img src={BASE_URL + '/storage/' + data.products.image} className={style.img} /> <div className={`font-15 ${style.productName}`}>{data.products.name && data.products.name}</div></div></th>
                                            <td><div className={`flex ${style.marginTop}`}><div className={`textSeconday ${style.price}`}>INR {data.product_options && data.product_options.mrp}</div></div></td>
                                            <td><div className={`flex ${style.marginTop}`}><div className={`textSeconday ${style.price}`}>INR {data.product_options && data.product_options.discount_amount}</div></div></td>
                                            <td>
                                                <AddItem handleDec={() => handleDecrement(data.id)} handleInc={() => handleIncrement(data.id)} counter={data.quantity && data.quantity} />
                                            </td>
                                            <td><div className={`flex ${style.marginTop}`}><div className={`textSeconday ${style.price}`}>INR  {data.product_options.price * data.quantity && data.product_options.price * data.quantity}</div></div></td>
                                            <td>{<CloseIcon className="pointer" sx={{ marginTop: '60%' }} onClick={() => handleDelete(data.id)} />}</td>
                                        </tr>
                                    )) :
                                        cartDataOff && cartDataOff.map((data) => (
                                            <tr>
                                                <th><div className='flex'><img src={BASE_URL + '/storage/' + data.products.image} className={style.img} /> <div className={`font-15 ${style.productName}`}>{data.products.name && data.products.name}</div></div></th>
                                                <td><div className={`flex ${style.marginTop}`}><div className={`textSeconday ${style.price}`}>INR {data.product_options && data.product_options.mrp}</div></div></td>
                                                <td><div className={`flex ${style.marginTop}`}><div className={`textSeconday ${style.price}`}>INR {data.product_options && data.product_options.discount_amount}</div></div></td>
                                                <td>
                                                    <AddItem handleDec={() => handleDecrementUnauth(data.id)} handleInc={() => handleIncrementUnauth(data.id)} counter={data.quantity && data.quantity} />
                                                </td>
                                                <td><div className={`flex ${style.marginTop}`}><div className={`textSeconday ${style.price}`}>INR  {data.product_options.price * data.quantity && data.product_options.price * data.quantity}</div></div></td>
                                                <td>{<CloseIcon className="pointer" sx={{ marginTop: '50%' }} onClick={() => handleOfflineDelete(data.id)} />}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
}
                    </div>
                    <div className={`col-md-4 col-xs-12 ${style.container}`}>
                        <div className={`${style.cartTotal} ${style.container}`}>
                            <div className="cart-summary">Cart Summary</div>
                            <div className={style.border}></div>

                            <div className={`flex space-between ${style.black}`}>
                                <div className="font-18">Subtotal:</div>
                                <div className="font-18">INR {item == 0 ? '0' : token ? cartShipping.total_price && cartShipping.total_price : cartShippingOff.total_price && cartShippingOff.total_price}</div>
                            </div>
                            <div className="font-13">Subtotal does not include applicable taxes</div>
                            {item == 0 ? '' :  
                            <>
                            <div className={style.border}></div>
                            <div className={style.underline} onClick={() => setPromo(!promo)}> Have a promocode?</div>
                            <div>
                                {promo ?
                                    <>
                                        <div className="col-md-12 nopadding marginTop">
                                            <div className="col-md-8 nopadding">
                                                <input
                                                    placeholder="Enter Code"
                                                    onChange={(e) => setCouponCode(e.target.value)}
                                                    className={style.inputPromo}
                                                />
                                            </div>

                                            <div className="col-md-4 noppading">
                                                <button className={style.Apply} onClick={handlepromo}> Apply</button>
                                            </div>
                                            
                                        </div>
                                       

                                        {promoData ?
                                            <>
                                                {promoData.success == 'true' ? ""
                                                    :
                                                    <div>
                                                        <div className={`pack-des ${style.congMob}`}>Congratulations! You saved $ 1000 on your order. </div>
                                                        <br />

                                                        <div className="flex" >
                                                            <div>{couponCode}</div>
                                                            <div>{<CloseIcon onClick={() => handleDeletePromo(cartShipping.coupon_id)} sx={{ marginTop: '50%' }} />}</div>
                                                        </div>
                                                    </div>}
                                            </>
                                            : ""}
                                    </>

                                    : ''}
                            </div>

                            <div className={`col-md-12 ${style.border}`}></div>
                            <div>
                                <label>
                                    <span className="font-15">Enter Pincode </span>
                                    <br />
                                    <div className="font-12 text-muted">* Enter your pincode to check delivery charges</div>
                                    <FormControl sx={{ width: 'auto' }} variant="outlined">
                                        <OutlinedInput
                                            id="outlined-adornment-weight"
                                            endAdornment={<InputAdornment position="end" className="pointer"><img src="/Icons/SearchRed.png" onClick={() => CheckShoppingbyDelivery(cartShipping.quantity, cartShipping.total_price)} /></InputAdornment>}
                                            aria-describedby="outlined-weight-helper-text"
                                            className={style.input}
                                            onChange={(e) => setPincode(e.target.value)}
                                            inputProps={{
                                                'aria-label': 'weight',
                                            }}
                                           sx={{fontSize:16}}
                                        />
                                    </FormControl>
                                </label>
                            </div>

                            <div className="col-md-12 col-xs-12 col-sm-12">
                                <p >{shippingData.message}</p>
                            </div>
                            <div className="col-md-12">
                                {shippingCost && shippingCost.map((data) => (
                                    <>
                                        <div className="PaymentMethod">
                                            <div className="col-md-12 col-xs-12 col-sm-12 nopadding">
                                                <span className="PaymentMethodCont">
                                                    <input type="radio" name="shipping" id="shippinng" className="radio-select" value={data.id} onClick={(e) => { setShippingType(e.target.value); setShoppingInfo(data); }} />
                                                    <label className="flex " forHtml="shippinng">{data.name} </label>
                                                    <a href="#" data-toggle="tooltip" title={'Deliver in ' + data.maximum_days + ' days'}><i className="fa fa-question-circle ms-2" aria-hidden="true"></i></a>
                                                </span>
                                            </div>
                                            <div className="col-md-12 col-xs-12 col-sm-12">
                                                <label className="right font-15">$100{data.in_state_charge}</label>
                                            </div>
                                        </div>
                                        <div>
                                        </div>
                                    </>
                                ))}
                            </div>
                            <div className="col-md-12 nopadding ">
                                {shippingType ?
                                    <>
                                        {shippingCost && shippingCost.map((data) => (
                                            <div className={style.plr}>
                                                {shoppingInfo.name == data.name ?
                                                    <div>
                                                        <div className="space-between mt-1">
                                                            <div className="font-15">Cart Amount: </div>
                                                            <div className="font-15">INR {data.totalCartAmount}</div>
                                                        </div>
                                                        <div className="space-between mt-1">
                                                            <div className="font-15">Shipping Cost : </div>
                                                            <div className="font-15">INR {data.TotalShipCost}</div>
                                                        </div>
                                                        <div className="space-between mt-1">
                                                            <div className="font-15">Gst Amount: </div>
                                                            <div className="font-15">INR {data.total_gst_amount}</div>
                                                        </div>
                                                    </div>

                                                    : ''
                                                }
                                            </div>
                                        ))}
                                    </>
                                    : ""}
                            </div>


                            <div className={`col-md-12 ${style.border}`}></div>
                            <div className="col-md-12">
                                <div className="flex space-between font-18">
                                    <div>Total</div>
                                    <div>INR {token ? shoppingInfo && shoppingInfo.id ? shoppingInfo.totalCartAmount && shoppingInfo.totalCartAmount : cartShipping.total_price && cartShipping.total_price : cartShippingOff.total_price && cartShippingOff.total_price}</div>
                                </div>
                            </div>
                            {/* <div className={style.border}></div> */}

                            <button className={style.checkoutButton} onClick={() => ProceedCheckout()}>PROCEED TO CHECKOUT</button>
                            </> }
                        </div>
                    </div>
                </div>
        </div>
    )
}