import React, { useEffect, useState } from 'react';
import style from '../../../styles/blog.module.css';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import { BASE_URL } from '../../../config';
import axios from 'axios';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const data = [
    {
        id: 1,
        img: '/Image/perfumeImage1.png',
        date: '13, Nov, 2021',
        by: 'Spacing Tech',
        name: 'Donec Tellus Nulla Lorem',
        detail: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit doli. Aenean commodo ligula eget dolor. ...'
    },
    {
        id: 2,
        img: '/Image/perfumeImage2.png',
        date: '13, Nov, 2021',
        by: 'Spacing Tech',
        name: 'Donec Tellus Nulla Lorem',
        detail: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit doli. Aenean commodo ligula eget dolor. ...'
    },
    {
        id: 3,
        img: '/Image/perfumeImage3.png',
        date: '13, Nov, 2021',
        by: 'Spacing Tech',
        name: 'Donec Tellus Nulla Lorem',
        detail: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit doli. Aenean commodo ligula eget dolor. ...'
    },
    {
        id: 4,
        img: '/Image/perfumeImage4.png',
        date: '13, Nov, 2021',
        by: 'Spacing Tech',
        name: 'Donec Tellus Nulla Lorem',
        detail: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit doli. Aenean commodo ligula eget dolor. ...'
    }
]

const recentPost = [
    {
        id: 1,
        img: '/Image/perfumeImage4.png',
        name: 'Donec Tellus Nulla Lorem',
        date: 'Nov 13, 2021'
    },
    {
        id: 2,
        img: '/Image/perfumeImage4.png',
        name: 'Donec Tellus Nulla Lorem',
        date: 'Nov 13, 2021'
    },
    {
        id: 3,
        img: '/Image/perfumeImage4.png',
        name: 'Donec Tellus Nulla Lorem',
        date: 'Nov 13, 2021'
    },
    {
        id: 4,
        img: '/Image/perfumeImage4.png',
        name: 'Donec Tellus Nulla Lorem',
        date: 'Nov 13, 2021'
    },
    {
        id: 5,
        img: '/Image/perfumeImage4.png',
        name: 'Donec Tellus Nulla Lorem',
        date: 'Nov 13, 2021'
    },
    {
        id: 6,
        img: '/Image/perfumeImage4.png',
        name: 'Donec Tellus Nulla Lorem',
        date: 'Nov 13, 2021'
    }
]

export default function Blog() {
    const navigate = useNavigate()
    const [blogdata, setBlogData] = useState({});
    const [recentBlog, SetRecentBlog] = useState([]);
    const [search, setSearch] = useState();
    let { url } = useParams();
    useEffect(() => {
        fetchcarrier();
        fetchRecentBlog();
    }, [recentBlog])


    const fetchcarrier = async () => {
        await axios.get(BASE_URL + '/api/blogdetail/' + url).then(({ data }) => {
            setBlogData(data.data)
        })
    }

    const fetchRecentBlog = async () => {
        await axios.get(BASE_URL + '/api/recent-blogs').then(({ data }) => {
            SetRecentBlog(data.data);
        })
    }

    const bySearch = (user, search) => {
        if (search) {
            return user.title.toLowerCase().includes(search.toLowerCase());
        } else return user;
    };


    const filteredList = (recentBlog, search) => {
        return recentBlog
            .filter(user => bySearch(user, search))
    };

    return (
        <div>
            <div className='col-md-12 col-xs-12 nopadding'>
                <nav aria-label="breadcrumb" >
                    <ol className='breadcrumb'>
                        <li class="breadcrumb-item  breadcrumb-padding"><a href="#"><span>Home</span></a><img src="/Icons/SigleSideArrow.png" className="breadcrumb-icon" /> </li>
                        <li className="breadcrumb-item-active" aria-current="page">Blogs & Articles</li>
                    </ol>
                </nav>
            </div>
            <div className={style.padding}>
                <div className={`col-md-12 col-xs-12 ${style.container}`}>
                    <div className={`col-md-3 col-xs-12 ${style.LeftPanel}`}>
                        <div>
                            <div className={style.subHeadings}>Search</div>
                            <FormControl sx={{ width: '100%' }} variant="outlined">
                            <input className='input' placeholder='Search' onChange={(e) => { setSearch(e.target.value) }} />
                                {/* <OutlinedInput
                                    id="outlined-adornment-weight"
                                    endAdornment={<InputAdornment position="end"><img src="/Icons/SearchRed.png" /></InputAdornment>}
                                    aria-describedby="outlined-weight-helper-text"
                                    inputProps={{
                                        'aria-label': 'weight',
                                    }}
                                /> */}
                            </FormControl>
                        </div>
                        <div className={style.borderBottom}></div>
                        <div className={`${style.subHeadings} ${style.marginTopBottom}`}>Recent Post</div>
                        {filteredList(recentBlog, search).length == 0 ? <h3 className='bold mb-4'>No Blog Found</h3> : 
                        <>
                        {filteredList(recentBlog, search).map(user => (
                            <>
                                <div className='flex pointer' onClick={() => navigate('/blogdetail/' + user.url)}  >
                                    <img className={style.recentPostImages} src={BASE_URL + '/storage/' + user.image} />
                                    <div className={style.recentPostName}>
                                        <div className={style.name}>{user.title}</div>
                                        <div>{user.created_at}</div>
                                    </div>
                                </div>
                                <div className={style.borderBottom}></div>
                            </>
                        ))}
                        </>
}
                        {/* {recentBlog && recentBlog.map((data) => (
                            <>
                                <div className='flex pointer' onClick={() => navigate('/blogdetail/' + data.url)}  >
                                    <img className={style.recentPostImages} src={BASE_URL + '/storage/' + data.image} />
                                    <div className={style.recentPostName}>
                                        <div className={style.name}>{data.title}</div>
                                        <div>{data.created_at}</div>
                                    </div>
                                </div>
                                <div className={style.borderBottom}></div>
                            </>
                        ))} */}
                        <div>
                            <div className={style.subHeadings}>Tags</div>
                            <div className='flex'>
                                <button className={style.tagButton}>#Blog</button>
                                <button className={style.tagButton}>#News</button>
                                <button className={style.tagButton}>#Fashion</button>
                            </div>
                        </div>

                    </div>
                    <div className='col-md-9 col-xs-12'>
                        <img src={BASE_URL + '/storage/' + blogdata.image} alt="Perfume" className={style.detailImage} />
                        <h2 className='bold'>{blogdata.title && blogdata.title}</h2>
                        <ul>
                            <div dangerouslySetInnerHTML={{ __html: blogdata.content }}></div>
                            <li className={`${style.blogGreyBlock} ${style.redBorder}`} >
                                <div className={style.commentBlock}>
                                    <div className={style.center}>
                                        <img src="/Icons/Comment.png" />
                                        <h5 className='bolder RedText'>{blogdata.author && blogdata.author}</h5>
                                        <div className={`flex ${style.center}`}>
                                            <div className='margin-left-right10 RedText'><img src="/Icons/date.png" alt="date" className='margin-left-right5' />Apr 09, 2022</div>
                                            {/* <div className='margin-left-right10 RedText'><img src="/Icons/CircleComment.png" alt="date" className='margin-left-right5' />0 Comment</div> */}
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <div className={`flex ${style.marginLeft}`}>
                            <button className={style.hashButtons}>#Blog</button>
                            <button className={style.hashButtons}>#News</button>
                            <button className={style.hashButtons}>#Fashion</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}