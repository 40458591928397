import React, { useState,useEffect } from 'react';
import { Button, Checkbox } from '@mui/material'
import IconButton from "@mui/material/IconButton";
import FormControlLabel from '@mui/material/FormControlLabel';
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import style from '../../../styles/login.module.css';
import { Link, useNavigate,useParams } from 'react-router-dom';
import GoogleIcon from '@mui/icons-material/Google';
import { BASE_URL } from '../../../config';
import axios from 'axios';
import Swal from 'sweetalert2';


const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const Login = () => {
    const [error,setError] = useState({});
    const [password,setPassword] = useState('');
    const [confirmPassword,setConfirmPassword] = useState('');
    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    let { slug } = useParams();
  let machineId = localStorage.getItem('MachineId');

    function handleSubmit(e){
        e.preventDefault();
        setError('');
        axios.post(BASE_URL+'/api/reset-password',{
            "password":"1234567890",
            "password_confirmation":"1234567890",
            "token":slug
        }).then(({data})=>{
          if(data.status === true){
            setError('')
            Swal.fire({
              text:data.meassge,
              icon:"success"
            })
            navigate("/login")
          } 
          if(data.status === 'false'){
            Swal.fire({
              text:data.message,
              icon:"error"
            })
          }
        }).catch(({response})=>{
          setError(response.data.message)
          if(response.status_code==="422"){
            setError(response.data.message)
          }
          if(response.status === 401){
            Swal.fire({
              text:response.data.message,
              icon:"error"
            })
          }
        });
      }



    return (
        <>
            <div className={`col-md-12 col-xs-12 col-sm-12 center ${style.container}`}>
                <div className={`card ${style.card}`}>
                    <div className={`${style.loginText}`}>Reset Password</div> 
                    <form>
                        <input
                            className={`mt-2 ${style.input}`}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Enter New Password"
                            type="email"
                            name="email"
                            required

                        />
                        <input
                            className={`mt-2 ${style.input}`}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            placeholder="Confirm New Password"
                            type="email"
                            name="email"
                            required

                        />                      
                        <Link onClick={handleSubmit}> <button className={style.loginBtn}>Reset Password</button> </Link>                      
                    </form> 
                </div>
            </div>
        </>
    )
}

export default Login;