import React,{useState, useEffect} from 'react'; 
import style from '../../../styles/dashboard.module.css';
import {BASE_URL} from '../../../config';
import axios from 'axios';
import Sidebar from '../../Sidebar/Sidebar'


export default function Dashboard() {

  const [activeData, setActiveData] = useState([]);
  const token = localStorage.getItem('token');

  useEffect(() => {
      ActiveOrderData()
  }, [])

  const ActiveOrderData = async () => {
      axios.post(BASE_URL + '/api/orders', { "order_status": "active" }, {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token
          }
      }).then(({ data }) => {
          setActiveData(data.data);
      })
  }


  return (

    <div className={`col-md-12 col-xs-12 nopadding`}>
          <div className='col-md-12 col-xs-12 nopadding'>
                <nav aria-label="breadcrumb" >
                    <ol className='breadcrumb'>
                        <li class="breadcrumb-item  breadcrumb-padding"><a href="#"><span>Home</span></a><img src="/Icons/SigleSideArrow.png" className="breadcrumb-icon" /> </li>
                        <li class="breadcrumb-item  breadcrumb-padding"><a href="#"><span>Shop</span></a><img src="/Icons/SigleSideArrow.png" className="breadcrumb-icon" /> </li>
                        <li className="breadcrumb-item-active" aria-current="page">
                        
                             Dashboard
                             
                            </li>
                    </ol>
                </nav>
            </div>
      <div className={`col-md-3 col-xs-12 nopadding `}>
        <Sidebar />
      </div>
      <div className={`col-md-9 col-xs-12 col-sm-12 ${style.container}`} >
      <div className={`col-md-12 col-xs-12 nopadding ${style.header}`}>
        <h3 className={style.heading}>
          Dasboard
        </h3>
        <div className='borderBottom'></div>
 
      <div className={style.padding20}>
        <div className={style.headingText}> Recent Order List </div>

        <div className='table-responsive mt-4'>
        <table className="table">
          <thead className="table-header">
            <tr>
              <th scope="col"> Date/Time </th>
              <th scope="col">Order Id</th>
              <th scope="col">Invoice No</th>
              <th scope="col"> Bill Amount</th>
              <th scope="col">Payment Status</th>
              <th scope="col">status</th>
            </tr>
          </thead>
          <tbody className='table-body'>
            {activeData && activeData.map(i => (
              <tr>
                <th><div className={style.dateTime}>{i.order_date_time} </div></th>
                <td><div className={style.order}>{i.order_number && i.order_number}</div></td>
                <td><div className={style.invoice}>{i.invoice_number && i.invoice_number}</div></td>
                <td><div className={style.bill}>{i.order_amount && i.order_amount}</div></td>
                <td><div className={style.bill}>{i.payment_status && i.payment_status}</div></td>
                <td><div className={style.bill}>{i.order_status && i.order_status}</div></td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      </div>
      </div>
      </div>
    </div>
  )
}