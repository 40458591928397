import React, { useState, useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import style from '../../styles/header.module.css';
import axios from 'axios';
import { BASE_URL } from '../../config';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function Header() {
    const [headerData, setHeaderData] = useState('')
    const navigate = useNavigate();

    useEffect(()=>{
        featchHeaderData();
    },[])

    const featchHeaderData = async()=>{
        await axios.get(BASE_URL+'/api/header-settings').then(({data})=>{
            setHeaderData(data.data);
        })  
    }

    return (
        <div className={style.DesktopView}>
             <Helmet>
                <link rel="icon" href={BASE_URL + '/storage/' + headerData.favicon} />
            </Helmet>
        <div className={`col-md-12 col-sm-12 col-xs-12 ${style.infoHeaderContainer} `}>
            <div className='col-md-6 col-sm-6 col-xs-12'>
                <div className={style.leftPanel}>
                <div className={style.margin}>
                        <div className="pointer flex">
                         <img src="/Icons/location.png" alt="Location" className={style.locationIcon} />
                       <Link to="/Contact"> <div className={style.fontStyle}> Find Store</div> </Link>
                        </div>
                    </div>
                    <div className={style.borderLeft}>|</div>
                    <div className={style.margin}>
                        
                    <div className="pointer flex">
                    <img src="/Icons/mail.png" alt="Mail" className={style.mailIcon} />
                       <a  href={"mailto:" + headerData.email}><div className={style.fontStyle}>{headerData.email && headerData.email}</div> </a> 
                        </div>
                    </div>
                   
                    
                </div>
            </div>
            <div className='col-md-6 col-sm-6 col-xs-12'>
                <div className={style.rightPanel}>
                <div className={style.margin}>
                    <div className="pointer flex">
                    <img src="/Icons/call.png" alt="Call" className={style.callIcon} />
                        <a href={"tel:" + headerData.tollfree_number}><div className={style.fontStyle}>{headerData.tollfree_number && headerData.tollfree_number}</div></a>
                        </div>
                    </div>
                    <div className={style.borderLeft}>|</div>
                    <div className={style.margin}>
                    <div className="pointer flex">
                    <img src="/Icons/location.png" alt="Location" className={style.locationIcon} />
                        <div className={style.fontStyle} onClick={() => navigate('/my-orders')}>Track your order</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}

export default Header;