import React, { useState, useEffect } from 'react';
import style from '../../../styles/Changepassword.module.css';
import axios from "axios";
import { BASE_URL } from "../../../config";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import Sidebar from '../../Sidebar/Sidebar';

export default function Password() {

  const [oldpassword, setOldPassword] = useState('');
  const [newpassword, setNewPassword] = useState('');
  const [confirmnewpassword, setNewconfirmPassword] = useState('');
  const [error, setError] = useState([]);

  const navigate = useNavigate();
  const token = localStorage.getItem('token');

  function handleSubmit(e) {
    e.preventDefault();
    setError('');
    const data = { 'old_password': oldpassword, 'new_password': newpassword, 'new_password_confirmation': confirmnewpassword };
    axios.post(BASE_URL + '/api/update-password', data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      if (data.status === "success") {
        setError('')
        localStorage.setItem('token', data.token)
        localStorage.setItem('userdata', JSON.stringify(data.data))
        Swal.fire({
          text: data.message,
          icon: "success"
        })
        localStorage.removeItem("token");
        localStorage.removeItem("userdata");
        navigate("/")

        document.getElementById("changepassword").reset();
      }
      if (data.status === "error") {
        Swal.fire({
          text: data.message,
          icon: "error"
        })
      }
    }).catch(({ response }) => {
      setError(response.data.message)
      if (response.status_code === "422") {
        setError(response.data.message)
      }
    });
  }


  return (
    <div className={`col-md-12 col-xs-12 nopadding`}>
      <div className='col-md-12 col-xs-12 nopadding'>
        <nav aria-label="breadcrumb" >
          <ol className='breadcrumb'>
            <li class="breadcrumb-item  breadcrumb-padding"><a href="#"><span>Home</span></a><img src="/Icons/SigleSideArrow.png" className="breadcrumb-icon" /> </li>
            <li class="breadcrumb-item  breadcrumb-padding"><a href="#"><span>Shop</span></a><img src="/Icons/SigleSideArrow.png" className="breadcrumb-icon" /> </li>
            <li className="breadcrumb-item-active" aria-current="page">

              Change Password

            </li>
          </ol>
        </nav>
      </div>
      <div className={`col-md-3 col-xs-12 nopadding`}>
        <Sidebar />
      </div>
      <div className={`col-md-9 col-xs-12 col-sm-12 ${style.container}`} >
      <div className={`col-md-12 col-xs-12 nopadding ${style.header}`}>
        <h3 className={style.heading}>
          Change Password
        </h3>
      </div>
     
      <div className={`col-md-12 col-xs-12 ${style.margin}`}>
        <input placeholder='Enter your old password' className="input" onChange={(e) => setOldPassword(e.target.value)} />
        <div className={` flex ${style.text}`}>
          <small>Forgot Password?</small>
          <small>Click here</small>
        </div>
      </div>
      <div className={`col-md-9 col-xs-12 nopadding ${style.margin}`}>
        <div className='col-md-6 col-xs-12'>
          <input placeholder='Enter New Password' className="input" onChange={(e) => setNewPassword(e.target.value)} />
        </div>
        <div className='col-md-6 col-xs-12'>
          <input placeholder='Confirm New Password' className="input" onChange={(e) => setNewconfirmPassword(e.target.value)} />
        </div>
      </div>
      <div className={`col-md-12 col-xs-6 ${style.update}`}>
        <button className={style.button} onClick={handleSubmit}>Update Password</button>
      </div>
    </div>

</div>

  )
}