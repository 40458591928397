import React, { useState } from 'react';
import { Drawer } from '@mui/material';
import style from '../../styles/header.module.css';
import ButtonIcon from '../OtherPerfumes/OtherPerfumes';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';


function MenuHeader() {

    return (
        <div className={style.DesktopView}>
        <div className={`col-md-12 col-xs-12 col-lg-12 nopadding ${style.menuHeaderContainer}`}>
            <div className={style.MobileViewStyle}>
                <div className={style.margin}>
                    <ButtonIcon />
                </div>
                    <div className={style.DesktopView}>
                        <div className={`flex ${style.menu}`}>
                            <Link to="/"><div className={style.menuFont}>Home</div></Link>
                            <div className={style.saperation}></div>
                            <Link  to="/About"><div className={style.menuFont}>About Us</div></Link>
                            <div className={style.saperation}></div>
                            <Link to="/our-products" state={{data:0}}><div className={style.menuFont}>Shop Online</div></Link>
                            <div className={style.saperation}></div>
                            <Link to="/FAQs" state={{data:0}}><div className={style.menuFont}>FAQ</div></Link>
                            <div className={style.saperation}></div>
                            <Link to="/Contact"><div className={style.menuFont}>Contact Us</div></Link>
                            <div className={style.saperation}></div>
                            <Link  to="/feedback"><div className={style.menuFont}>Feedback</div></Link>
                        </div>
                    </div>
            </div>
        </div>
        </div>
    )
}
export default MenuHeader;