import React from 'react';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import style from '../../../styles/Changepassword.module.css';


const quesData1 = [
    {
        heading: '01. The Order',
        ques1: 'When do I receive my order? ',
        ans1: 'When placing the order, a day of shipment is indicated. After the order has been placed, the same delivery time will also be stated on the order confirmation. It is therefore never possible that during the order, the shipping day on the website, is different than on the order confirmation.',
        ques2: 'I now see the longer delivery time of (a part of) my order. How can I cancel it? ',
        ans2: 'If the order has a longer delivery time than you had previously seen, it is of course possible to cancel (a part of) the order. For this you can contact our customer service. They will cancel the order for you. The purchase amount will be back on your bank account within two working days. When an order has already been shipped, it can no longer be cancelled. ',
        ques3: 'When will I receive the invoice for my order? ',
        ans3: 'When you have paid for the order, you will not automatically receive an invoice for your order. If you wish to receive an invoice, this can be done in two ways.The first way is through your account at our store. When you log in to your account you can see you orders and download the invoice. '
    },
    {
        heading: '02. Shipment',
        ques1: 'When do I receive my order? ',
        ans1: 'When placing the order, a day of shipment is indicated. After the order has been placed, the same delivery time will also be stated on the order confirmation. It is therefore never possible that during the order, the shipping day on the website, is different than on the order confirmation.',
        ques2: 'I now see the longer delivery time of (a part of) my order. How can I cancel it? ',
        ans2: 'If the order has a longer delivery time than you had previously seen, it is of course possible to cancel (a part of) the order. For this you can contact our customer service. They will cancel the order for you. The purchase amount will be back on your bank account within two working days. When an order has already been shipped, it can no longer be cancelled. ',
        ques3: 'When will I receive the invoice for my order? ',
        ans3: 'When you have paid for the order, you will not automatically receive an invoice for your order. If you wish to receive an invoice, this can be done in two ways.The first way is through your account at our store. When you log in to your account you can see you orders and download the invoice. ',
    },
    {
        heading: '03. The Order',
        ques1: 'When do I receive my order? ',
        ans1: 'When placing the order, a day of shipment is indicated. After the order has been placed, the same delivery time will also be stated on the order confirmation. It is therefore never possible that during the order, the shipping day on the website, is different than on the order confirmation.',
        ques2: 'I now see the longer delivery time of (a part of) my order. How can I cancel it? ',
        ans2: 'If the order has a longer delivery time than you had previously seen, it is of course possible to cancel (a part of) the order. For this you can contact our customer service. They will cancel the order for you. The purchase amount will be back on your bank account within two working days. When an order has already been shipped, it can no longer be cancelled. ',
        ques3: 'When will I receive the invoice for my order? ',
        ans3: 'When you have paid for the order, you will not automatically receive an invoice for your order. If you wish to receive an invoice, this can be done in two ways.The first way is through your account at our store. When you log in to your account you can see you orders and download the invoice. ',
    },
    {
        heading: '04. Returns, Exchanges and Complaints',
        ques1: 'When do I receive my order? ',
        ans1: 'When placing the order, a day of shipment is indicated. After the order has been placed, the same delivery time will also be stated on the order confirmation. It is therefore never possible that during the order, the shipping day on the website, is different than on the order confirmation.',
        ques2: 'I now see the longer delivery time of (a part of) my order. How can I cancel it? ',
        ans2: 'If the order has a longer delivery time than you had previously seen, it is of course possible to cancel (a part of) the order. For this you can contact our customer service. They will cancel the order for you. The purchase amount will be back on your bank account within two working days. When an order has already been shipped, it can no longer be cancelled. ',
        ques3: 'When will I receive the invoice for my order? ',
        ans3: 'When you have paid for the order, you will not automatically receive an invoice for your order. If you wish to receive an invoice, this can be done in two ways.The first way is through your account at our store. When you log in to your account you can see you orders and download the invoice. ',
    }

]

function FAQ() {
    return (
        <div>
            <div className='col-md-12 col-xs-12 nopadding'>

                <nav aria-label="breadcrumb" >
                    <ol className='breadcrumb'>
                        <li class="breadcrumb-item  breadcrumb-padding"><a href="#"><span>Home</span></a><img src="/Icons/SigleSideArrow.png" className="breadcrumb-icon" /> </li>
                        <li className="breadcrumb-item-active" aria-current="page">Faq</li>
                    </ol>
                </nav>
            </div>
            <div className={`col-md-12 col-xs-12 ${style.margin}`}>
                <div className={style.center}>
                    <HelpOutlineOutlinedIcon sx={{ fontSize: 50 }} />
                    <h1 className='bold'>Frequently Asked Question</h1>
                </div>

                {quesData1.map(i => (
                    <div className={`col-md-6 col-xs-12 ${style.data}`}>
                        <h3 className='bold'>{i.heading}</h3><br />
                        <div className='bold'>{i.ques1}</div>
                        <div>{i.ans1}</div><br />
                        <div className='bold'>{i.ques2}</div>
                        <div>{i.ans2}</div><br />
                        <div className='bold'>{i.ques3}</div>
                        <div>{i.ans3}</div>
                    </div>
                ))}

            </div>
        </div>
    )
}

export default FAQ