import React from 'react';
import Header1 from './infoheader';
import Header2 from './searchHeader';
import Header3 from './menuHeader';
import MobileViewHeader from './MobileHeader';

function MainHeader(){
    return(
        <div>
            <Header1 />
            <Header2 />
            <Header3 />
            <MobileViewHeader />
        </div>
    )
}

export default MainHeader;