import React, { useState , useEffect} from "react";
import '../../../styles/Address.css';
import AddIcon from '@mui/icons-material/Add';
import Modal from '@mui/material/Modal';
import Checkbox from '@mui/material/Checkbox';
import axios from 'axios';
import { BASE_URL } from "../../../config";
import Sidebar from '../../Sidebar/Sidebar';
import AddressModal from "../PaymentSelection/AddressModal";
import { Navigate } from "react-router-dom";


export default function MyAddress() {

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const token = localStorage.getItem('token')
    const userData = JSON.parse(localStorage.getItem('userdata'))
    console.log(userData)
    

    const [shippingAddress, setShippingAddress] = useState();
    const [billingAddress, setBillingAddress] = useState()
  
    useEffect(() => {
        Address();
    }, [])
  
    const Address = async () => {
        await axios.get(BASE_URL + '/api/customer-address', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }).then(({ data }) => {
            setShippingAddress(data.data.shipping);
            setBillingAddress(data.data.billing)
        })
    }


    return (
        <div className="col-md-12 col-xs-12 nopadding">
               <div className='col-md-12 col-xs-12 nopadding'>
                <nav aria-label="breadcrumb" >
                    <ol className='breadcrumb'>
                        <li class="breadcrumb-item  breadcrumb-padding"><a href="#"><span>Home</span></a><img src="/Icons/SigleSideArrow.png" className="breadcrumb-icon" /> </li>
                        <li class="breadcrumb-item  breadcrumb-padding"><a href="#"><span>Shop</span></a><img src="/Icons/SigleSideArrow.png" className="breadcrumb-icon" /> </li>
                        <li className="breadcrumb-item-active" aria-current="page">
                        
                             My Address
                             
                            </li>
                    </ol>
                </nav>
            </div>
      <div className={`col-md-3 col-xs-12 nopadding `}>
        <Sidebar />
      </div>
      <div className="wishCon col-md-9 col-xs-12 col-sm-12">
            <div className="col-md-12 col-xs-12 nopadding header">
                <div className="heading">
                    <h3>My Address</h3>
                    <button className='addAddress flex' onClick={handleOpen}><AddIcon /> ADD ADDRESS</button>
                </div>
            </div>
            {/* <div className="col-md-12 col-xs-12" >
                <div className="col-md-6 col-xs-12">
                    <div className="address-1">
                        <div className="flex space-between">
                            <div>{userData.name && userData.name}</div>
                        
                        </div>
                        <div className="textSecondary width70">{userData.shipping_address && userData.shipping_address}</div>
                    </div>
                </div>
                <div className="col-md-6 col-xs-12 ">
                    <div className="address-2">
                        <div className="flex space-between">
                            <div>{shippingAddress[0].name && shippingAddress[0].name}</div>
                      
                        </div>
                        <div className="textSecondary width70">{shippingAddress[0].address && shippingAddress[0].address}</div>
                    </div>
                </div>
            </div> */}
            <div className="col-md-12 col-xs-12 marginTop marginBottom">
                
                    <div className="col-md-6 col-xs-12">
                    <div className="address-head"> Billing Address</div>

                    {billingAddress && billingAddress.map((data) => (
                    <div className="address-details mt-2">
                        <div className="flex space-between">
                            {/* <div className="address-head">  Address</div> */}
                            {/* <button className="primaryEdit" ><img src="/Icons/EditRed.png" /></button> */}
                        </div>
                        <div className="flex">
                            <div> Name: </div>
                            <div className="textSecondary marginLeft">{data.name && data.name}</div>
                        </div>
                       
                        <div className="flex marginTop">
                            <div>Address Type: </div>
                            <div className="textSecondary marginLeft">{data.address_type && data.address_type}</div>
                        </div>
                        <div className="marginTop">
                            <div> Address: </div>
                            <div className="textSecondary marginLeft">{data.address && data.address}</div>
                        </div>
                        <div className="flex marginTop">
                            <div>Country: </div>
                            <div className="textSecondary marginLeft">{data.country.name && data.country.name}</div>
                        </div>
                        <div className="flex marginTop">
                            <div>State: </div>
                            <div className="textSecondary marginLeft">{data.state.name && data.state.name}</div>
                        </div>
                        <div className="flex marginTop">
                            <div>City: </div>
                            <div className="textSecondary marginLeft">{data.city.name && data.city.name}</div>
                        </div>
                        <div className="flex marginTop">
                            <div>Zip Code: </div>
                            <div className="textSecondary marginLeft">{data.pincode && data.pincode}</div>
                        </div>
                    </div>
                         ))}
                </div>

           
                
                <div className="col-md-6 col-xs-12">
                    <div className="address-head"> Shipping Address</div>
                {shippingAddress && shippingAddress.map((data) => (
                    <div className="address-details mt-2">
                        <div className="flex space-between">
                            {/* <div className="address-head"> Address</div>
                            <button className="primaryEdit"><img src="/Icons/EditRed.png" /></button> */}
                        </div>
                        <div className="flex">
                            <div> Name: </div>
                            <div className="textSecondary marginLeft">{data.name && data.name}</div>
                        </div>
                       
                        <div className="flex marginTop">
                            <div>Address Type: </div>
                            <div className="textSecondary marginLeft">{data.address_type && data.address_type}</div>
                        </div>
                        <div className="marginTop">
                            <div> Address: </div>
                            <div className="textSecondary marginLeft">{data.address && data.address}</div>
                        </div>
                        <div className="flex marginTop">
                            <div>Country: </div>
                            <div className="textSecondary marginLeft">{data.country.name && data.country.name}</div>
                        </div>
                        <div className="flex marginTop">
                            <div>State: </div>
                            <div className="textSecondary marginLeft">{data.state.name && data.state.name}</div>
                        </div>
                        <div className="flex marginTop">
                            <div>City: </div>
                            <div className="textSecondary marginLeft">{data.city.name && data.city.name}</div>
                        </div>
                        <div className="flex marginTop">
                            <div>Zip Code: </div>
                            <div className="textSecondary marginLeft">{data.pincode && data.pincode}</div>
                        </div>
                    </div>
                         ))}
                </div>
            </div>
        </div>
        <AddressModal showAdd={open} setShowAdd={setOpen} />
        </div>
    )
}