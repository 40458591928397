import React,{useEffect, useState} from 'react';
import style from '../../../styles/footerlinks.module.css';
import axios from 'axios';
import { BASE_URL } from '../../../config';

// const PolicyData = [
//     {
//         heading:'Free & Easy Return',
//         point1:'As Sample Store uses some of the latest graphics designs which may not be supported in lower version of browsers designs which may not be supported in lower version of browsers',
//         point2:'As Sample Store uses some of the latest graphics designs which may not be supported in lower version of browsers designs which may not be supported in lower version of browsers',
//         point3:'As Sample Store uses some of the latest graphics designs which may not be supported in lower version of browsers designs which may not be supported in lower version of browsers',
//     },
//     {
//         heading:'Return Policy 7 Day',
//         point1:'As Sample Store uses some of the latest graphics designs which may not be supported in lower version of browsers designs which may not be supported in lower version of browsers',
//         point2:'As Sample Store uses some of the latest graphics designs which may not be supported in lower version of browsers designs which may not be supported in lower version of browsers',
//         point3:'As Sample Store uses some of the latest graphics designs which may not be supported in lower version of browsers designs which may not be supported in lower version of browsers',
//     },
//     {
//         heading:'What is a Return Policy',
//         point1:'As Sample Store uses some of the latest graphics designs which may not be supported in lower version of browsers designs which may not be supported in lower version of browsers',
//         point2:'As Sample Store uses some of the latest graphics designs which may not be supported in lower version of browsers designs which may not be supported in lower version of browsers',
//         point3:'As Sample Store uses some of the latest graphics designs which may not be supported in lower version of browsers designs which may not be supported in lower version of browsers',
//     },
//     {
//         heading:'Secure With Your Payment',
//         point1:'As Sample Store uses some of the latest graphics designs which may not be supported in lower version of browsers designs which may not be supported in lower version of browsers',
//         point2:'As Sample Store uses some of the latest graphics designs which may not be supported in lower version of browsers designs which may not be supported in lower version of browsers',
//         point3:'As Sample Store uses some of the latest graphics designs which may not be supported in lower version of browsers designs which may not be supported in lower version of browsers',
//     }
// ]


function RefundCacellation() {

    const [refund, setRefund] = useState([])

    useEffect(() => {
      fetchListing();
    },[])
  
    const fetchListing = async() => {
      await axios.get(BASE_URL+'/api/policy/refund_policy').then(({data}) => {
        setRefund(data.data)
      })
    }


    return(
        <div className={style.container}>
        <div className='col-md-12 col-xs-12 nopadding'>
        <nav aria-label="breadcrumb" >
          <ol className='breadcrumb'>
          <li class="breadcrumb-item  breadcrumb-padding"><a href="#"><span>Home</span></a><img src="/Icons/SigleSideArrow.png" className="breadcrumb-icon" /> </li>
            {/* <li className="breadcrumb-item-active" aria-current="page">Refund & Cancellation Policy</li> */}
            <li className="breadcrumb-item-active" aria-current="page">{refund.title && refund.title}</li>
            
          </ol>
        </nav>
            </div>
            <br /><br />
    <div className={style.div}>
    <h1 className={style.heading}>{refund.title && refund.title} </h1> <br /><br />
            <div dangerouslySetInnerHTML={{__html: refund.content && refund.content}}></div>
        {/* {PolicyData.map(i => (
                    <div className={`col-md-6 col-xs-12 ${style.content}`}>
                    <h3 className={style.heading}> {i.heading} </h3> <br />
                    <div className={style.details}>{i.point1}</div>
                    <div className={style.border}></div>
                    <div className={style.details}>{i.point2}</div>
                    <div className={style.border}></div>
                    <div className={style.details}>{i.point3}</div>
                    <div className={style.border}></div>
                </div>
        ))} */}
    </div>
    </div>
    )
}

export default RefundCacellation;