import React from 'react';
import {Button} from '@mui/material' 
import { Link } from 'react-router-dom';
import style from '../../styles/itemBox.module.css'
import { useNavigate } from 'react-router-dom';

function ItemBox({discount,name,actualPrice, discountPrice,img,onClick, ProductDetail}){

    const navigate = useNavigate();

    return(
        <div >
        <div className={`card ${style.card}`}>
            <div className={style.innerCard}>
            <div className={style.outerCard}>
                <div className={style.mobileHidden}>
                {discount == '0' ? <br /> : 
                <div className={style.discount}>{discount} %</div>
            }
            </div>
                
                    <div className={style.imgContainer} onClick={ProductDetail}>
                        <img src={img} className={style.img} />
                    </div>
              
                <h4 className={style.name}>{name}</h4>
                <div className={style.price}>
                    <h5 className={style.actualPrice}>INR {actualPrice}</h5>
                    <h5 className={style.discountPrice}>INR {discountPrice}</h5>
                </div>
                <div className={style.mobilePro}>
                        <div className={style.discountPrice}>${discountPrice}</div>
                        <div className={style.mobilePrice}>MRP: <span className={style.actualPrice}>${actualPrice}</span> {discount == '0' ? "" : <span>({discount}%off)</span>} </div>
                    </div>
                
                <button className={style.cartButton} onClick={onClick}>Add to Cart</button>
               
            </div>
            </div>
        </div>
        </div>
    )
}
export default ItemBox;