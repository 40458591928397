import React, { useState, useEffect, useContext } from 'react';
import ProductBox from '../../ProductBox/itemBox';
import SliderwithMenu from '../../SliderwithMenu';
import axios from 'axios';
import { BASE_URL } from '../../../config';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import Slider from '../../NewSlider';
import { UserContext } from '../../../App';


function PremiumCategories() {
  const {item,setItem} = useContext(UserContext);
    const [products, setProducts] = useState([]);
    const [error, setError] = useState([]);
    const token = localStorage.getItem('token');
    const navigate = useNavigate();
    let machineId = localStorage.getItem('MachineId');
    

    useEffect(() => {
        fetchPremiumProducts(0);
        getMachineId();
        FetchItemsAuth();
        fetchCount();
    }, [])

    const fetchCount = async () => {
      if(!token){
      const data = { "device_id": machineId }
      await axios.post(BASE_URL + '/api/get-cart-unauth', data).then(({ data }) => {
          setItem(data.data.cart.item)
          
      })
    }
  }
 
  const FetchItemsAuth = async () => {
    if(token){
    axios.get(BASE_URL + '/api/get-cart', {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    }).then(({ data }) => {
        setItem(data.data.cart.item)
    })
}
}

  const fetchPremiumProducts = async (id) => {
        await axios.get(BASE_URL + '/api/premium-product/' + id).then(({ data }) => {
            setProducts(data.data)
        })
    }



    function handleSubmit(id, optionId){
        setError('');
        axios.post(BASE_URL+'/api/store-cart',{"product_id":id,"product_option_id":optionId},
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
              }
        }).then(({data})=>{
          FetchItemsAuth()
          if(data.success === true){
            setError('')
          
            Swal.fire({
              text:data.meassge,
              icon:"success"
            })
            
            document.getElementById("loginpage").reset();
          } 
          if(data.status ==="error"){
            Swal.fire({
              text:data.message,
              icon:"error"
            })
          }
        }).catch(({response})=>{
          setError(response.data.message)
          if(response.status_code==="422"){
            setError(response.data.message)
          }
        });
      }

      function getMachineId() {

        let machineId = localStorage.getItem('MachineId');
    
        if (!machineId) {
          machineId = crypto.randomUUID();
          localStorage.setItem('MachineId', machineId);
        }     
      }

      function handleSubmitOffline(id, optionId){

        setError('');
        axios.post(BASE_URL+'/api/store-cart-unauth',{"product_id":id,"product_option_id":optionId,"device_id":machineId},{}).then(({data})=>{
          setItem(data.item)
          if(data.success === true){
            setError('')
            Swal.fire({
              text:data.meassge,
              icon:"success"
            })

            document.getElementById("loginpage").reset();
          } 
          if(data.status ==="error"){
            Swal.fire({
              text:data.message,
              icon:"error"
            })
          }
        }).catch(({response})=>{
          setError(response.data.message)
          if(response.status_code==="422"){
            setError(response.data.message)
          }
        });
      }


    return (
        <Slider name="Premium" boldName="Products" product={fetchPremiumProducts}>
            {products && products.map((data) => (
                <div className='col-md-3 col-sm-6 col-xs-6 nopadding-mobile'>
                    <ProductBox
                     ProductDetail={() => navigate('/product-details/' + data.slug)}
                        name={data.name && data.name}
                        img={BASE_URL + '/storage/' + data.image}
                        discount={data.product_options && data.product_options[0].discount_percentage}
                        actualPrice={data.product_options && data.product_options[0].mrp}
                        discountPrice={data.product_options && data.product_options[0].price}
                        onClick={() => token ? handleSubmit(data.product_options[0].product_id && data.product_options[0].product_id, data.product_options[0].id && data.product_options[0].id ) : handleSubmitOffline(data.product_options[0].product_id && data.product_options[0].product_id, data.product_options[0].id && data.product_options[0].id )}
                    />
                </div>
            ))}
        </Slider>
    )
}

export default PremiumCategories;