import React,{createContext, useEffect,useState} from 'react';
import logo from './logo.svg';
import './App.css';
import './styles/fonts/bootstrap/bootstrap.css';
import "@fontsource/montserrat";
import './styles/customStyle.css';
import axios from 'axios';
import { BASE_URL } from './config';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./components/pages/Homepage/Home";
import AboutPage from "./components/pages/About/AboutUsDetails";
import ContactPage from "./components/pages/ContactUs/ContactUs";
import Login from './components/pages/Login/login'
import CookiesPolicy from './components/pages/CookiesPolicy/CookiesPolicy';
import FAQs from './components/pages/FAQs/FAQs';
import HelpPage from './components/pages/HelpPage/Help';
import PrivacyPolicy from './components/pages/PrivacyPolicy/PrivacyPolicy';
import Refund from './components/pages/Refund/Refund';
import SignUp  from './components/pages/Signup/signup';
import TermsCondition from './components/pages/TermsCondition/TermsCondition';
import Header from './components/header/MainHeader';
import Footer from './components/footer/footer';
import Feedback from './components/pages/Feedback/feedback';
import Sidebar from './components/Sidebar/Sidebar';
import CatorryPage from './components/pages/Category/Category';
import ProductDetails from './components/pages/ProductDetailPage/ProductDetailPage';
import Blog from './components/pages/Blog/Blog';
import AddressPage from './components/pages/AddressPage/AddressPage';
import Cart from './components/pages/Cart/Cart';
import PaymentSelection from "./components/pages/PaymentSelection/Checkout";
import BlogDetails from "./components/pages/Blog/BlogDetails";
import Review from "./components/pages/Review/Review";
import ListingPolicy from './components/pages/ListingPolicy/ListingPolicy';
import WishList from "./components/pages/Wishlist/Wishlist";
import ThankYou from "./components/ThankYou";
import OrderDetail from './components/pages/Order/OrderDetails';
import AccountSettings from './components/pages/AccountSetting/AccountSetting';
import MyOrders from './components/pages/Order/Order';
import MyAddress from './components/pages/MyAddress/MyAddress';
import ChangePassword from './components/pages/ChangePassword/ChangePassword';
import InviteFriend from './components/pages/InviteFriend/InviteFriend';
import Dashboard from './components/pages/Dashboard/Dashboard';
import WishListDash from './components/pages/Wishlist/WishlistDash';
import GoToTop from './components/GoToTop';
import VerifyEmail from './components/pages/VerifyEmail/VerifyEmail';
import ResetPassword from './components/pages/ResetPassword/ResetPassword'

var token;
export const UserContext = createContext()
function App() {
  const [item, setItem] = useState(0);
  const [wishCount, setWishCount] = useState(0)

  useEffect(()=>{
    var token = localStorage.getItem("token");
  },[token]);

  useEffect(()=>{
    fetchCount();
    FetchItemsAuth();
    FetchWishCounts();
    FetchWishlistUnauth();
  },[])

  let machineId = localStorage.getItem('MachineId');
  console.log(machineId)
  
    const fetchCount = async () => {
      if(!token){
      const data = { "device_id": machineId }
      await axios.post(BASE_URL + '/api/get-cart-unauth', data).then(({ data }) => {
          setItem(data.data.cart.item)
          
      })
    }
  }    

  const FetchItemsAuth = async () => {
    if(token){
    axios.get(BASE_URL + '/api/get-cart', {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    }).then(({ data }) => {
        setItem(data.data.cart.item)
        
    })
    
}}

const FetchWishCounts = async () => {
  if(token){
  axios.get(BASE_URL + '/api/get-wishlist', {
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
      }
  }).then(({ data }) => {
    setWishCount(data.data.length)
    // console.log('hyd', data.data)
      
  })
}}

const FetchWishlistUnauth = async () => {
  if (!token) {
      const data = { "device_id": machineId }
      await axios.post(BASE_URL + '/api/get-wishlist-unauth',data).then(({ data }) => {
          setWishCount(data.data.length)
      })
  }
}




  return (
    <div className="App">
      <UserContext.Provider value={{item,setItem,wishCount,setWishCount}}>
      <BrowserRouter>
     <Header />
      <Routes>
        <Route path="/" element= {<HomePage />} />
        <Route path="/About" element={<AboutPage />} />
        <Route path="/Contact" element={<ContactPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/cookie-policy" element={<CookiesPolicy />} />
        <Route path="/FAQs" element={<FAQs />} />
        <Route path="/Help" element={<HelpPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/Refund-and-cancellation" element={<Refund />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/terms-and-condition" element={<TermsCondition />} />
        <Route path="/feedback" element={<Feedback />} />
        <Route path="/dashboard" element={<Dashboard   />} />
        <Route path="/our-products" element={<CatorryPage />} />
        <Route path="/product-details/:slug" element={<ProductDetails />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/address" element={<AddressPage />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/checkout" element={<PaymentSelection />} />
        <Route path="/blogdetail/:url" element={<BlogDetails />} />
        <Route path="/review" element={<Review />} />
        <Route path="/listing-policy" element={<ListingPolicy />} />
        <Route path="/wishList" element={<WishList />} />
        <Route path="/thankyou" element={<ThankYou />} />
        <Route path="/order-detail" element={<OrderDetail />} />
        <Route path="/settings" element={<AccountSettings />} />
        <Route path="/my-orders" element={<MyOrders />} />
        <Route path="/my-address" element={<MyAddress />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path='/invite' element={<InviteFriend />} />
        <Route path='/my-wishList' element={<WishListDash />} />
        <Route path="/verify-email/:slug" element={<VerifyEmail />} />
        <Route path="/reset-password/:slug" element={<ResetPassword />} />
      </Routes>
      <Footer />
      <GoToTop />
    </BrowserRouter>
    </UserContext.Provider>
      </div>
  );
}

export default App;