import React from 'react';
import '../../../styles/wishlist.css';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Button from '@mui/material/Button';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../../config';
import Sidebar from '../../Sidebar/Sidebar';
import Swal from 'sweetalert2';

const styleee = {
    background: '#fff',
    borderRadius: '50%',
    color: '#000'
}

const Btn = {
    backgroundColor: '#B12926',
    borderRadius: '20px',
    fontSize: 12,
    PaddingLeft: 20

}

export default function WishList() {

    const [data, setData] = useState();
    const token = localStorage.getItem('token');
    const [error, setError] = useState();
    const [wishCount, setWishCount] = useState(0)


    useEffect(() => {
        FetchWishlist();
    }, [])

    const FetchWishlist = async () => {
        if (token) {
            await axios.get(BASE_URL + '/api/get-wishlist',
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    }
                }).then(({ data }) => {
                    setData(data.data)
                    setWishCount(data.data.length)
                    //console.log(data.data.length)
                })
        }
    }

    function handleSubmit(id, optionId) {
        setError('');
        axios.post(BASE_URL + '/api/store-cart', { "product_id": id, "product_option_id": optionId },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            }).then(({ data }) => {
                FetchWishlist();
                if (data.success === true) {
                    setError('')
                    Swal.fire({
                        text: data.meassge,
                        icon: "success"
                    })
                    document.getElementById("loginpage").reset();
                }
                if (data.status === "error") {
                    Swal.fire({
                        text: data.meassge,
                        icon: "error"
                    })
                }
            }).catch(({ response }) => {
                setError(response.data.message)
                if (response.status_code === "422") {
                    setError(response.data.message)
                }
            });
    }


    return (
        <div className="col-md-12 col-xs-12 nopadding">
            <div className='col-md-12 col-xs-12 nopadding'>
                <nav aria-label="breadcrumb" >
                    <ol className='breadcrumb'>
                        <li class="breadcrumb-item  breadcrumb-padding"><a href="#"><span>Home</span></a><img src="/Icons/SigleSideArrow.png" className="breadcrumb-icon" /> </li>
                        <li class="breadcrumb-item  breadcrumb-padding"><a href="#"><span>Shop</span></a><img src="/Icons/SigleSideArrow.png" className="breadcrumb-icon" /> </li>
                        <li className="breadcrumb-item-active" aria-current="page">

                            Wishlist

                        </li>
                    </ol>
                </nav>
            </div>
            <div className={`col-md-3 col-xs-12 nopadding`}>
                <Sidebar />
            </div>
            <div className='col-md-9 wishCon'>
                <div className="col-md-12 col-xs-12 nopadding header">
                    <h3 className="heading">
                        My Wish List
                    </h3>
                </div>

                {data && data.length == 0 ? <h3>No Products Added in Wishlist</h3> :
                    <div className='padding'>
                        <div className='table-responsive'>
                            <table className="table">
                                <tbody className='table-body' >
                                    {data && data.map(i => (
                                        <tr>
                                            <td>
                                                <div className='flex'>
                                                    <div>
                                                        <img src={BASE_URL + '/storage/' + i.product.image} className='image' />
                                                    </div>
                                                    <div className='column-table'>
                                                        <div className=' textSecondary'>{i.product.name && i.product.name}</div>
                                                        <div className='flex'>
                                                            <div className='mrp'>INR {i.product.product_options[0] && i.product.product_options[0].mrp}</div>
                                                            <div className='column-table textRed'>INR {i.product.product_options[0] && i.product.product_options[0].price}</div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='small textSecondary'>In Stock</div>
                                                <div onClick={() => handleSubmit(i.product.product_options[0].product_id && i.product.product_options[0].product_id, i.product.product_options[0].id && i.product.product_options[0].id)}>
                                                    <Button endIcon={<ArrowForwardIcon style={styleee} />} variant="contained" style={Btn}>Add to Cart</Button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                }
                {/* <div className='col-md-12 col-xs-12 copy'>
                <div className='flex right'>
                    <div className='margin'>Wishlist</div>
                    <div className="codeBox">
                        <input className="inputStyle" />
                        <div className="copyBotton"> Copy </div>
                    </div>
                </div>
            </div> */}
            </div>

        </div>
    )
}