import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {Container, Button} from "react-bootstrap";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import style from '../styles/thankyou.module.css'
import { BASE_URL } from "../config";


const ThankYou = () => {
    
    const navigate = useNavigate();
    const [data, setData] = useState();
    const [num, setNum] = useState();
    const [mail, setMail] = useState();

    useEffect(() => {
        fetchHeaderData();
    })

    function fetchHeaderData() {
        axios.get(BASE_URL + '/api/header-settings').then(({ data }) => {
            setData(data.data.header_logo);
            setMail(data.data.email)
            setNum(data.data.tollfree_number)
        })
    }
    
    const {state} = useLocation([]);
   
    return (
        <div className="overflow">
           
                    <div className="thankYouCont">
                     
                        <img src={BASE_URL+'/storage/'+ data} className={style.thankImg} />
                        <div  className="thankuInnerCon">

            
                <h5 className="flex center" >Hello <h5 className={`bold ${style.name}`}>{state.userName && state.userName}!</h5></h5>

                <div className={`mt-4 ${style.thankCon}`}>
                    <div className={style.font60}> Thank You!</div>

                </div>
                <h3 className="mt-4">Congratulations! on placing a successful order with us</h3>
                <div className={`mt-4 ${style.border}`}></div>
                <div className={`mt-4 ${style.left}`}>
                <h6 className="mt-2 ">Order ID : <span className={`bold ${style.orderId}`}>#{state.orderId && state.orderId}</span></h6>
                <h6 className="mt-2">Order Date & Time : <span  className={`bold ${style.dateTime}`}>{state.date && state.date} {state.time && state.time}</span></h6>
                <h6 className="mt-2 ">Billed Amount : <span  className={`bold ${style.amount}`}>{state.Amount} </span></h6>
            
                     <h6 className="mt-2 ">Delivery Option : <span className={`bold ${style.payMode}`}>{state.paymentmode}</span></h6>
                     <h6 className="mt-2 ">Expected Delivery : <span  className={`bold ${style.deliver}`}>{state.deliveryDate}</span></h6>             
                     </div>          
                     <div className={`mt-4 ${style.border}`}></div> 
                <h6 className="mt-4">For any query Please feel free to contact us on: {num} or write us {mail}</h6>

                <div className="mt-4">
                    <Button onClick={() => navigate("/dashboard")} variant="danger">Go To Dashboard</Button>
                 <a href={state.download} target="_blank">   <Button variant="danger" className={style.ml}> Download Invoice </Button> </a>
                </div>
                </div>
            </div>
        </div>
    );
};

export default ThankYou;

