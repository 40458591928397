import React, { useEffect, useState } from 'react';
import style from '../../../styles/blog.module.css';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import { BASE_URL } from '../../../config';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const data = [
    {
        id: 1,
        img: '/Image/perfumeImage1.png',
        date: '13, Nov, 2021',
        by: 'Spacing Tech',
        name: 'Donec Tellus Nulla Lorem',
        detail: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit doli. Aenean commodo ligula eget dolor. ...'
    },
    {
        id: 2,
        img: '/Image/perfumeImage2.png',
        date: '13, Nov, 2021',
        by: 'Spacing Tech',
        name: 'Donec Tellus Nulla Lorem',
        detail: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit doli. Aenean commodo ligula eget dolor. ...'
    },
    {
        id: 3,
        img: '/Image/perfumeImage3.png',
        date: '13, Nov, 2021',
        by: 'Spacing Tech',
        name: 'Donec Tellus Nulla Lorem',
        detail: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit doli. Aenean commodo ligula eget dolor. ...'
    },
    {
        id: 4,
        img: '/Image/perfumeImage4.png',
        date: '13, Nov, 2021',
        by: 'Spacing Tech',
        name: 'Donec Tellus Nulla Lorem',
        detail: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit doli. Aenean commodo ligula eget dolor. ...'
    }
]

const recentPost = [
    {
        id: 1,
        img: '/Image/perfumeImage4.png',
        name: 'Donec Tellus Nulla Lorem',
        date: 'Nov 13, 2021'
    },
    {
        id: 2,
        img: '/Image/perfumeImage4.png',
        name: 'Donec Tellus Nulla Lorem',
        date: 'Nov 13, 2021'
    },
    {
        id: 3,
        img: '/Image/perfumeImage4.png',
        name: 'Donec Tellus Nulla Lorem',
        date: 'Nov 13, 2021'
    },
    {
        id: 4,
        img: '/Image/perfumeImage4.png',
        name: 'Donec Tellus Nulla Lorem',
        date: 'Nov 13, 2021'
    },
    {
        id: 5,
        img: '/Image/perfumeImage4.png',
        name: 'Donec Tellus Nulla Lorem',
        date: 'Nov 13, 2021'
    },
    {
        id: 6,
        img: '/Image/perfumeImage4.png',
        name: 'Donec Tellus Nulla Lorem',
        date: 'Nov 13, 2021'
    }
]




export default function Blog() {

    const [blogData, setBlogData] = useState([]);
    const [recentBlog, SetRecentBlog] = useState([]);
    const [search, setSearch] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        fetchBlogData();
        fetchRecentBlog();
    }, [])

    const fetchBlogData = async () => {
        await axios.get(BASE_URL + '/api/blogs').then(({ data }) => {
            setBlogData(data.data);
        })
    }

    const fetchRecentBlog = async () => {
        await axios.get(BASE_URL + '/api/recent-blogs').then(({ data }) => {
            SetRecentBlog(data.data);
        })
    }

    const bySearch = (user, search) => {
        if (search) {
            return user.title.toLowerCase().includes(search.toLowerCase());
        } else return user;
    };


    const filteredList = (recentBlog, search) => {
        return recentBlog
            .filter(user => bySearch(user, search))
    };


    return (
        <div>
            <div className='col-md-12 col-xs-12 nopadding'>
                <nav aria-label="breadcrumb" >
                    <ol className='breadcrumb'>
                        <li class="breadcrumb-item  breadcrumb-padding"><a href="#"><span>Home</span></a><img src="/Icons/SigleSideArrow.png" className="breadcrumb-icon" /> </li>
                        <li className="breadcrumb-item-active" aria-current="page">Blogs & Articles</li>
                    </ol>
                </nav>
            </div>
            <div className={style.padding}>
                <div className={`col-md-12 col-xs-12 ${style.container}`}>
                    <div className={`col-md-3 col-xs-12 ${style.LeftPanel}`}>
                        <div>
                            <div className={style.subHeadings}>Search</div>
                            <FormControl sx={{ width: '100%' }} variant="outlined">
                                <input className='input' placeholder='Search' onChange={(e) => { setSearch(e.target.value) }} />
                                {/* <OutlinedInput
                                    id="outlined-adornment-weight"
                                    
                                    endAdornment={<InputAdornment position="end"><img src="/Icons/SearchRed.png" /></InputAdornment>}
                                    aria-describedby="outlined-weight-helper-text"
                                    inputProps={{
                                        'aria-label': 'weight',
                                    }}
                                /> */}
                            </FormControl>
                        </div>
                        <div className={style.borderBottom}></div>
                        <div className={`${style.subHeadings} ${style.marginTopBottom}`}>Recent Post</div>
                        {filteredList(recentBlog, search).length == 0 ? <h3 className='bold mb-4'>No Blog Found</h3> : 
                        <>
                        {filteredList(recentBlog, search).map(user => (
                            <>
                                <div className='flex pointer' onClick={() => navigate('/blogdetail/' + user.url)}  >
                                    <img className={style.recentPostImages} src={BASE_URL + '/storage/' + user.image} />
                                    <div className={style.recentPostName}>
                                        <div className={style.name}>{user.title}</div>
                                        <div>{user.created_at}</div>
                                    </div>
                                </div>
                                <div className={style.borderBottom}></div>
                            </>
                        ))}
                        </>
}
                        <div>
                            <div className={style.subHeadings}>Tags</div>
                            <div className='flex'>
                                <button className={style.tagButton}>#Blog</button>
                                <button className={style.tagButton}>#News</button>
                                <button className={style.tagButton}>#Fashion</button>
                            </div>
                        </div>

                    </div>
                    <div className='col-md-9 col-xs-12'>
                        {blogData && blogData.map((data) => (
                            <div className={`col-md-6 col-sm-6 col-xs-12 ${style.marginTop}`}>
                                <img src={BASE_URL + '/storage/' + data.image} alt="perfume" className={style.img} />
                                <br />
                                <div className={`flex space-between ${style.width90}`}>
                                    <div className='textSecondary'>{data.created_at && data.created_at}</div>
                                    <div className='flex'><div className='textSecondary'> By : </div> {data.author && data.author}</div>
                                </div>
                                <h5>{data.title && data.title}</h5>
                                <div className={style.width90} dangerouslySetInnerHTML={{ __html: data.content && data.content.length > 500 ? data.content.substring(0, 150) : data.content }}></div>
                                <br />
                                <button className={style.button} onClick={() => navigate('/blogdetail/' + data.url)}>Read More</button>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}