import React,{useState, useEffect} from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import style from '../../../styles/Banner.module.css';
import { Link , useNavigate} from 'react-router-dom';
import { BASE_URL } from '../../../config';
import axios from 'axios';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";


function IzharsonBanner(){

    const [slider, setSlider] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        getSliderData();
    }, [])

    const getSliderData = async () => {
        await axios.get(BASE_URL + '/api/sliders').then(({ data }) => {
            setSlider(data.data);
            // console.log('gyfgeyfuh', data.data)
        })
    }


    return(
        <div className='col-md-12 col-xs-12 nopadding'>
             <Carousel
                showThumbs={false}
                autoPlay={true}
                interval={2500}
                showStatus={false}

                >
                    {slider.map((data) => (
            //             <div>
            //             <div className={`col-md-12 col-sm-12 col-xs-12 nopadding ${style.container}`} style={{ backgroundImage: `url(${BASE_URL+'/storage/'+data.image})` }}>
            //     <div className='col-md-12 col-sm-12 col-xs-12'>
            //         <div className={style.newHeading}>{data.title && data.title}</div>
            //         <div className={style.newSubHeading}>{data.sub_title && data.sub_title}</div>
            //         <div className={style.detail}>{data.content && data.content} </div>
            //         <div className='col-md-12 col-xs-12 bannerBtn'>
            //         <Link to="/our-products"><button className={` ${style.button}`}>Shop Now  </button></Link>
            //         </div>
            //     </div>

            // </div>
            //         </div>
            <div>
            <div className={`col-md-12 col-sm-12 col-xs-12 nopadding`} style={{ backgroundImage: `url(${BASE_URL+'/storage/'+data.image})` }}>
    <div className={`col-md-12 col-sm-12 col-xs-12 nopadding ${style.bannerCont}`}>
        <div className={style.textNewHead}>{data.title && data.title}</div>
        <div className={style.textNew}>{data.sub_title && data.sub_title}</div>
        <div className={style.bannerNewDetails}>{data.content && data.content} </div>
        <div className='col-md-12 col-xs-12 bannerBtn mt-2'>
        <Link to="/our-products"><button className={` ${style.button}`}>Shop Now <img src="/Icons/whitearrow_right.png" className='arrow-icon' style={{height:'10px', width:'20px', marginLeft:20, marginTop:5}} />  </button></Link>
        </div>
    </div>

</div>
        </div>
                    ))}
            </Carousel>
        </div>
    )
}

export default IzharsonBanner;