import React, { useState, useEffect } from "react";
import style from '../../../styles/Cart.module.css';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import AddIcon from '@mui/icons-material/Add';
import Modal from '@mui/material/Modal';
import Checkbox from '@mui/material/Checkbox';
import { useLocation, useNavigate } from "react-router-dom";
import { BASE_URL } from "../../../config";
import axios from "axios";
import AddressModal from "./AddressModal";
import { Card, Form } from "react-bootstrap";
import Swal from "sweetalert2";





export function AddItem(props) {

    const { handleInc, handleDec, counter } = props;

    return (
        <div>
            <div className={`flex ${style.counter}`}>
                <div className={`margin-left-right10 ${style.font}`} onClick={handleDec}>-</div>
                <div>{counter}</div>
                <div className={`margin-left-right10 ${style.font}`} onClick={handleInc}>+</div>
            </div>
        </div>
    )
}


export default function Checkout() {

    const [counter, setCounter] = useState(1);
    const price = 84.00;

    const [prize, setPrize] = useState(price);

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const token = localStorage.getItem('token');
    let machineId = localStorage.getItem('MachineId');
    const [cartDataOff, setCartDataOff] = useState([])
    const [cartShipping, setCartShipping] = useState([]);
    const [cartShippingOff, setCartOffShipping] = useState([]);
    const [cartData, setCartData] = useState([]);
    const [shippingAddress, setShippingAddress] = useState([]);
    const [billingAddress, setBillingAddress] = useState([]);
    const [billingId, setBillingId] = useState('');
    const navigate = useNavigate();
    const [error, setError] = useState();
    const [shippingId, setShippingId] = useState();
    const [payment, setPayment] = useState();
    const [bookingDetails, setBookingDetails] = useState();
    const [shippingData, setShippingData] = useState([]);
    const [pin, setPin] = useState('');
    const [bilpin, setBilPin] = useState('');

    const date = new Date().toLocaleDateString();
    const time = new Date().toLocaleTimeString();


    const { state } = useLocation();

    // console.log(state);

    useEffect(() => {
        FetchItems();
        FetchItemsUnAuth();
        getMachineId();
        FetchCustomerAddress();
    }, [])

    function getMachineId() {

        let machineId = localStorage.getItem('MachineId');

        if (!machineId) {
            machineId = crypto.randomUUID();
            localStorage.setItem('MachineId', machineId);
        }

    }

    const BookOrder = () => {
        navigate('/thankyou',
            {
                state: {
                    orderId: bookingDetails.order_id ? bookingDetails.order_id : '',
                    contact: bookingDetails.mobile_number ? bookingDetails.mobile_number : '',
                    delivery: bookingDetails.delivery_day ? bookingDetails.delivery_day : ''
                }
            }
        )
    }

    const FetchItems = async () => {
        // console.log("data")
        axios.get(BASE_URL + '/api/get-cart', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }).then(({ data }) => {
            // console.log("data")
            setCartData(data.data.cart_details);
            setCartShipping(data.data.cart);
        })
    }

    const FetchItemsUnAuth = async () => {

        const data = { "device_id": machineId }
        await axios.post(BASE_URL + '/api/get-cart-unauth', data).then(({ data }) => {

            setCartDataOff(data.data.cart_details);
            setCartOffShipping(data.data.cart);

        })
    }

    const FetchCustomerAddress = async () => {
        axios.get(BASE_URL + '/api/customer-address', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }).then(({ data }) => {
            setShippingAddress(data.data.shipping);
            setBillingAddress(data.data.billing)
        })
    }

    function ProceedPayment() {
        setError('');
        axios.post(BASE_URL + '/api/submit-order', {
            "billing_id": billingId,
            "shipping_id": shippingId,
            "shipping_type": state.shippingtype,
            "payment_mode": payment
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }).then(({ data }) => {
            setBookingDetails(data.data)
            if (data.success === true) {
                // BookOrder();
                setError('')
                Swal.fire({
                    text: data.message,
                    icon: "success"
                })
                navigate('/thankyou',
                {
                    state: {
                        orderId: data.data.order_id && data.data.order_id ,
                        date: date,
                        time: time,
                        Amount : (Number(state.cartamount && state.cartamount) - Number(state.preDiscount && state.preDiscount) + Number(state.shippingAmount && state.shippingAmount))+ Number(state.gstAmount && state.gstAmount),
                        deliveryDate : data.data.delivery_day,
                        paymentmode : payment,
                        download:data.data.pdf_url,
                        userName : data.data.customer_name

                        // delivery: bookingDetails.delivery_day ? bookingDetails.delivery_day : ''
                    }
                }
            )
                // navigate("/thankyou-order")
                
                document.getElementById("otherService").reset();
            }
            if (data.success === false) {
                // BookOrder();
                Swal.fire({
                    text: data.messsge,
                    icon: "error"
                })
            }
        }).catch(({ response }) => {
            setError(response.data)
            if (response.status === "422") {
                setError(response.data.message)
            }
        });
    }

    function CheckShoppingbyDelivery(statePincode,bilpincode) {
        setError('');
        if(statePincode && bilpincode){
        var billpin= bilpincode;
        var shippin = statePincode;
        }else if(statePincode){
            var billpin= statePincode;
            var shippin = statePincode;
        }else{
            var billpin= bilpincode;
            var shippin = bilpincode;
        }
        axios.post(BASE_URL + '/api/check-pincode-delivery', {
            "pincode":shippin,
            "cart_quantity":state.quantity,
            "cart_amount":state.cartGstTotal,
            "pincode_billing":billpin
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }).then(({ data }) => {    
          setShippingData(data.shippingCost) 
        //   console.log('daara',data.shippingCost)  
          
            if (data.success === true) {
                setError('')
                Swal.fire({
                    text: data.message,
                    icon: "success"
                })
            }
            if (data.success === false) {
                Swal.fire({
                    text: data.errors.pincode,
                    icon: "error"
                })
            }
        }).catch(({ response }) => {
            setError(response.data)
            if (response.status === "422") {
                setError(response.data.message)
            }
        });
      }
      


    return (
        <div>
            <div className='col-md-12 col-xs-12 nopadding'>
                <nav aria-label="breadcrumb" >
                    <ol className='breadcrumb'>
                        <li class="breadcrumb-item  breadcrumb-padding"><a href="#"><span>Home</span></a><img src="/Icons/SigleSideArrow.png" className="breadcrumb-icon" /> </li>
                        <li class="breadcrumb-item  breadcrumb-padding"><a href="#"><span>Shop</span></a><img src="/Icons/SigleSideArrow.png" className="breadcrumb-icon" /> </li>
                        <li className="breadcrumb-item-active" aria-current="page">Payment method selections</li>
                    </ol>
                </nav>
            </div>
            <div className={`col-md-12 col-xs-12 ${style.container}`}>
                <div className="col-md-8 col-xs-12">
                    <div className="table-responsive">
                        <table class="table">
                            <thead className="table-header">
                                <tr>
                                    <th scope="col">Product </th>
                                    <th scope="col">Price</th>
                                    <th scope="col">Dicount</th>
                                    <th scope="col">Quantity</th>
                                    <th scope="col">Total</th>
                                </tr>
                            </thead>
                            <tbody className="table-body">
                                {cartData && cartData.map((data) => (
                                    <tr>
                                        <th><div className='flex'><img src={BASE_URL + '/storage/' + data.products.image} className={style.img} /> <div className={style.productName}>{data.products.name && data.products.name}</div></div></th>
                                        {/* <th><div className='flex'>{data.products.id && data.products.id}</div></th> */}
                                        <td><div className={`flex ${style.marginTop}`}><div className={`textSeconday ${style.price}`}>INR {data.product_options && data.product_options.mrp}</div></div></td>
                                        <td><div className={`flex ${style.marginTop}`}><div className={`textSeconday ${style.price}`}>INR {data.product_options && data.product_options.discount_amount}</div></div></td>
                                        <td>
                                            <div className={`textSeconday ${style.priceCheck}`}>{data.quantity && data.quantity}</div>
                                        </td>
                                        <td><div className={`flex ${style.marginTop}`}><div className={`textSeconday ${style.price}`}>INR  {data.product_options.price * data.quantity && data.product_options.price * data.quantity}</div></div></td>

                                    </tr>
                                )) 
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="col-md-12 col-xs-12 nopadding container">
                        <div className="col-md-12 col-xs-12 nopadding header">
                            <div className="heading">
                                <h3>Select Address</h3>
                                <button className='addAddress flex' onClick={() => setOpen(true)}><AddIcon sx={{}} /> ADD ADDRESS</button>
                            </div>
                        </div>
                        <div className="padding20">
                            <div className="col-md-6">
                                <h4>Billing Address</h4>
                                <div className="margin-top-bottom">
                                    {billingAddress && billingAddress.map((address) => (
                                        <div className={`margin-top-bottom col-md-12 nopadding ${style.addrsCont}`}>


                                            <div className="col-md-12 nopadding space-between">
                                                <div>{address.name && address.name}</div>
                                                <div><Form.Check className={style.right} name="Billing address" type="radio" value={address.id} onChange={(e) => { setBillingId(e.target.value) }} onClick={()=>{CheckShoppingbyDelivery(pin,address.pincode);setBilPin(address.pincode)}} /></div>
                                            </div>

                                            <div className="col-md-12 nopadding ">

                                                <div className="col-md-12 mt-2 text-muted nopadding">{address.address && address.address}</div>
                                                <div className='col-md-12 mt-2  nopadding'>
                                                    <div className="col-md-6 nopadding">Country :{address.country.name && address.country.name} </div>
                                                    <div className="col-md-6 nopadding">State : {address.city.name && address.city.name} </div>
                                                </div>
                                                <div className='col-md-12 nopadding mt-1'>
                                                    <div className="col-md-6 nopadding">City : {address.state.name && address.state.name}</div>
                                                    <div className="col-md-6 nopadding">Zipcode : {address.pincode && address.pincode} </div>
                                                </div>
                                                <div className="col-md-12 mt-1 nopadding">Mobile Number : {address.mobile_number && address.mobile_number} </div>
                                                <div className="col-md-12 mt-1 nopadding">Email : {address.email && address.email} </div>

                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <h4>Shipping Address</h4>
                                <div className="margin-top-bottom">
                                    {shippingAddress && shippingAddress.map((address) => (
                                        <div className={`margin-top-bottom col-md-12 nopadding ${style.addrsCont}`}>


                                            <div className="col-md-12 nopadding space-between" >
                                                <div>{address.name && address.name}</div>
                                                <div><Form.Check className={style.right} name="Shipping address" type="radio" value={address.id} onChange={(e) => { setShippingId(e.target.value) }} onClick={() => {CheckShoppingbyDelivery(address.pincode,bilpin); setPin(address.pincode)}} /></div>
                                            </div>

                                            <div className="col-md-12 nopadding ">

                                                <div className="col-md-12 mt-2 text-muted nopadding">{address.address && address.address}</div>
                                                <div className='col-md-12 mt-2  nopadding'>
                                                    <div className="col-md-6 nopadding">Country :{address.country.name && address.country.name} </div>
                                                    <div className="col-md-6 nopadding">State : {address.city.name && address.city.name} </div>
                                                </div>
                                                <div className='col-md-12 nopadding mt-1'>
                                                    <div className="col-md-6 nopadding">City : {address.state.name && address.state.name}</div>
                                                    <div className="col-md-6 nopadding">Zipcode : {address.pincode && address.pincode} </div>
                                                </div>
                                                <div className="col-md-12 mt-1 nopadding">Mobile Number : {address.mobile_number && address.mobile_number} </div>
                                                <div className="col-md-12 mt-1 nopadding">Email : {address.email && address.email} </div>

                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className={`col-md-4 col-xs-12 ${style.container}`}>
                    <div className={`${style.cartTotal} ${style.container}`}>
                        <h4>Checkout Summary</h4>
                        <div className={style.border}></div>
                        <br />
                        <div className={`flex space-between ${style.black}`}>
                            <div>Cart Total:</div>
                            <div>INR {state.cartamount && state.cartamount} </div>
                        </div>
                        <br />
                        <div className={`flex space-between ${style.black}`}>
                            <div>Pre Discount :</div>
                            <div>INR {state.preDiscount && state.preDiscount}</div>
                        </div>
                        <br />
                        <div className={`flex space-between ${style.black}`}>
                            <div>Promo Discount :</div>
                            <div>INR {state.promoDiscount && state.promoDiscount}</div>
                        </div>
                        <br />
                        <div className={`flex space-between ${style.black}`}>
                            <div>Sub Total:</div>
                            <div>INR {Number(state.cartamount && state.cartamount) - Number(state.preDiscount && state.preDiscount) - Number(state.promoDiscount && state.promoDiscount) } </div>
                        </div>
                        <br />
                        <div className={`flex space-between ${style.black}`}>
                            <div>Shipping Cost:</div>
                            <div>INR {state.shippingAmount && state.shippingAmount}</div>
                        </div>
                        <br />
                        <div className={`flex space-between ${style.black}`}>
                            <div>GST:</div>
                            <div>INR {pin ? shippingData && shippingData.map((i) => (i.name == state.shipping && i.total_gst_amount )) : state.gstAmount && state.gstAmount}</div>
                        </div>
                        <br />
                        <div className={`flex space-between ${style.black}`}>
                            <div>Total Amount:</div>
                            <div>{(Number(state.cartamount && state.cartamount) - Number(state.preDiscount && state.preDiscount) + Number(state.shippingAmount && state.shippingAmount))+Number(state.gstAmount && state.gstAmount)}</div>
                        </div>
                        <br />

                        <div className={style.border}></div>
                        <h4>Select Payment Mode</h4>
                        {/* <FormControl name="payment">

                        <FormControlLabel value="online" control={<Radio sx={{ padding: 1 }} />} label={<h5>Pay Online</h5>} /> <br />

                        <FormControlLabel value="cod" control={<Radio sx={{ padding: 1 }} />} label={<h5>COD</h5>} />

                        </FormControl> */}

                        <FormControl>
                            {/* <FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel> */}
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                name="payment"
                                onChange={(e) => setPayment(e.target.value)}
                            >
                                <FormControlLabel value="online" control={<Radio sx={{ padding: 1 }} />} label={<h5>Pay Online</h5>} /> <br />

                                <FormControlLabel value="cod" control={<Radio sx={{ padding: 1 }} />} label={<h5>COD</h5>} />

                            </RadioGroup>
                        </FormControl>

                        <div className={style.border}></div>
                        <br />
                        <button className={style.checkoutButton} onClick={() => { shippingData == null ? Swal.fire("Kindly Select Different Delivery Location") : ProceedPayment() }}>Confirm Payment</button>
                    </div>

                </div>
            </div>
            <AddressModal showAdd={open} setShowAdd={setOpen} />
            
        </div>
    )
}