import React, { useState } from 'react';
import style from '../../../styles/aboutus.module.css';
import axios from 'axios';
import { BASE_URL } from '../../../config';
import Swal from 'sweetalert2';
import {
    Container,
    Row,
    Col,
    Breadcrumb,
    Form,
    Button
} from "react-bootstrap";
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Star = ({ starId, marked }) => {
    return (
        <span
            star-id={starId}
            role="button"
            className="star-style"
        >
            {marked ? "\u2605" : "\u2606"}
        </span>
    );
};


function Feedback() {
    const [message, setMessage] = useState('');
    const [error, setError] = useState({});
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [file, setFile] = useState("");
    const navigate = useNavigate();
    const formdata = new FormData();
    const [selection, setSelection] = React.useState(0);
    const [rating, setRating] = useState(0);
    const [data, setData] = useState([]);
    const hoverOver = event => {
        let starId = 0;
        if (event && event.target && event.target.getAttribute("star-id")) {
            starId = event.target.getAttribute("star-id");
        }
        setSelection(starId);
    };
    formdata.append(["first_name"], firstname);
    formdata.append(["last_name"], lastname);
    formdata.append(["email"], email);
    formdata.append(["mobile_number"], mobile);
    formdata.append(["message"], message);
    formdata.append(["rating"], rating);
    formdata.append(["image"], file);

    useEffect(() => {
        fetchFeedbackData();
    }, [])

    const fetchFeedbackData = async () => {
        await axios.get(BASE_URL + '/api/manage-page/feedback').then(({ data }) => {
            setData(data.data)
        })
    }

    function handleSubmit(e) {
        e.preventDefault();
        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        }
        axios.post(BASE_URL + '/api/feedback', formdata, config).then(({ data }) => {
            setEmail(null)
            setFile(null)
            setFirstname(null)
            setLastname(null)
            setMobile(null)
            setMessage(null)
            if (data.status_code === 200) {
                setError('')
                Swal.fire({
                    text: data.message,
                    icon: "success"
                })
                document.getElementById("feedbackpage").reset();
            }
        }).catch(({ response }) => {
            if (response.status === 422) {
                setError(response.data.message);
            }
        });
    }

    const handleOnChange = e => {
        setFile(e.target.files[0]);
    };

    const OnChangeFirstName = e => {
        const { value } = e.target;


        const re = /^[A-Za-z]+$/;
        if (value === "" || re.test(value)) {
            setFirstname(value);
        }
    }

    const OnChangeLastName = e => {
        const { value } = e.target;

        const re = /^[A-Za-z]+$/;
        if (value === "" || re.test(value)) {
            setLastname(value);
        }
    }


    return (
        <div className='col-md-12 col-xs-12 nopadding'>
            <div className={`col-md-12 col-xs-12 ${style.header}`}>
                <div className={style.heading}>{data.title && data.title} </div>
                <div >
                    <nav aria-label="breadcrumb" >
                        <ol className={`breadcrumb ${style.breadcrumb}`}>
                            <li class="breadcrumb-item  breadcrumb-padding"><a href="#"><span>home</span></a><img src="/Icons/SigleSideArrow.png" className="breadcrumb-icon" /> </li>
                            <li className="breadcrumb-item-active" aria-current="page">{data.title && data.title}</li>
                        </ol>
                    </nav>
                </div>
            </div>

            <div className="col-md-12 col-xs-12 padding50">
                <div className={`col-md-6 col-sm-6 col-xs-12 ${style.ImageContainer}`}>
                    <img src={BASE_URL + '/storage/' + data.image} alt="feedback" className={style.feedbackImg} />
                </div>
                <div className="col-md-6 col-sm-6 col-xs-12">
                    <div >
                        <h4>{data.title && data.title}</h4>
                        <Form id="feedbackpage">
                            <div className="col-md-6 col-sm-6 col-xs-12">

                                <Form.Group as={Col} controlId="formGridName">

                                    <input onChange={OnChangeFirstName} value={firstname} maxLength={12} type="text" placeholder="First Name" className='input' />
                                    {error.first_name && (
                                        <span className="alert-danger danger">{error.first_name}</span>
                                    )}
                                </Form.Group>
                            </div>
                            <div className="col-md-6 col-sm-6 col-xs-12">
                                <Form.Group as={Col} controlId="formGridName">
                                    <input type="text" onChange={OnChangeLastName} value={lastname} placeholder="Last Name" className='input' />
                                    {error.last_name && (
                                        <span className="alert-danger danger">{error.last_name}</span>
                                    )}
                                </Form.Group>
                            </div>

                            <div className="col-md-6 col-sm-6 col-xs-12 ">
                                <Form.Group as={Col} controlId="formGridEmail">
                                    <input onChange={(e) => setEmail(e.target.value)} type="email" placeholder="Email" className='input' />
                                    {error.email && (
                                        <span className="alert-danger danger">{error.email}</span>
                                    )}
                                </Form.Group>
                            </div>
                            <div className="col-md-6 col-sm-6 col-xs-12">
                                <Form.Group as={Col} controlId="formGridPhone">
                                    <input type="number" onChange={(e) => { e.target.value = (Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)); setMobile(e.target.value) }} placeholder="Contact Number" className='input' />
                                    {error.mobile_number && (
                                        <span className="alert-danger danger">{error.mobile_number}</span>
                                    )}
                                </Form.Group>


                            </div>
                            <div className="col-md-12 col-sm-6 col-xs-12 ">
                                {/* <Form.Group controlId="messageForm.ControlTextarea1">

                      <Form.Control onChange={(e) => setMessage(e.target.value)} placeholder="Message" as="textarea" rows={3} />
                      {error.message && (
                        <span className="alert-danger danger">{error.message}</span>
                      )}
                    </Form.Group> */}
                                <textarea
                                    rows="4"
                                    className="input"
                                    // value={msg}
                                    onChange={e => setMessage(e.target.value)}
                                    placeholder="Message"
                                    type="text"
                                    name="msg"
                                    required
                                />
                            </div>

                            <div className="col-md-6 col-sm-6 col-xs-12 mt-2">

                                <Form.Group as={Col} controlId="formGridPhone">
                                   <span className='font18'> Select Rating:</span> 
                                    <div
                                        onMouseOver={hoverOver}
                                        onMouseOut={() => hoverOver(null)}
                                        onClick={event => setRating(event.target.getAttribute("star-id"))}
                                    >
                                        {Array.from({ length: 5 }, (v, i) => (
                                            <Star starId={i + 1} marked={selection ? selection > i : rating > i} />

                                        ))}
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-6 col-sm-6 col-xs-12  mt-2">
                                <Form.Group as={Col} controlId="formGridPhone">

                                    <div >Upload Image</div>
                                    <input type="file" className='mt-2' onChange={handleOnChange} />
                                    {error.image && (
                                        <span className="alert-danger danger">{error.image}</span>
                                    )}
                                </Form.Group>
                            </div>




                            <Button  className={`mt-2 ${style.submitButton}`} onClick={firstname && lastname && email && message && rating ? handleSubmit : () => Swal.fire("Kindly Enter All Details")} variant="danger" >Submit Now</Button>
                        </Form>
                        {/* <form>
                            <input
                                className='input'
                                onChange={e => setName(e.target.value)}
                                placeholder="Name"
                                type="text"
                                name="name"
                                required
                            />
                            <input
                                className='input'
                                onChange={e => setEmail(e.target.value)}
                                placeholder="Email Address"
                                type="email"
                                name="email"
                                required
                            />
                            <input
                                className='input'
                                // value={number}
                                onChange={e => setMobile(e.target.value)}
                                placeholder="Phone"
                                type="number"
                                name="number"
                                required
                            />

                            <textarea
                                rows="4"
                                className='input'
                                // value={msg}
                                onChange={e => setMessage(e.target.value)}
                                placeholder="Message"
                                type="text"
                                name="msg"
                                required
                            />
                            <br />

                            <button className={style.submitButton} onClick={handleSubmit}>Submit</button>
                        </form> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Feedback;