import React, { useState } from 'react';
import style from '../../../styles/Changepassword.module.css';
import axios from "axios";
import { BASE_URL } from "../../../config";
import { NavLink, useNavigate } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';
import Swal from "sweetalert2";
import Sidebar from '../../Sidebar/Sidebar'

export default function Setting() {
  const userdata = JSON.parse(localStorage.getItem("userdata"));
  const [name, setName] = useState(userdata && userdata.name);
  const [email, setEmail] = useState(userdata && userdata.email);
  const [mobileNumber, setMobileNumber] = useState(userdata && userdata.mobile_number);
  const [gender, setGender] = useState(userdata && userdata.gender);
  const [maritalStatus, setMaritalStatus] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState(userdata && userdata.dob);
  const [profileImage, setProfileImage] = useState('');
  const [aboutMe, setAboutMe] = useState('');
  const [shippingAddress, setShippingAddress] = useState(userdata && userdata.address_line_1);
  const [billingAddress, setBillingAddress] = useState(userdata && userdata.address_line_2);
  const [errors, setError] = useState([]);
  const [buttonText, setButtonText] = useState("Update");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const navigate = useNavigate();
  const handleOnChange = e => {
    setProfileImage(e.target.files[0]);
  };
  const token = localStorage.getItem('token');
  function handleSubmit(e) {
    e.preventDefault();
    setError('');
    const data = {
      'name': name,
      'email': email,
      'mobile_number': mobileNumber,
      'gender': gender,
      'dob': dateOfBirth,
      'address_line_1': billingAddress,
      'address_line_2': shippingAddress,
    };
    axios.put(BASE_URL + '/api/update-customer-profile', data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      if (data.status === "success") {
        setError('')
        Swal.fire({
          text: data.message,
          icon: "success"
        })
        navigate("/")
        localStorage.setItem('userdata', JSON.stringify(data.data))
        document.getElementById("accountsetting").reset();
      }
      if (data.status === "error") {
        Swal.fire({
          text: data.message,
          icon: "error"
        })
      }
    }).catch(({ response }) => {
      setError(response.data)
      if (response.status === "422") {
        setError(response.data.message)
      }
    });
  }


  return (
    <div className={`col-md-12 col-xs-12 nopadding`}>
      <div className='col-md-12 col-xs-12 nopadding'>
        <nav aria-label="breadcrumb" >
          <ol className='breadcrumb'>
            <li class="breadcrumb-item  breadcrumb-padding"><a href="#"><span>Home</span></a><img src="/Icons/SigleSideArrow.png" className="breadcrumb-icon" /> </li>
            <li class="breadcrumb-item  breadcrumb-padding"><a href="#"><span>Shop</span></a><img src="/Icons/SigleSideArrow.png" className="breadcrumb-icon" /> </li>
            <li className="breadcrumb-item-active" aria-current="page">

              My Account Settings

            </li>
          </ol>
        </nav>
      </div>
      <div className={`col-md-3 col-xs-12 nopadding `}>
        <Sidebar />
      </div>
      <div className={`col-md-9 col-xs-12 col-sm-12 ${style.container}`} >
      <div className={`col-md-12 col-xs-12 nopadding ${style.header}`}>
        <h3 className={style.heading}>
          My Account
        </h3>
        </div>
        <div className={`col-md-12 col-xs-12 nopadding`}>
          <div className="">
            <Form id="accountsetting">
              <div className='col-md-12 col-sm-12 col-xs-12'>
                <div className="col-md-4 col-sm-4 col-xs-6 mt-2">
                  <div>Name</div>
                  <input className='input' type="text" placeholder="Name" onChange={(e) => setName(e.target.value)} value={name} />
                  <span className="text-danger">{errors.name}</span>
                </div>
                <div className="col-md-4  col-sm-4 col-xs-6 mt-2">

                  <div>Email</div>
                  <input className='input' placeholder="Email" onChange={(e) => setEmail(e.target.value)} value={email} />
                  <span className="text-danger">{errors.email}</span>
          
                </div>
                <div className="col-md-4  col-sm-4 col-xs-6 mt-2">

                  <div>Contact Number</div>
                  <input className='input' type="text" placeholder="Contact Number" onChange={(e) => setMobileNumber(e.target.value)} value={mobileNumber} />
                  <span className="text-danger">{errors.mobile_number}</span>

                </div>
                <div className="col-md-4  col-sm-4 col-xs-6 mt-2">
                  <div>Gender</div>
                  <select className='input' onChange={(e) => setGender(e.target.value)} value={gender}>
                    <option value="">Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select>
                  <span className="text-danger">{errors.gender}</span>

                </div>

                <div className="col-md-4 col-sm-4 col-xs-6 mt-2">
               
                  <div>Date of Birth</div>
                  <input className='input' type="date" placeholder="Date of Birth" onChange={(e) => setDateOfBirth(e.target.value)} value={dateOfBirth} />
                  <span className="text-danger">{errors.date_of_birth}</span>
           
                </div>
                <div className="col-md-4 col-sm-4 col-xs-6 mt-2">
         
                  <div>Upload Profile Photo</div>
                  <input className='input' laceholder='dbfjknd' type="file" onChange={handleOnChange} />
                  <span className="text-danger">{errors.profile_image}</span>
      
                </div>

                <div className={`col-md-12 col-xs-12 ${style.margin}`}>
                  <div>Default Shipping Address</div>
                  <textarea className='input' name="Text1" rows="3" placeholder="Default Shipping Address " onChange={(e) => setShippingAddress(e.target.value)} value={shippingAddress} />
                  <span className="text-danger">{errors.shipping_address}</span>
                </div>
                <div className={`col-md-12 col-xs-12 ${style.margin}`}>
                  <div>Default Billing Address</div>
                  <textarea className='input' name="Text1" rows="3" placeholder="Default Billing Address " onChange={(e) => setBillingAddress(e.target.value)} value={billingAddress} />
                  <span className="text-danger">{errors.billing_address}</span>
                </div>

                <div className="col-md-4 col-xs-12 col-sm-12 mb-4">
                  <Button className='mt-4' onClick={handleSubmit} variant="danger" type="submit">
                    {buttonText}
                  </Button>
                </div>
              </div>
            </Form>
          </div>
          </div>
        </div>


    </div>

  )
}