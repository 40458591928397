import { Button } from '@mui/material';
import React, { useState } from 'react';
import style from '../../../styles/contactus.module.css';
import axios from 'axios';
import { BASE_URL } from '../../../config';
import Swal from 'sweetalert2';
import { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MailIcon from '@mui/icons-material/Mail';
import CallIcon from '@mui/icons-material/Call';
import LocationOnIcon from '@mui/icons-material/LocationOn';

function ContactUs() {

    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [mobile, setMobile] = useState();
    const [message, setMessage] = useState();
    const [error, setError] = useState('');
    const [data, setData] = useState('');

    useEffect(() => {
        // fetchContactData();
        fetchAddress();
    }, [])

    // const fetchContactData = async() => {
    //     await axios.get(BASE_URL+'/api/manage-page/contact-us').then(({data}) => {
    //         setData(data.data)
    //     })
    // }

    const fetchAddress = async () => {
        await axios.get(BASE_URL + '/api/company-address').then(({ data }) => {
            setData(data.data)

        })
    }

    function handleSubmit(e) {
        e.preventDefault();
        setError('');
        axios.post(BASE_URL + '/api/contact-us', { 'name': name, 'email': email, 'mobile_number': mobile, 'message': message }).then(({ data }) => {
            setName(null);
            setEmail(null)
            if (data.status_code === 200) {
                setError('')
                Swal.fire({
                    text: data.message,
                    icon: "success"
                })
                document.getElementById("contactpage").reset();
            }
        }).catch(({ response }) => {

            if (response.status === 422) {
                setError(response.data.message)
            }
        });
    }

    const OnChangeName = e => {
        const { value } = e.target;

        const re = /^[A-Za-z]+$/;
        if (value === "" || re.test(value)) {
            setName(value);
        }
    }

    const OnEmailFeedValidation = e => {
        const { value } = e.target;

        const re = /^[A-Za-z@.1-9]+$/;
        if (value === "" || re.test(value)) {
            setEmail(value);
        }
    }



    return (
        <div id="ContactUs">
            <div className='col-md-12 col-xs-12 nopadding'>
                <div className={`col-md-12 col-xs-12 ${style.header}`}>
                    <div className={style.heading}> Contact Us</div>
                    <div >
                        <nav aria-label="breadcrumb" >
                            <ol className={`breadcrumb ${style.breadcrumb}`}>
                                <li class="breadcrumb-item  breadcrumb-padding"><a href="#"><span>Home</span></a><img src="/Icons/SigleSideArrow.png" className="breadcrumb-icon" /> </li>
                                <li className="breadcrumb-item-active" aria-current="page">Contact</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div className={`col-md-12 col-xs-12 ${style.content}`}>
                    <div className={style.contentHeading}> Reach Us</div>
                    <div className='col-md-7 col-sm-6 col-xs-12'>
                        <div className="col-md-12 col-sm-12 col-xs-12 nopadding">
                            {/* <div className='col-md-6 col-sm-12 col-sm-12' dangerouslySetInnerHTML={{__html:data.content && data.content}}></div> */}
                            {data && data.map((item) => (
                                <div className={`col-md-6 col-sm-12 col-xs-12 `} >
                                    <div className={style.contactBox}>
                                        <div className={`mt-2 center ${style.Name}`}>{item.name}</div>
                                        <div className={style.border}></div>
                                        <div className={`mt-1 flex`}>
                                            <LocationOnIcon className={style.locationIcon} sx={{fontSize:25}} />
                                            {item.address && item.address}
                                        </div>
                                        <div className={`mt-2 flex`}>  <CallIcon sx={{fontSize:20}} className={style.callIcon} /><div className={style.boldT}> Mobile Number : <span className={style.normal}> {item.contact_number} </span></div> </div>
                                        <div className={`mt-2 flex`}>  <WhatsAppIcon sx={{fontSize:20}} className={style.whatsAppIcon} /><div className={style.boldT}> Mobile Number : <span className={style.normal}> {item.contact_number} </span></div> </div>
                                        <div className={`mt-2 flex`}>  <MailIcon className={style.MailIcon} sx={{fontSize:20}} /><div className={style.boldT}> Email : <span className={style.normal}> {item.email} </span></div> </div>
                                        <div className='mt-2' dangerouslySetInnerHTML={{__html:item.map_url}}></div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={`col-md-5 col-xs-12 col-sm-6 center`}>
                        <div className={style.contactForm}>
                            <div className={style.form}>
                                <h1 className={style.contentHeading}>Get In Touch</h1>

                                <Form id="contactpage">
                                    <input
                                        className={style.input}
                                        value={name}
                                        onChange={OnChangeName}
                                        placeholder="Name"
                                        type="text"
                                        name="name"
                                        required
                                    />
                                    {error.name && (
                                        <span className="alert-danger danger">{error.name}</span>
                                    )}
                                    <input
                                        className={style.input}
                                        value={email}
                                        onChange={OnEmailFeedValidation}
                                        placeholder="Email address"
                                        type="email"
                                        name="email"
                                        required
                                    />
                                    {error.email && (
                                        <span className="alert-danger danger">{error.email}</span>
                                    )}
                                    <input
                                        className={style.input}
                                        // value={number}
                                        onChange={(e) => { e.target.value = (Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)); setMobile(e.target.value) }}
                                        placeholder="Phone"
                                        type="number"
                                        name="number"
                                        required
                                    />
                                    {error.mobile_number && (
                                        <span className="alert-danger danger">{error.mobile_number}</span>
                                    )}

                                    <textarea
                                        rows="4"
                                        className={style.input}
                                        // value={msg}
                                        onChange={e => setMessage(e.target.value)}
                                        placeholder="Message"
                                        type="text"
                                        name="msg"
                                        required
                                    />
                                    {error.message && (
                                        <span className="alert-danger danger">{error.message}</span>
                                    )}



                                    <button className={style.button} onClick={handleSubmit}>Send</button>
                                </Form>
                            </div>
                        </div>

                    </div>

                </div>
                {/* <div className='col-md-12 col-xs-12 center'>
                    <img src="/Image/map.png" alt="Map" className={style.map} />
                </div> */}
            </div>
        </div>
    )
}

export default ContactUs;