import React, { useState, useEffect } from 'react';
// import style from '../../../styles/Changepassword.module.css';
import { Row, Container, Col, Form, Dropdown, ProgressBar } from 'react-bootstrap';
import { BASE_URL } from '../../../config';
import axios from 'axios';
import Swal from "sweetalert2";
import ReactStars from "react-stars";
import style from '../../../styles/home.module.css';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { Tab, Tabs, Modal, Box, Button } from '@mui/material';
import Sidebar from '../../Sidebar/Sidebar'


const Star = ({ starId, marked }) => {
    return (
        <span
            star-id={starId}
            role="button"
            className="star-style"
        >
            {marked ? "\u2605" : "\u2606"}
        </span>
    );
};



const reasonData = [
    {
        id: '1',
        reason: 'Reason 1'
    },
    {
        id: '2',
        reason: 'Reason 2'
    },
    {
        id: '3',
        reason: 'Reason 3'
    },
    {
        id: '4',
        reason: 'Reason 4'
    },
    {
        id: '5',
        reason: 'Reason 5'
    }
]

const reasonCategoryData = [
    {
        id: '1',
        reason: 'Category 1'
    },
    {
        id: '2',
        reason: 'Category 2'
    },
    {
        id: '3',
        reason: 'Category 3'
    },
    {
        id: '4',
        reason: 'Category 4'
    },
    {
        id: '5',
        reason: 'Category 5'
    }
]

const Style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 400,
    // bgcolor: 'background.paper',
    fontSize: 15,
    boxShadow: 24,
    p: 4,
};



export default function AccountOrder() {

    const [tabvalue, setTabValue] = useState(0);

    const handleTabsChange = (event, newValue) => {
        setTabValue(newValue);
    };
    return (
        <div className={`col-md-12 col-xs-12 nopadding `}>
            <div className='col-md-12 col-xs-12 nopadding'>
                <nav aria-label="breadcrumb" >
                    <ol className='breadcrumb'>
                        <li class="breadcrumb-item  breadcrumb-padding"><a href="#"><span>Home</span></a><img src="/Icons/SigleSideArrow.png" className="breadcrumb-icon" /> </li>
                        <li class="breadcrumb-item  breadcrumb-padding"><a href="#"><span>Shop</span></a><img src="/Icons/SigleSideArrow.png" className="breadcrumb-icon" /> </li>
                        <li className="breadcrumb-item-active" aria-current="page">

                            My Orders

                        </li>
                    </ol>
                </nav>
            </div>
            <div className={`col-md-3 col-xs-12 nopadding `}>
                <Sidebar />
            </div>
            <div className={`col-md-9 col-xs-12 col-sm-12 ${style.container}`} >
                <div className={`col-md-12 col-xs-12 nopadding ${style.header}`}>
                    <h3 className={style.heading}>
                        My Order
                    </h3>
                </div>


                <div className='col-md-12 col-xs-12 col-sm-12'>
                    <Tabs
                        onChange={handleTabsChange}
                        value={tabvalue}
                        indicatorColor="red"
                        textColor="secondary"
                        className={style.TabBorder}
                    >
                        <Tab label="Active Orders" sx={{ fontSize: 14, fontWeight: 'bold' }} />
                        <Tab className="Delivered Orders" label="Delivered Orders" sx={{ fontSize: 14, fontWeight: 'bold' }} />
                        <Tab className="Cancelled Orders" label="Cancelled Orders" sx={{ fontSize: 14, fontWeight: 'bold' }} />
                    </Tabs>

                    {tabvalue === 0 && <div className={style.TabContent}><ActiveOrder /></div>}
                    {tabvalue === 1 && <div className={style.TabContent}><DeliveredOrder /></div>}
                    {tabvalue === 2 && <div className={style.TabContent}><CancelledOrder /></div>}
                </div>
            </div>
        </div>
    )
}


export const ActiveOrder = () => {

    const [activeData, setActiveData] = useState([]);
    const token = localStorage.getItem('token');

    useEffect(() => {
        ActiveOrderData()
    }, [])

    const ActiveOrderData = async () => {
        axios.post(BASE_URL + '/api/orders', { "order_status": "active" }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }).then(({ data }) => {
            setActiveData(data.data);
        })
    }

    return (
        <div>
            {activeData && activeData.length ?
                <div className='col-md-12 col-xs-12 col-sm-12'>
                    {activeData && activeData.map((data) => (
                        <div className="orders-grid">

                            <div className='col-md-12 nopadding mt-4'>
                                <div className='col-md-3 ' >Date &amp; Time : {data.estimated_delivery_date && data.estimated_delivery_date}</div>
                                <div className='col-md-3 '> Shipping To : {data.address && data.address}</div>
                                <div className='col-md-3 '>Order Amount : {data.order_amount_with_gst && data.order_amount_with_gst}</div>
                                <div className='col-md-3 '>Order ID : {data.order_number && data.order_number}</div>
                            </div>

                            <div className='mt-4 '>
                                <div className='col-md-12 nopadding'>
                                    <div className='mt-4'>
                                        {data && data.order_details.map((item) => (
                                            <div className='col-md-3 col-sm-6 col-xs-12 mb-2 '>
                                                <div className='card-body-blk'>
                                                    <div className={`p10 ${style.orderCont}`}>
                                                        <div className='center mt-2'>{item.product && item.product.name}</div>
                                                        <div className={`center mt-2  ${style.orderCont}`}><img src={BASE_URL + '/storage/' + item.product.image} className="active-order-img center mt-2" alt="" /></div>
                                                        <div className='center mt-2'>Price : $ {item.price && item.price}</div>
                                                        <div className='center mt-2'> Quantity : {item.quantity && item.quantity}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            </div>

                        </div>
                    ))}


                </div> : <h1 className='center p100' >No Bookings Available</h1>}
        </div>
    )
}

export const DeliveredOrder = () => {
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const [products, setProducts] = useState([]);
    const [trackButton, setTrackButton] = useState("");
    const [requestButton, setRequestButton] = useState("Request Cancellation");
    const [returnButton, setReturnButton] = useState("Return");
    const [deliveredData, setDeliveredData] = useState([]);
    const token = localStorage.getItem('token');
    const [showModal, setShowModal] = useState();
    const [canReason, setCanReason] = useState();
    const [error, setError] = useState();
    const [detailedReason, setDetailedReason] = useState();
    const [reasonCategory, setReasonCategory] = useState();
    const [returnReason, setReturnReason] = useState();
    const [returnModal, setReturnModal] = useState();
    const [trackModal, setTrackModal] = useState();
    const [trackData, setTrackData] = useState();
    const [trackStatus, setTrackStatus] = useState();
    const [productReview, setProductReview] = useState();
    const [orderid, setOrderId] = useState();
    const [orderdetailid, setOrderDetailId] = useState();
    const [rating, setRating] = useState();
    const [selection, setSelection] = useState(0)
    const hoverOver = event => {
        let starId = 0;
        if (event && event.target && event.target.getAttribute("star-id")) {
            starId = event.target.getAttribute("star-id");
        }
        setSelection(starId);
    };
    useEffect(() => {
        DeliveredOrderData()
    }, [])

    const DeliveredOrderData = async () => {
        axios.post(BASE_URL + '/api/orders', { "order_status": "delivered" }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }).then(({ data }) => {
            setDeliveredData(data.data);
            // console.log("de", data.data)
        })
    }


    function trackOrder(id) {
        setTrackModal(true)
        setError('');
        axios.get(BASE_URL + '/api/track-order-status/' + id, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }).then(({ data }) => {
            setTrackData(data.data)
            if (data.status === "success") {
                setError('')
                Swal.fire({
                    text: data.message,
                    icon: "success"
                })
                document.getElementById("otherService").reset();
            }
            if (data.status === "error") {
                Swal.fire({
                    text: data.message,
                    icon: "error"
                })
            }
        }).catch(({ response }) => {
            setError(response.data)
            if (response.status === "422") {
                setError(response.data.message)
            }
        });
    }

    function OrderCancel(id) {
        setError('');
        axios.post(BASE_URL + '/api/cancel-order', {

            "order_id": id,
            "reason": detailedReason,
            "reason_id": reasonCategory

        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }).then(({ data }) => {
            setShowModal(false)
            if (data.status === "success" && true) {
                setError('')
                Swal.fire({
                    text: data.message,
                    icon: "success"
                })
                document.getElementById("otherService").reset();
            }
            if (data.status === false) {
                Swal.fire({
                    text: data.message,
                    icon: "error"
                })
            }
        }).catch(({ response }) => {
            setError(response.data)
            if (response.status === "422") {
                setError(response.data.message)
            }
        });
    }

    const [file, setFile] = useState(null);

    const handleOnChange = e => {
        setFile(e.target.files);
    };

    function OrderReturn(id, detailId) {
        setError('');
        axios.post(BASE_URL + '/api/return-order', {
            "order_id": id,
            "reason_id": reasonCategory,
            "return_reason": returnReason,
            "order_detail_id": detailId,
            "image": file

        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }).then(({ data }) => {

            setReturnModal(false)
            if (data.status === true) {
                setError('')
                Swal.fire({
                    text: data.message,
                    icon: "success"
                })
                // navigate("/")
                document.getElementById("otherService").reset();
            }
            if (data.status === false) {
                Swal.fire({
                    text: data.message,
                    icon: "error"
                })
            }
        }).catch(({ response }) => {
            setError(response.data)
            if (response.status === "422") {
                setError(response.data.message)
            }
        });
    }

    function OrderReviewSubmit() {
        setError('');
        axios.post(BASE_URL + '/api/submit-order-review', {
            "order_id": orderid,
            "rating": rating,
            "review": message,
            "order_detail_id": orderdetailid
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }).then(({ data }) => {

            setProductReview(false)
            if (data.success === true) {
                setError('')
                Swal.fire({
                    text: data.message,
                    icon: "success"
                })
                // navigate("/")
                document.getElementById("otherService").reset();
            }
            if (data.status === "error") {
                Swal.fire({
                    text: data.message,
                    icon: "error"
                })
            }
        }).catch(({ response }) => {
            setError(response.data)
            if (response.status === "422") {
                setError(response.data.message)
            }
        });
    }

    const proceedOrderDetails = (id) => {
        navigate('/order-detail',
            {
                state: {
                    OrderID: id
                }
            }
        )
    }



    return (
        <div >

            {deliveredData && deliveredData.length ?
                <>
                    {deliveredData && deliveredData.map((data) => (
                        <>
                            <div className="">

                                <div className='col-md-12 col-xs-12 col-sm-12 mt-4'>

                                    <div>
                                        <div className='col-md-2 col-xs-12 col-sm-12' >Date : {data.estimated_delivery_date && data.estimated_delivery_date}</div>
                                        <div className='col-md-2 col-xs-12 col-sm-12 max-content'>Shipping : {data.address && data.address}</div>
                                        <div className='col-md-2 col-xs-12 col-sm-12'>  OrderID : #{data.order_number && data.order_number}</div>
                                        <div className='col-md-2 col-xs-12 col-sm-12'>
                                            <a onClick={() => proceedOrderDetails(data.id)} className={style.textDecor}>Order Details</a>
                                        </div>
                                        <div className='col-md-2 col-xs-12 col-sm-12'>
                                            <button variant="" className={style.req} onClick={() => { setShowModal(true); setOrderId(data.id); }}>Cancel Order</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body-blk">
                                <div>
                                    <div className='col-md-12 col-xs-12 col-sm-12 nopadding'>
                                        <div >
                                            {data && data.order_details.map((item) => (
                                                <div className={`col-md-6 col-xs-12 col-sm-12 mt-2 mb-2 ${style.orderCont}`}>
                                                    <div className='col-md-12 order-padding'>
                                                        <div className='col-md-6 col-xs-6 col-sm-6'>
                                                            <p>{item.product && item.product.name}</p>
                                                            <img src={BASE_URL + '/storage/' + item.product.image} className="active-order-img" alt="" />
                                                        </div>


                                                        <div className='center col-md-6 col-xs-6 col-md-6'>
                                                            <Col>
                                                                <Col><p className="order-price-detail">Price: $ {item.price && item.price}</p></Col>
                                                                <Col>
                                                                    <button className={style.return} onClick={() => { setReturnModal(true); setOrderId(item.order_id); setOrderDetailId(item.id); }}>{returnButton}</button>
                                                                    <Col>
                                                                        <button className={style.return} onClick={() => { setProductReview(true); setOrderId(item.order_id); setOrderDetailId(item.id); }} onChange={() => console.log(data.id)}>Product Rating</button>
                                                                        <Col>
                                                                            <button variant="danger" className={`${style.track}`} onClick={() => trackOrder(item.order_id)}>Track Order</button>
                                                                        </Col>
                                                                    </Col>
                                                                </Col>
                                                            </Col>
                                                        </div>
                                                    </div>

                                                </div>

                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>





                        </>
                    ))}
                </>
                : <h1 className='center p100' >No Bookings Available</h1>}
            <Modal open={showModal} onClose={() => setShowModal(false)}>
                <Box sx={Style}>
                    <div onClick={() => setShowModal(false)} className='pointer flex space-between   border-bottom'> <div className='cancel-order'>Cancel Order</div> <CloseIcon sx={{ marginBottom: '5px', height: '25px', width: '25px' }} /> </div>
                    <Form.Select defaultValue={0} className="mt-4 input" size="md" onChange={(e) => setReasonCategory(e.target.value)}>
                        <option value="0">Select Reason Category </option>
                        {reasonCategoryData.map((item) => (
                            <option value={item.id}> {item.reason}</option>
                        ))}
                    </Form.Select>
                    <Form.Select defaultValue={0} className="mt-4 input" size="md" onChange={(e) => setCanReason(e.target.value)}>
                        <option value="0">Select Reason for Cancellation </option>
                        {reasonData.map((item) => (
                            <option value={item.id}> {item.reason}</option>
                        ))}
                    </Form.Select>
                    <Form.Group className='mt-4' controlId="messageForm.ControlTextarea1">

                        <Form.Control onChange={(e) => setDetailedReason(e.target.value)} placeholder='Enter Full Detail' as="textarea" rows={3} />

                    </Form.Group>

                    <div className='mt-2 italic'>Note: All payment refunds will be done on the same source account</div>
                    <div className='mt-2 border-top' ></div>
                    <div className='mt-1 text-right' >

                        <Button variant="contained" sx={{ fontSize: 12 }} onClick={() => OrderCancel(orderid)}>Sumbit</Button>

                    </div>
                </Box>
            </Modal>

            <Modal open={trackModal} onClose={() => setTrackModal(false)}>
                <Box sx={Style} className="track-modal" >
                    <div onClick={() => setTrackModal(false)} className="pointer border-bottom flex space-between"> <div className="cancel-order">Track Order</div> <CloseIcon sx={{ marginBottom: '5px', height: '25px', width: '25px' }} /> </div>
                    <div className='mt-4'>
                        <ProgressBar variant='success' now={trackData && trackData.length === 1 ? 8 : trackData && trackData.length === 2 ? 25 : trackData && trackData.length === 3 ? 40 : trackData && trackData.length === 4 ? 58 : trackData && trackData.length === 5 ? 75 : trackData && trackData.length === 6 ? 100 : 0}  />
                    </div>
                    <div className='mt-4 flex'>
                        {trackData && trackData.map((item) => (
                            <Col md={2} xs={2} sm={2} className="track-order">{item.order_status && item.order_status}<br /> <span className='track-display'>{item.order_status_date && item.order_status_date}</span></Col>
                        ))}
                    </div>
                </Box>
            </Modal>
            <Modal open={productReview} onClose={() => setProductReview(false)}>
                <Box sx={Style} className="review-modal">
                    <div onClick={() => setProductReview(false)} className='pointer border-bottom flex space-between'> <div className="cancel-order">Product Rating</div> <CloseIcon sx={{ marginBottom: '5px', height: '25px', width: '25px' }} /> </div>
                    <Form.Group className='mt-4 flex' as={Col} controlId="formGridPhone">
                        <span className='font18'>Select Rating :</span>
                        <div
                            className='product-review'
                            onMouseOver={hoverOver}
                            onMouseOut={() => hoverOver(null)}
                            onClick={event => setRating(event.target.getAttribute("star-id"))}
                        >
                            {Array.from({ length: 5 }, (v, i) => (
                                <Star starId={i + 1} marked={selection ? selection > i : rating > i} />

                            ))}
                        </div>
                    </Form.Group>

                    <div className="mb-4">
                        <div  className='font18'>Write a Review</div>
                        <Form.Group controlId="messageForm.ControlTextarea1">

                            <Form.Control onChange={(e) => setMessage(e.target.value)} placeholder="Message" as="textarea" rows={3} />

                        </Form.Group>
                    </div>
                    <div className="mt-2 border-top"></div>
                    <div className="mt-2 text-right">
                        <Button variant="contained" sx={{ fontSize: 12 }} onClick={() => OrderReviewSubmit()}>Sumbit</Button>
                    </div>
                </Box>
            </Modal>

            <Modal open={returnModal} onClose={() => setReturnModal(false)}>
                <Box sx={Style} className="review-modal">
                    <div onClick={() => setReturnModal(false)} className="pointer border-bottom flex space-between"> <div className="cancel-order">Return Product</div> <CloseIcon sx={{ marginBottom: '5px', height: '25px', width: '25px' }} /> </div>
                    <div >
                        <Form.Select defaultValue={0} className="mt-4 input" onChange={(e) => setReasonCategory(e.target.value)}>
                            <option value="0">Select Reason </option>
                            {reasonData.map((item) => (
                                <option value={item.id}> {item.reason}</option>
                            ))}
                        </Form.Select>
                    </div>
                    <div className='mt-2'>Write a Message</div>
                    <Form.Group controlId="messageForm.ControlTextarea1">

                        <Form.Control onChange={(e) => setReturnReason(e.target.value)} placeholder="Message" as="textarea" rows={3} />

                    </Form.Group>
                    <div className='mt-2'>Upload Image</div>
                    <input type="file" className='mt-2' onChange={handleOnChange} />
                    <div className="mt-2 border-top"></div>
                    <div className='mt-4 text-right'>
                        <Button variant="contained" sx={{ fontSize: 12 }} onClick={() => OrderReturn(orderid, orderdetailid)}>Sumbit Return</Button>
                    </div>
                </Box>
            </Modal>


        </div>

    )
}

export const CancelledOrder = () => {

    const [cancelledData, setCancelledData] = useState([]);
    const token = localStorage.getItem('token');
    const navigate = useNavigate();

    useEffect(() => {
        DeliveredOrderData()
    }, [])

    const DeliveredOrderData = async () => {
        axios.post(BASE_URL + '/api/orders', { "order_status": "Cancelled" }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }).then(({ data }) => {
            setCancelledData(data.data);
        })
    }

    const proceedOrderDetails = (id) => {
        navigate('/order-detail',
            {
                state: {
                    OrderID: id
                }
            }
        )
    }


    return (
        <div className='mt-4'>
            {cancelledData && cancelledData.length ?
                <>
                    {cancelledData && cancelledData.map((data) => (
                        <>
                            <div className={`col-md-12 col-xs-12 col-sm-12 cancel-cont ${style.orderCont}`}>
                                <div className='col-md-12 col-xs-12 col-sm-12'>
                                    <div className='col-md-offset-1 col-md-2 col-xs-3 col-sm-6'>
                                        <Col>Order ID</Col>
                                        <Col># {data.order_number && data.order_number}</Col>
                                    </div>
                                    <div className='col-md-2 col-xs-3 col-sm-6'>
                                        <Col>Order Date & Time</Col>
                                        <Col>{data.order_date_time && data.order_date_time}</Col>
                                    </div>
                                    <div className='col-md-2 col-xs-3 col-sm-6'>
                                        <Col>Total Products</Col>
                                        <Col>{data.total_item_count && data.total_item_count}</Col>
                                    </div>
                                    <div className='col-md-2 col-xs-3 col-sm-6'>
                                        <Col>Payment Status</Col>
                                        <Col>{data.payment_status && data.payment_status}</Col>
                                    </div>
                                    <div className='col-md-2 col-xs-3 col-sm-6'>
                                        <Col>Billed Amount</Col>
                                        <Col>$ {data.order_amount && data.order_amount}</Col>
                                    </div>
                                </div>
                                <div className='col-md-12 col-xs-12 col-sm-12 mt-4'>
                                    <div className='col-md-offset-1 col-md-2 col-xs-3 col-sm-6'>
                                        <Col>Payment Method</Col>
                                        <Col>{data.payment_method && data.payment_method}</Col>
                                    </div>
                                    <div className='col-md-2 col-xs-3 col-sm-6'>
                                        <Col>Order Current Status</Col>
                                        <Col>{data.order_status && data.order_status}</Col>
                                    </div>
                                    <div className='col-md-2 col-xs-3 col-sm-6'>
                                        <Col>Payment Refund Status</Col>
                                        <Col>Processing</Col>
                                    </div>
                                    <div className='col-md-2 col-xs-3 col-sm-6'>
                                        <Col>Reason for Cancellation</Col>
                                        <Col>{data.cancelorder && data.cancelorder.cancellation_reason}</Col>
                                    </div>
                                    <div>
                                        <div className='mt-3 font12'><button className={style.req} onClick={() => proceedOrderDetails(data.id)}>View Details</button></div>
                                    </div>
                                </div>
                            </div>
                        </>

                    ))}
                </>
                : <h1 className='center p100' >No Bookings Available</h1>}
        </div>
    )
}


