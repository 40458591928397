import React, { useRef } from "react";
import style from '../styles/slider.module.css';


const sideScroll = (
  element,
  speed,
  distance,
  step
) => {
  let scrollAmount = 0;
  const slideTimer = setInterval(() => {
    element.scrollLeft += step;
    scrollAmount += Math.abs(step);
    if (scrollAmount >= distance) {
      clearInterval(slideTimer);
    }
  }, speed);
};

export default function Slider(props) {
  const { name, boldname, children } = props;
  const contentWrapper = useRef(null);

  return (
    <>

      <div className={`col-md-12 col-xs-12 ${style.container}`}>
        <div className="col-md-12 col-xs-12 nopadding-mobile">
          <div className='col-md-8 col-xs-8 nopadding-mobile'>
            <div className="flex">
            <div className={style.fontsize}> {name} </div>
            <div className={`${style.fontsize} ${style.boldName}`}> {boldname} </div>
            </div>
          </div>
          <div className={`col-md-4 col-xs-4 ${style.righttoppanel}`}>

            <div className="margin-left-right5">
              <div className={style.ScrollButton} onClick={() => { sideScroll(contentWrapper.current, 25, 100, -10); }}>
                <img src="/Icons/ArrowLeft.png" alt="Left" className="margin-left-right10 pointer" />
              </div>
            </div>
            <div className="margin-left-right5">
              <div className={style.ScrollButton} onClick={() => {
                sideScroll(contentWrapper.current, 25, 100, 10);
              }}>
                <img src="/Icons/ArrowRight.png" alt="Right" className="margin-left-right10 pointer" />
              </div>
            </div></div>
        </div>

        <div className={style.contentWrapper} ref={contentWrapper}>

          {children}
        </div>
      </div>
    </>
  );
}

