import React from 'react';
import style from '../../../styles/help.module.css'
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MenuBookIcon from '@mui/icons-material/MenuBook';
import Button from '@mui/material/Button';


const data1 = [
  {
    id: "panel1",
    heading: "How do I make yesterday payment?",
    details: "Nulla facilisi. Phasellus sollicitudin nulla et"
  },
  {
    id: "panel2",
    heading: "How this technology works?",
    details: " et et et et et "
  },
  {
    id: "panel3",
    heading: "What is the community benefit",
    details: " at at at at at at "
  },
];

const data2 = [
  {
    id: "panel4",
    heading: "Focus critical developer resources",
    details: "Nulla facilisi. Phasellus sollicitudin nulla et"
  },
  {
    id: "panel5",
    heading: "Improve conversation from International",
    details: " et et et et et "
  },
  {
    id: "panel6",
    heading: "Eget nunc scelerisque viverra mauris",
    details: " at at at at at at "
  },
];

function Support() {

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = panel => (event, isExpanded) => {
    // console.log(panel)
    // console.log(isExpanded)
    setExpanded(isExpanded ? panel : false);
  };


  return (
    <div className={style.container}>
      <div className='col-md-12 col-xs-12 nopadding'>
      <nav aria-label="breadcrumb" >
          <ol className='breadcrumb'>
          <li class="breadcrumb-item  breadcrumb-padding"><a href="#"><span>Home</span></a><img src="/Icons/SigleSideArrow.png" className="breadcrumb-icon" /> </li>
            <li className="breadcrumb-item-active" aria-current="page">Support</li>
          </ol>
        </nav>
      </div>

      <div className={`col-md-12 col-xs-12 ${style.help}`}>
        <div>
        <h2 className={style.helpHeading}>Need Some Help?</h2>
        </div>
       
          <div className={style.helpContainer}>
            <input placeholder="Write your email" className={style.Emailinput} />
            <div className={style.getHelpButton}>Get help</div>
          </div>
        <br />
        <div>
          <small>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</small>
          <small> <a>Click Here</a> </small>
        </div>
      </div>
      <div className={`col-md-12 col-xs-12 ${style.help}`}>
        <div className="">
          <div className={`col-md-3 col-xs-12 marginTop ${style.helpCards}`}>
            <div className='card'>
              <div className={`${style.card} ${style.support}`}>
                <div className={` ${style.icon}`}>
                  <MenuBookIcon sx={{fontSize:30,marginTop:1, color:'#B92126'}} />
                </div>
                <div>
                <br />
                <div className={style.cardHead}>Knowledge Base</div>
                <small className={style.cardBody}>Nulla facilisi. Phasellus sollicitudin nulla et</small>
                <small className={style.cardBody}>Nulla facilisi. Phasellus sollicitudin nulla et</small>
                <small className={style.cardBody}>Nulla facilisi. Phasellus sollicitudin nulla et</small>
              </div>
              <br />
              <button className={style.ExploreButton}> Explore Topics </button>
              </div>
            </div>
          </div>
          <div className='col-md-3 col-xs-12 marginTop'>
            <div className='card'>
              <div className={style.card}>
                <div className={` ${style.icon}`}>
                  <MenuBookIcon sx={{fontSize:30,marginTop:1, color:'#B92126'}} />
                </div>
                <div>
                <br />
                <div className={style.cardHead}>Question Answer</div>
                <small className={style.cardBody}>Nulla facilisi. Phasellus sollicitudin nulla et</small>
                <small className={style.cardBody}>Nulla facilisi. Phasellus sollicitudin nulla et</small>
                <small className={style.cardBody}>Nulla facilisi. Phasellus sollicitudin nulla et</small>
                </div>
            <br />
                <button className={style.qaButton}> QA Forum </button>
              </div>
            </div>
          </div>
          <div className='col-md-3 col-xs-12 marginTop'>
            <div className='card'>
              <div className={style.card}>
                <div className={` ${style.icon}`}>
                  <MenuBookIcon sx={{fontSize:30,marginTop:1, color:'#B92126'}} />
                </div>
                <div>
                  <br />
                <div className={style.cardHead}>FAQ</div>
                <small className={style.cardBody}>Nulla facilisi. Phasellus sollicitudin nulla et</small>
                <small className={style.cardBody}>Nulla facilisi. Phasellus sollicitudin nulla et</small>
                <small className={style.cardBody}>Nulla facilisi. Phasellus sollicitudin nulla et</small>
              </div>
              <br />
              <button className={style.qaButton}> Read FAQ </button>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`col-md-12 col-xs-12 ${style.ques}`}>
        <div className={style.support}>
        <h2 className={style.helpHeading}>Support</h2>
        <small>Aenean blandit et massa luctus luctus. In at turpis conditimentum, convallis lacus sit amet, consequat erat.</small>
        </div>
        <div className={`col-md-6 col-xs-12`}>
          {data1.map(accordion => {
            const { id, heading, secondaryHeading, details } = accordion;
            return (
              <Accordion
                expanded={expanded === id}
                key={id}
                onChange={handleChange(id)}
                className={style.Accordion}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <h5>{heading}</h5>
                </AccordionSummary>
                <AccordionDetails>
                  <div>{details}</div>
                </AccordionDetails>
              </Accordion>
            );
          })}

        </div>
        <div className={`col-md-6 col-xs-12`}>
          {data2.map(accordion => {
            const { id, heading, details } = accordion;
            return (
              <Accordion
                expanded={expanded === id}
                key={id}
                onChange={handleChange(id)}
                className={style.Accordion}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <h5>{heading}</h5>
                </AccordionSummary>
                <AccordionDetails>
                  <div>{details}</div>
                </AccordionDetails>
              </Accordion>
            );
          })}

        </div>
      </div>
    </div>
  )
}

export default Support;