import React, { useEffect, useState } from 'react';
import style from '../../../styles/login.module.css';
import { Link,useNavigate } from 'react-router-dom';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Checkbox, Modal } from '@mui/material';
import OtpInput from 'react18-input-otp';
import axios from 'axios';
import { BASE_URL } from '../../../config';
import Swal from 'sweetalert2';
import { Form } from "react-bootstrap";
import { useGoogleLogin } from '@react-oauth/google';
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const SignUp = () => {
    const [open, setOpen] = useState(false);
    const [otp, setOtp] = useState('');
    const handleChange = (enteredOtp) => {
        setOtp(enteredOtp);
    };
    const [password,setPassword] = useState('');
    const [confirmpassword,setConfirmPassword] = useState('');
    const [firstname,setFirstName] = useState('');
    const [lastname,setLastName] = useState('');
    const [contactnumber,setContactNumber] = useState('');
    const [address,setAddress] = useState('');
    const [countryid,setCountryId] = useState('');
    const [stateid,setStateId] = useState('');
    const [cityid,setCityId] = useState('');
    const [pincode,setPinCode] = useState('');
    const [email,setEmail] = useState('');
    const [country,setCountry] = useState([]);
    const [state,setState] = useState([]);
    const [city,setCity] = useState([]);
    const navigate = useNavigate();
    const [error, setError] = useState('')
    const [loading,setLoading] = useState(false);
    let machineId = localStorage.getItem('MachineId');
    //console.log(machineId)
    const path = window.location.origin


    useEffect(() => {
        getMachineId();
        fetchCountry();
        getState();
        getCity();
    },[])

    const fetchCountry = async()=>{
        setLoading(true)
        await axios.get(BASE_URL+'/api/country').then(({data})=>{
          setCountry(data.data)
          setLoading(false)
        }).catch(({response})=>{
    
        })
      }
    
      function getMachineId() {
    
        let machineId = localStorage.getItem('MachineId');
    
        if (!machineId) {
            machineId = crypto.randomUUID();
            localStorage.setItem('MachineId', machineId);
        }     
    }
    
     async function getState(id){
        setLoading(true)
       await  axios.get(BASE_URL+'/api/state/'+id).then(({data})=>{
          setState(data.data);
          setLoading(false);
        }).catch(({response})=>{
    
        })
      }
      async function getCity(id){
        setLoading(true)
        await  axios.get(BASE_URL+'/api/city/'+id).then(({data})=>{
          setCity(data.data);
          setLoading(false)
         }).catch(({response})=>{
     
         })
       }

       function handleSubmit(e){
   
        e.preventDefault();
        setError('');
       const data = {
        'first_name':firstname,
        'last_name':lastname,
        'mobile_number':contactnumber,
        'address':address,
        'email':email,
        'country':countryid,
        'state':stateid,
        'city':cityid,
        'pincode':pincode,
        'password':password,
        'password_confirmation':confirmpassword,
        'device_id' : machineId,
        'mobile_code':'91',
        'url': path + '/verify-email/'  
       }
       
        axios.post(BASE_URL+'/api/register',data).then(({data})=>{
          if(data.status==="success"){
            setError('')
            Swal.fire({
              text:data.message,
              icon:"success"
            })
            localStorage.setItem('token',data.token)
            localStorage.setItem('userdata',JSON.stringify(data.data))
            document.getElementById("signuppage").reset();
            navigate("/login")
          }
        }).catch(({response})=>{
          setError(response.data.message)
          if(response.status_code==="422"){
            setError(response.data.message)
            // //console.log(response.data.message);
          }
        });
      }

      const login = useGoogleLogin({
        onSuccess: response => {
          axios.get("https://www.googleapis.com/oauth2/v3/userinfo",{
            headers:{
              "Authorization":`Bearer ${response.access_token}`
            }
          }).then(({data})=>{
            axios.post(BASE_URL+'/api/google/login',{'email':data.email,'name':data.given_name, 'google_id' : data.sub,'device_id' : machineId}).then(({data})=>{
              localStorage.setItem('token',data.data.token)
              localStorage.removeItem('userdata');
              localStorage.setItem('userdata',JSON.stringify(data.data.user))
              navigate("/dashboard")
            })
            // //console.log(data)
          })
          
       
        },
      });



    return (
        <div>
            <div className={`col-md-4 col-xs-12 col-md-offset-4 .col-md-offset-right-4 ${style.container}`}>
                <div className={`card ${style.card}`}>
                    <div className={`${style.loginText} font-sans`} >SIGN UP!</div>
                    <div className='small-text'> Please enter the details to register your account with us, remember OTP will be sent to verify your account </div>
                    <div className={style.border}></div>
                    <form id="signuppage">
                        <Form.Group controlId="formGridFirst">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control type="text" onChange={(e)=>setFirstName(e.target.value)} placeholder="Enter First Name" className={style.input} />
                            {error.first_name && (
                                <span className="text-danger">{error.first_name}</span>
                            )}
                        </Form.Group>
                        <Form.Group className="pt-4" controlId="formGridLast">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control type="text" onChange={(e)=>setLastName(e.target.value)} placeholder="Enter Last Name" className={style.input} />
                            {error.last_name && (
                                <span className="text-danger">{error.last_name}</span>
                            )}
                        </Form.Group>
                        <Form.Group className="pt-4" controlId="formGridEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" onChange={(e)=>setEmail(e.target.value)} placeholder="Enter Email" className={style.input} />
                            {error.email && (
                                <span className="text-danger">{error.email}</span>
                            )}
                        </Form.Group>
                        <Form.Group className="pt-4" controlId="formGridContact">
                            <Form.Label>Mobile Number</Form.Label>
                            <Form.Control type="number" onChange={(e)=>setContactNumber(e.target.value)} placeholder="Enter Mobile Number" className={style.input} />
                            {error.mobile_number && (
                                <span className="text-danger">{error.mobile_number}</span>
                            )}
                        </Form.Group>

                        <Form.Group className="pt-4" controlId="formGridAddress">
                            <Form.Label>Address </Form.Label>
                            <Form.Control type="text" onChange={(e)=>setAddress(e.target.value)} placeholder="Enter Address" className={style.input} />
                            {error.address && (
                                <span className="text-danger">{error.address}</span>
                            )}
                        </Form.Group>
                        <Form.Group className="pt-4" controlId="formGridCountry">
                            <Form.Label>Country</Form.Label>

                            <Form.Select defaultValue="Choose..." onChange={(e)=>{getState(e.target.value);setCountryId(e.target.value)}} className={style.input}>
                                <option>Select Country</option>
                                {
                                    country && country.map((data)=>{
                                    return(
                                        <option key={data.id} value={data.id}>{data.name}</option>
                                    )
                                    })
                                }
                                </Form.Select>
                            {error.country && (
                                <span className="text-danger">{error.country}</span>
                            )}
                        </Form.Group>
                        <Form.Group className="pt-4" controlId="formGridState">
                            <Form.Label>State</Form.Label>
                            <Form.Select defaultValue="Choose..." onChange={(e)=>{getCity(e.target.value);setStateId(e.target.value)}} className={style.input}>
                                <option>Select State</option>
                                {
                                state && state.map((data)=>{
                                return(
                                    <option key={data.id} value={data.id}>{data.name}</option>
                                )
                                })
                            }
                            
                            </Form.Select>
                            {error.state && (
                                <span className="text-danger">{error.state}</span>
                            )}
                        </Form.Group>
                        <Form.Group className="pt-4" controlId="formGridCity">
                            <Form.Label>City</Form.Label>
                            <Form.Select defaultValue="Choose..." onChange={(e)=>setCityId(e.target.value)} className={style.input}>
                                <option>Select City</option>
                                {
                                    city && city.map((data)=>{
                                    return(
                                        <option key={data.id} value={data.id}>{data.name}</option>
                                    )
                                    })
                                }
                                
                                </Form.Select>
                            {error.city && (
                                <span className="text-danger">{error.city}</span>
                            )}
                        </Form.Group>
                        <Form.Group className="pt-4" controlId="formGridZip">
                            <Form.Label>Zipcode </Form.Label>
                            <Form.Control type="number"  onChange={(e)=>setPinCode(e.target.value)} placeholder="Enter Zipcode" className={style.input} />
                            {error.pincode && (
                                <span className="text-danger">{error.pincode}</span>
                            )}
                        </Form.Group>
                        

                        <Form.Group className="pt-4" controlId="formGridPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" onChange={(e)=>setPassword(e.target.value)} placeholder="Enter Password" className={style.input} />
                            {error.password && (
                                <span className="text-danger">{error.password}</span>
                            )}
                        </Form.Group>

                        
                        <Form.Group className="pt-4" controlId="formGridPassword">
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control type="password" onChange={(e)=>setConfirmPassword(e.target.value)} placeholder="Confirm Password" className={style.input}  />
                            {error.password && (
                                <span className="text-danger">{error.password}</span>
                            )}
                        </Form.Group>
                        {/* <input
                            className={style.input}
                            onChange={e => //console.log(e.target.value)}
                            placeholder="Confirm Password"
                            type="password"
                            name="repassword"
                            required
                        /> */}

                        <FormControlLabel control={<Checkbox
                            {...label}
                            defaultChecked
                            sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                        />} label={<h6>I agree to the following terms and conditions</h6>} />
                        <Link> <button className={style.button} onClick={handleSubmit}>Signup</button> </Link> <br />  <br />
                        <smal className={`${style.smal} ${style.small}`}>OR</smal> <br />
                        <a className={style.googleButton} onClick={()=>login()}>  <div className={style.GoogleIconCont}><img src="/Icons/google.png" className={style.GoogleIcon} /></div><div className={style.googleText}>Register with google</div> </a>                    </form>
                    <br />
                    <div className="flex center"><div className={style.member}>Already have an account? </div> <Link to="/login"><div className={style.account}>Login</div></Link></div>
                </div>
            </div>
        </div>
    )
}

export default SignUp;