import React, { useEffect, useState, useContext } from 'react';
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ItemBox from '../../ProductBox/itemBox';
import style from '../../../styles/categoryPage.module.css';
import ListIcon from '@mui/icons-material/List';
import AppsSharpIcon from '@mui/icons-material/AppsSharp';
import ReactStars from "react-stars";
import Offcanvas  from 'react-bootstrap/Offcanvas';
import { Dropdown } from "react-bootstrap";
import axios from 'axios';
import { BASE_URL } from '../../../config';
import { useLocation } from 'react-router-dom';
import { Pagination } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { Box, Card, Drawer, Modal } from '@mui/material';
import { Form } from 'react-bootstrap';
import { UserContext } from '../../../App';
import { faL } from '@fortawesome/free-solid-svg-icons';
import Nav from 'react-bootstrap/Nav';
// import Tab from 'react-bootstrap/Tab';
import { Button, Collapse } from "react-bootstrap";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}


const Style = {
  position: 'absolute',
  top: '300px',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid lightgray',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};


export default function CategoryPage() {

  const [expanded, setExpanded] = useState(false);
  const [title, setTitle] = useState("Show 15");
  const [sort, setSort] = useState("Default Sorting");
  const [product, setProduct] = useState([]);
  const [category, setCategory] = useState([]);
  const [packing, setPacking] = useState([]);
  const [pageCount, setpageCount] = useState(0);
  const location = useLocation();
  const [error, setError] = useState([]);
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  let machineId = localStorage.getItem('MachineId');
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState()
  const [searchCategory, setsearchCategory] = useState(null);
  const [filterDiscount, setFilterDiscount] = useState();
  const [search, setSearch] = useState();
  const [filterRating, setFilterRating] = useState();
  const { item, setItem } = useContext(UserContext);
  const [filterQuan, setFilterQuan] = useState();
  const { state } = useLocation();
  const [show, setShow] = useState(false);
  const [filter, setFilter] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [see, setSee] = useState(false)

  const handleSort = () => {
    setSort(true)
    setFilter(null)
  }
  const handleFilter = () => {
    setFilter(true)
    setSort(null)
  }

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    fetchProduct();
    fetchCategory();
    fetchPaking();
    getMachineId();
    fetchCount();
    FetchItemsAuth();
    fetchFilterWithSubCategoryProduct();
  }, [])

  const fetchCount = async () => {
    if (!token) {
      const data = { "device_id": machineId }
      await axios.post(BASE_URL + '/api/get-cart-unauth', data).then(({ data }) => {
        setItem(data.data.cart.item)

      })
    }
  }

  const FetchItemsAuth = async () => {
    if (token) {
      axios.get(BASE_URL + '/api/get-cart', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then(({ data }) => {
        setItem(data.data.cart.item)
      })
    }
  }

  const fetchProduct = async () => {
    await axios.get(BASE_URL + '/api/manage-product-with-category/' + state.data).then(({ data }) => {
      setProduct(data.data);
      console.log(data.data)
    })
  }

  function fetchFilterProduct(id) {
    axios.get(BASE_URL + '/api/manage-product-with-category/' + id).then(({ data }) => {
      setProduct(data.data);
      //console.log(data.data)
    })
  }

  function fetchFilterSubCategoryProduct(id) {
    const data = { "subcategory_id": [id] }
    axios.post(BASE_URL + '/api/filter-product/', data).then(({ data }) => {
      setProduct(data.data);
      //console.log(data.data)
    })
  }

  const [value, setValue] = React.useState(0);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };



  const [selectPrice1, setselctedPrice1] = useState(false)
  const [selectPrice2, setselctedPrice2] = useState(false)
  const [selectPrice3, setselctedPrice3] = useState(false)
  const [selectPrice4, setselctedPrice4] = useState(false)
  const [selectPrice5, setselctedPrice5] = useState(false)

  const handlePrice1 = () => {
    setselctedPrice1(true)
    setselctedPrice2(false)
    setselctedPrice3(false)
    setselctedPrice4(false)
    setselctedPrice5(false)
  }
  const handlePrice2 = () => {
    setselctedPrice1(false)
    setselctedPrice2(true)
    setselctedPrice3(false)
    setselctedPrice4(false)
    setselctedPrice5(false)
  }
  const handlePrice3 = () => {
    setselctedPrice1(false)
    setselctedPrice2(false)
    setselctedPrice3(true)
    setselctedPrice4(false)
    setselctedPrice5(false)
  }
  const handlePrice4 = () => {
    setselctedPrice1(false)
    setselctedPrice2(false)
    setselctedPrice3(false)
    setselctedPrice4(true)
    setselctedPrice5(false)
  }
  const handlePrice5 = () => {
    setselctedPrice1(false)
    setselctedPrice2(false)
    setselctedPrice3(false)
    setselctedPrice4(false)
    setselctedPrice5(true)
  }

  function fetchFilterPriceProduct(minPr, maxPr) {
    // setselctedPrice(null)
    const data = { "min_price": minPr, "max_price": maxPr }
    axios.post(BASE_URL + '/api/filter-product/', data).then(({ data }) => {
      // setselctedPrice(true)
      setProduct(data.data);
      //console.log(data.data)
    })
  }


  const fetchCategory = async () => {
    await axios.get(BASE_URL + '/api/product-categories').then(({ data }) => {
      setCategory(data.data);
    })
  }

  const fetchPaking = async () => {
    await axios.get(BASE_URL + '/api/manage-volume').then(({ data }) => {
      setPacking(data.data)
    })
  }

  function fetchFilterWithSubCategoryProduct() {
    if (state.cate) {
      const data = { "subcategory_id": [state.cate] }
      axios.post(BASE_URL + '/api/filter-product/', data).then(({ data }) => {
        setProduct(data.data);
        //console.log(data.data)
      })
    }
  }




  function handleSubmit(id, optionId) {
    setOpen(true)
    setError('');
    axios.post(BASE_URL + '/api/store-cart', { "product_id": id, "product_option_id": optionId },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then(({ data }) => {
        FetchItemsAuth()
        if (data.success === true) {
          setError('')
          //   //console.log('userdata',JSON.stringify(data.data))
          Swal.fire({
            text: data.meassge,
            icon: "success"
          })
          navigate("")
          document.getElementById("loginpage").reset();
        }
        if (data.success === false) {
          Swal.fire({
            text: data.meassge,
            icon: "error"
          })
        }
      }).catch(({ response }) => {
        setError(response.data.meassge)
        if (response.status_code === "422") {
          setError(response.data.meassge)
        }
      });
  }

  function getMachineId() {

    let machineId = localStorage.getItem('MachineId');

    if (!machineId) {
      machineId = crypto.randomUUID();
      localStorage.setItem('MachineId', machineId);
    }
  }

  function handleSubmitOffline(id, optionId) {
    setOpen(true)
    setError('');
    axios.post(BASE_URL + '/api/store-cart-unauth', { "product_id": id, "product_option_id": optionId, "device_id": machineId }, {}).then(({ data }) => {
      fetchCount();
      if (data.success === true) {
        // setModalData(data)
        setError('')

        //   //console.log('userdata',JSON.stringify(data.data))
        Swal.fire({
          text: data.meassge,
          icon: "success"
        })
        navigate("")
        document.getElementById("loginpage").reset();
      }
      if (data.success === false) {
        Swal.fire({
          text: data.meassge,
          icon: "error"
        })
      }
    }).catch(({ response }) => {
      setError(response.data.meassge)
      if (response.status_code === "422") {
        setError(response.data.meassge)
      }
    });
  }

  const bySearch = (user, search) => {
    if (search) {
      return user.name.toLowerCase().includes(search.toLowerCase());
    } else return user;
  };



  const byCategorySearch = (user, searchCategory) => {
    if (searchCategory) {
      return user.subcategory_id == searchCategory;
    }
    else return user;
  };

  const byDiscount = (user, filterDiscount) => {
    if (filterDiscount) {
      return user.product_options[0].discount_percentage >= filterDiscount;
    }
    else return user;
  };

  const byRating = (user, filterRating) => {
    if (filterRating) {
      return user.rating == filterRating;
    }
    else return user;
  };

  const byquantity = (user, filterQuan) => {
    if (filterQuan) {
      return user.product_options[0].packaging.quantity + "" + user.product_options[0].packaging.quantity_in == filterQuan;
    }
    else return user;

  };

  const filteredList = (product, search) => {
    return product
      .filter(user => bySearch(user, search))
      .filter((user) => byCategorySearch(user, searchCategory))
      .filter((user) => byDiscount(user, filterDiscount))
      .filter((user) => byRating(user, filterRating))
      .filter((user) => byquantity(user, filterQuan))
  };


  return (
    <div>
      <div className='col-md-12 col-xs-12 nopadding'>
        <nav aria-label="" >
          <ol className='breadcrumb'>
            <li class="breadcrumb-item  breadcrumb-padding"><a href="#"><span>Home</span></a><img src="/Icons/SigleSideArrow.png" className="breadcrumb-icon" /> </li>
            <li className="breadcrumb-item-active" aria-current="page">Shop</li>
          </ol>
        </nav>
      </div>

      <div className={`col-md-12 col-xs-12 ${style.container}`}>

        <div className='col-md-3 col-xs-12'>
          <div className="hide-in-desktop">
            <button variant="primary" onClick={() => setShow(true)} className="burger-btn">
              Filter / Sort By
            </button>
            <Drawer open={show} onClose={handleClose} >
              <div >
                <div className="filter-sidebar-title">
                  <div>Filter / Sort By</div>
                  <div onClick={() => setShow(false)}><CloseIcon /></div></div>
              </div>
              <div className="mobile-sorting-pd" style={{minWidth:'300px'}}>
                {/* <div className="sorting-btn" style={{padding:10}}>
                  <button onClick={handleSort} className="sorting-button"><i class="fa fa-sort-amount-desc" aria-hidden="true"></i> Sort</button>
                  <button onClick={handleFilter} className="filter-button"><i class="fa fa-sliders" aria-hidden="true"></i>Filter</button>
                </div> */}
                {/* {sort && */}
                    <div className='col-xs-12 nopadding'>
                      <div className='col-xs-5'>
                 <Tabs value={value} onChange={handleChangeTab} aria-label="basic tabs example" orientation="vertical" >
                 <Tab label={<div style={{fontSize:12}}>Categories</div>} {...a11yProps(0)} />
                 <Tab label={<div style={{fontSize:12}}>Price</div>} {...a11yProps(1)} />
                 <Tab label={<div style={{fontSize:12}}>Discount</div>} {...a11yProps(2)} />
                 <Tab label={<div style={{fontSize:12}}>Packing</div>} {...a11yProps(3)} />
                 <Tab label={<div style={{fontSize:12}}>Rating</div>} {...a11yProps(4)} />
               </Tabs>
               </div>
               <div className='col-xs-7'>
            
             <TabPanel value={value} index={0} style={{padding:0}}>
             {category.map((category, key) => (
              <Accordion
                expanded={expanded == category.id}
                key={category.id}
                value={category.id}
                className={style.marginTopBottom10}
                onChange={category && category.direct_childs.length == 0 ? "" : handleChange(category.id)}
                style={{padding:0}}
              >

                <AccordionSummary
                  expandIcon={category && category.direct_childs.length == 0 ? "" : <ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  className={style.margin0}
                  sx={{ fontSize: 16 }}
                  onClick={() => fetchFilterProduct(category.id)}
                  
                >
                  <div>{category.name && category.name} </div>
                </AccordionSummary>
                <AccordionDetails>
                  {category.direct_childs && category.direct_childs.map((subcategory, keym) => (
                    <div className='space-between'>
                      <div> {subcategory.name && subcategory.name} </div>
                      <div><input type="checkbox"
                        // onClick={() => {setsearchCategory(subcategory.id); console.log(subcategory.id)}}  
                        onClick={() => fetchFilterSubCategoryProduct(subcategory.id)}
                      /></div>
                    </div>
                  ))}
                </AccordionDetails>

              </Accordion>
            ))}
             </TabPanel>
             <TabPanel value={value} index={1}>
             <div>
                <input type="checkbox" id="Rating5" name="Brands" onClick={() => fetchFilterPriceProduct('100', '1000')} className={style.autoWidth} />
                <label for="Rating5">
                Rs.100 - Rs.1000
                </label> <br />
                <input type="checkbox" id="Rating4" name="Rating" className={style.autoWidth} onClick={() => fetchFilterPriceProduct('1001', '3000')} />
                <label for="Rating4">
                Rs.1001 - Rs.3000
                </label> <br />
                <input type="checkbox" id="Rating3" name="Rating" className={style.autoWidth} onClick={() => fetchFilterPriceProduct('3001', '5000')} />
                <label for="Rating3">
                Rs.3001 - Rs.5000
                </label> <br />
                <input type="checkbox" id="Rating2" name="Rating" className={style.autoWidth} onClick={() => fetchFilterPriceProduct('5001', '10000')} />
                <label for="Rating2">
                Rs.5001 - Rs.10000
                </label> <br />
                <input type="checkbox" id="Rating1" name="Rating" className={style.autoWidth} onClick={() => fetchFilterPriceProduct('10000', '100000')} />
                <label for="Rating1">
                Rs.10000 & above
                </label> <br />
              </div>
             </TabPanel>
             <TabPanel value={value} index={2}>
             <div>
                <input type="checkbox" value="10" name="discount" className={style.autoWidth} onChange={(e) => setFilterDiscount(e.target.value)} />
                <label for="10%" className={style.marginLeft}>10% and more</label> <br />
                <input type="checkbox" value="20" name="discount" className={style.autoWidth} onChange={(e) => setFilterDiscount(e.target.value)} />
                <label for="20%" className={style.marginLeft}>20% and more</label> <br />
                <input type="checkbox" value="30" name="discount" className={style.autoWidth} onChange={(e) => setFilterDiscount(e.target.value)} />
                <label for="30%" className={style.marginLeft}>30% and more</label> <br />
                <input type="checkbox" value="40" name="discount" className={style.autoWidth} onChange={(e) => setFilterDiscount(e.target.value)} />
                <label for="40%" className={style.marginLeft}>40% and more</label> <br />
                <input type="checkbox" value="50" name="discount" className={style.autoWidth} onChange={(e) => setFilterDiscount(e.target.value)} />
                <label for="50%" className={style.marginLeft}>50% and more</label> <br />
              </div>
             </TabPanel>
             <TabPanel value={value} index={3}>
             <div>
                {packing && packing.map((item) => (
                  <>
                    <input type="checkbox" id="500gm" name="Packing" className={style.autoWidth} value={item.quantity + "" + item.quantity_in} onChange={(e) => setFilterQuan(e.target.value)} />
                    <label for="500gm" className={style.marginLeft}>{item && item.quantity} {item && item.quantity_in}</label> <br />
                  </>
                ))}
              </div>
             </TabPanel>
             <TabPanel value={value} index={4}>
             <div>
                <input type="checkbox" id="Rating5" name="Brands" onChange={() => setFilterRating(5)} className={style.autoWidth} />
                <label for="Rating5">
                  <img src="/Icons/entypo_star.png" alt="rating" className={style.marginLeftRight} />
                  <img src="/Icons/entypo_star.png" alt="rating" className={style.marginLeftRight} />
                  <img src="/Icons/entypo_star.png" alt="rating" className={style.marginLeftRight} />
                  <img src="/Icons/entypo_star.png" alt="rating" className={style.marginLeftRight} />
                  <img src="/Icons/entypo_star.png" alt="rating" className={style.marginLeftRight} />
                </label> <br />
                <input type="checkbox" id="Rating4" name="Rating" className={style.autoWidth} onChange={() => setFilterRating(4)} />
                <label for="Rating4">
                  <img src="/Icons/entypo_star.png" alt="rating" className={style.marginLeftRight} />
                  <img src="/Icons/entypo_star.png" alt="rating" className={style.marginLeftRight} />
                  <img src="/Icons/entypo_star.png" alt="rating" className={style.marginLeftRight} />
                  <img src="/Icons/entypo_star.png" alt="rating" className={style.marginLeftRight} />
                  <img src="/Icons/star.png" alt="rating" className={style.marginLeftRight} />
                </label> <br />
                <input type="checkbox" id="Rating3" name="Rating" className={style.autoWidth} onChange={() => setFilterRating(3)} />
                <label for="Rating3">
                  <img src="/Icons/entypo_star.png" alt="rating" className={style.marginLeftRight} />
                  <img src="/Icons/entypo_star.png" alt="rating" className={style.marginLeftRight} />
                  <img src="/Icons/entypo_star.png" alt="rating" className={style.marginLeftRight} />
                  <img src="/Icons/star.png" alt="rating" className={style.marginLeftRight} />
                  <img src="/Icons/star.png" alt="rating" className={style.marginLeftRight} />
                </label> <br />
                <input type="checkbox" id="Rating2" name="Rating" className={style.autoWidth} onChange={() => setFilterRating(2)} />
                <label for="Rating2">
                  <img src="/Icons/entypo_star.png" alt="rating" className={style.marginLeftRight} />
                  <img src="/Icons/entypo_star.png" alt="rating" className={style.marginLeftRight} />
                  <img src="/Icons/star.png" alt="rating" className={style.marginLeftRight} />
                  <img src="/Icons/star.png" alt="rating" className={style.marginLeftRight} />
                  <img src="/Icons/star.png" alt="rating" className={style.marginLeftRight} />
                </label> <br />
                <input type="checkbox" id="Rating1" name="Rating" className={style.autoWidth} onChange={() => setFilterRating(1)} />
                <label for="Rating1">

                  <img src="/Icons/entypo_star.png" alt="rating" className={style.marginLeftRight} />
                  <img src="/Icons/star.png" alt="rating" className={style.marginLeftRight} />
                  <img src="/Icons/star.png" alt="rating" className={style.marginLeftRight} />
                  <img src="/Icons/star.png" alt="rating" className={style.marginLeftRight} />
                  <img src="/Icons/star.png" alt="rating" className={style.marginLeftRight} />
                </label> <br />
              </div>
             </TabPanel>
             </div>
             </div>
            
              </div>
            </Drawer>
          </div>
          <Form className={`d-flex searchproduct mobile-hidden ${style.space}`}>
            <Form.Control
              type="search"
              placeholder="Search"
              className={`input-drawer`}
              onChange={(e) => { setSearch(e.target.value) }}
            />

          </Form>
          <div className={`mobile-hidden ${style.categoryFrame}`}>
            <div className={style.categHeading}> Categories</div>
            <div className={style.border}></div>
            {category.map((category, key) => (
              <Accordion
                expanded={expanded == category.id}
                key={category.id}
                value={category.id}
                className={style.marginTopBottom10}
                onChange={category && category.direct_childs.length == 0 ? "" : handleChange(category.id)}

              >

                <AccordionSummary
                  expandIcon={category && category.direct_childs.length == 0 ? "" : <ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  className={style.margin0}
                  sx={{ fontSize: 16 }}
                  onClick={() => fetchFilterProduct(category.id)}
                >
                  <div>{category.name && category.name} </div>
                </AccordionSummary>
                <AccordionDetails>
                  {category.direct_childs && category.direct_childs.map((subcategory, keym) => (
                    <div className='space-between'>
                      <div> {subcategory.name && subcategory.name} </div>
                      <div><input type="checkbox"
                        // onClick={() => {setsearchCategory(subcategory.id); console.log(subcategory.id)}}  
                        onClick={() => fetchFilterSubCategoryProduct(subcategory.id)}
                      /></div>
                    </div>
                  ))}
                </AccordionDetails>

              </Accordion>
            ))}


          </div>

          <div className={`mobile-hidden ${style.categoryFrame}`}>
            <div className={style.categHeading}> Price</div>
            <div className={style.border}></div>
            <div className={style.flexWrap}>
              <div className={selectPrice1 ? ` nopadding pointer  ${style.priceFrameAfter}` : ` nopadding pointer  ${style.priceFrame}`} onClick={() => { fetchFilterPriceProduct('100', '1000'); handlePrice1() }}>
                Rs.100 - Rs.1000
              </div>
              <div className={selectPrice2 ? ` nopadding pointer  ${style.priceFrameAfter}` : ` nopadding pointer  ${style.priceFrame}`} onClick={() => { fetchFilterPriceProduct('1001', '3000'); handlePrice2() }}>
                Rs.1001 - Rs.3000
              </div>
              <div className={selectPrice3 ? `nopadding pointer  ${style.priceFrameAfter}` : ` nopadding pointer  ${style.priceFrame}`} onClick={() => { fetchFilterPriceProduct('3001', '5000'); handlePrice3() }}>
                Rs.3001 - Rs.5000
              </div>
              <div className={selectPrice4 ? `nopadding pointer  ${style.priceFrameAfter}` : `nopadding pointer  ${style.priceFrame}`} onClick={() => { fetchFilterPriceProduct('5001', '10000'); handlePrice4() }}>
                Rs.5001 - Rs.10000
              </div>
              <div className={selectPrice5 ? ` nopadding pointer  ${style.priceFrameAfter}` : ` nopadding pointer  ${style.priceFrame}`} onClick={() => { fetchFilterPriceProduct('10000', '100000'); handlePrice5() }}>
                Rs.10000 & above
              </div>
            </div>


          </div>

          <div className={`mobile-hidden ${style.categoryFrame}`}>
            <div className={style.categHeading}> Discount</div>
            <div className={style.border}></div>
            <div>
              <div>
                <input type="checkbox" value="10" name="discount" className={style.autoWidth} onChange={(e) => setFilterDiscount(e.target.value)} />
                <label for="10%" className={style.marginLeft}>10% and more</label> <br />
                <input type="checkbox" value="20" name="discount" className={style.autoWidth} onChange={(e) => setFilterDiscount(e.target.value)} />
                <label for="20%" className={style.marginLeft}>20% and more</label> <br />
                <input type="checkbox" value="30" name="discount" className={style.autoWidth} onChange={(e) => setFilterDiscount(e.target.value)} />
                <label for="30%" className={style.marginLeft}>30% and more</label> <br />
                <input type="checkbox" value="40" name="discount" className={style.autoWidth} onChange={(e) => setFilterDiscount(e.target.value)} />
                <label for="40%" className={style.marginLeft}>40% and more</label> <br />
                <input type="checkbox" value="50" name="discount" className={style.autoWidth} onChange={(e) => setFilterDiscount(e.target.value)} />
                <label for="50%" className={style.marginLeft}>50% and more</label> <br />
              </div>
            </div>

          </div>

          <div className={`mobile-hidden ${style.categoryFrame}`}>
            <div className={style.categHeading}> Packing </div>
            <div className={style.border}></div>
            <div>
              <div>
                {packing && packing.map((item) => (
                  <>
                    <input type="checkbox" id="500gm" name="Packing" className={style.autoWidth} value={item.quantity + "" + item.quantity_in} onChange={(e) => setFilterQuan(e.target.value)} />
                    <label for="500gm" className={style.marginLeft}>{item && item.quantity} {item && item.quantity_in}</label> <br />
                  </>
                ))}
              </div>
            </div>

          </div>

          <div className={`mobile-hidden ${style.categoryFrame}`}>
            <div className={style.categHeading}> Rating</div>
            <div className={style.border}></div>
            <div>
              <div>
                <input type="checkbox" id="Rating5" name="Brands" onChange={() => setFilterRating(5)} className={style.autoWidth} />
                <label for="Rating5">
                  <img src="/Icons/entypo_star.png" alt="rating" className={style.marginLeftRight} />
                  <img src="/Icons/entypo_star.png" alt="rating" className={style.marginLeftRight} />
                  <img src="/Icons/entypo_star.png" alt="rating" className={style.marginLeftRight} />
                  <img src="/Icons/entypo_star.png" alt="rating" className={style.marginLeftRight} />
                  <img src="/Icons/entypo_star.png" alt="rating" className={style.marginLeftRight} />
                </label> <br />
                <input type="checkbox" id="Rating4" name="Rating" className={style.autoWidth} onChange={() => setFilterRating(4)} />
                <label for="Rating4">
                  <img src="/Icons/entypo_star.png" alt="rating" className={style.marginLeftRight} />
                  <img src="/Icons/entypo_star.png" alt="rating" className={style.marginLeftRight} />
                  <img src="/Icons/entypo_star.png" alt="rating" className={style.marginLeftRight} />
                  <img src="/Icons/entypo_star.png" alt="rating" className={style.marginLeftRight} />
                  <img src="/Icons/star.png" alt="rating" className={style.marginLeftRight} />
                </label> <br />
                <input type="checkbox" id="Rating3" name="Rating" className={style.autoWidth} onChange={() => setFilterRating(3)} />
                <label for="Rating3">
                  <img src="/Icons/entypo_star.png" alt="rating" className={style.marginLeftRight} />
                  <img src="/Icons/entypo_star.png" alt="rating" className={style.marginLeftRight} />
                  <img src="/Icons/entypo_star.png" alt="rating" className={style.marginLeftRight} />
                  <img src="/Icons/star.png" alt="rating" className={style.marginLeftRight} />
                  <img src="/Icons/star.png" alt="rating" className={style.marginLeftRight} />
                </label> <br />
                <input type="checkbox" id="Rating2" name="Rating" className={style.autoWidth} onChange={() => setFilterRating(2)} />
                <label for="Rating2">
                  <img src="/Icons/entypo_star.png" alt="rating" className={style.marginLeftRight} />
                  <img src="/Icons/entypo_star.png" alt="rating" className={style.marginLeftRight} />
                  <img src="/Icons/star.png" alt="rating" className={style.marginLeftRight} />
                  <img src="/Icons/star.png" alt="rating" className={style.marginLeftRight} />
                  <img src="/Icons/star.png" alt="rating" className={style.marginLeftRight} />
                </label> <br />
                <input type="checkbox" id="Rating1" name="Rating" className={style.autoWidth} onChange={() => setFilterRating(1)} />
                <label for="Rating1">

                  <img src="/Icons/entypo_star.png" alt="rating" className={style.marginLeftRight} />
                  <img src="/Icons/star.png" alt="rating" className={style.marginLeftRight} />
                  <img src="/Icons/star.png" alt="rating" className={style.marginLeftRight} />
                  <img src="/Icons/star.png" alt="rating" className={style.marginLeftRight} />
                  <img src="/Icons/star.png" alt="rating" className={style.marginLeftRight} />
                </label> <br />
              </div>
            </div>

          </div>

        </div>

        <div className="col-md-9 col-xs-12" >
          {/* <div className={`col-md-12 col-xs-12 ${style.Pangination}`}>
            <div className="col-md-6 col-xs-12 " >
            </div>
            <div className={`col-md-6 col-xs-6 flex ${style.right}`}>
              <Dropdown>
                <Dropdown.Toggle variant="" id="dropdown-basic">
                  {sort}
                </Dropdown.Toggle>

                <Dropdown.Menu className={style.dropdownPadding}>
                  <Dropdown.Item onClick={() => setSort("Sort1")} href="#/action-1">
                    Sort1
                  </Dropdown.Item> <br />
                  <Dropdown.Item onClick={() => setSort("Sort2")} href="#/action-2">
                    Sort2
                  </Dropdown.Item> <br />
                  <Dropdown.Item onClick={() => setSort("Sort3")} href="#/action-3">
                    Sort1
                  </Dropdown.Item> <br />
                </Dropdown.Menu>
              </Dropdown>
          \

            </div>

          </div> */}

          <div className='col-md-12 col-xs-12'>
            {filteredList(product, search).length == 0 ? <h1 className='p100 center'> No Product Found </h1> :
              filteredList(product, search).map(user => (
                <div className={`col-md-4 col-xs-12 col-sm-12 ${style.marginTopBottom}`}>
                  <ItemBox
                    ProductDetail={() => navigate('/product-details/' + user.slug)}
                    name={user.name && user.name}
                    img={BASE_URL + '/storage/' + user.image}
                    discount={user.product_options && user.product_options[0].discount_percentage}
                    actualPrice={user.product_options && user.product_options[0].mrp}
                    discountPrice={user.product_options && user.product_options[0].price}
                    onClick={() => token ? handleSubmit(user.product_options[0].product_id && user.product_options[0].product_id, user.product_options[0].id && user.product_options[0].id) : handleSubmitOffline(user.product_options[0].product_id && user.product_options[0].product_id, user.product_options[0].id && user.product_options[0].id)}
                  />
                </div>
              ))}
          </div>

          {/* <Modal open={open} onClose={() => setOpen(false)} >
            
            <Box sx={{ ...Style, width: 200 }}>
            <div>{modalData ? modalData.meassge && modalData.meassge : ''}</div>              
            </Box>

          </Modal> */}
        </div>
       
      </div>
    </div>
  )
}