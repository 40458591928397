import React,{useState, useEffect} from 'react';
import style from '../../styles/footer.module.css';
import LocationOnIcon from '@mui/icons-material/LocationOnOutlined';
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';
import PhoneInTalkRoundedIcon from '@mui/icons-material/PhoneInTalkRounded';
import { Link } from 'react-router-dom';
import { BASE_URL } from '../../config';
import axios from 'axios';
import { Nav } from 'react-bootstrap';

function Footer(){

    const [footerData, setFooterData] = useState('')
    const [links, setLinks] = useState('')

    useEffect(()=>{
        FetchFooterData();
        FetchSocialLinks();
    },[])

    const FetchFooterData = async()=>{
        await axios.get(BASE_URL+'/api/header-settings').then(({data})=>{
            setFooterData(data.data);
        })  
    }

    const FetchSocialLinks = async()=>{
        await axios.get(BASE_URL+'/api/social-settings').then(({data})=>{
            setLinks(data.data);
        })  
    }
   
    return(
        <div className={`col-md-12 col-sm-12 col-xs-12 ${style.container}`}>

            {/* <div className={`col-md-12 col-xs-12 col-sm-12 ${style.container}`}> */}
                <div className='col-md-12 col-xs-12 col-sm-12'>
           <div className={`col-md-3 col-xs-12 col-sm-6 ${style.center}`}>
                <img src={BASE_URL+'/storage/'+footerData.footer_logo} alt="IZHARSONS_logo" className={style.logo} />
                 <br /><br />
                <div className={style.text}>
                {footerData.short_description && footerData.short_description}
                </div>
                <br />
                <div className='col-md-12 col-xs-12 col-sm-12 nopadding'>
                    <div className='col-md-3 col-xs-3 col-sm-3 nopadding'>
                        <img src="/Icons/pay1.png" alt="Maestro" className={style.paylogo} />
                    </div>
                    <div className='col-md-3 col-xs-3 col-sm-3 nopadding'>
                    <img src="/Icons/pay2.png" alt="Maestro" className={style.paylogo} />
                    </div>
                    <div className='col-md-3 col-xs-3 col-sm-3 nopadding'>
                    <img src="/Icons/pay3.png" alt="Maestro" className={style.paylogo} />
                    </div>
                    <div className='col-md-3 col-xs-3 col-sm-3 nopadding'>
                    <img src="/Icons/pay4.png" alt="Maestro" className={style.paylogo} />
                    </div>
                </div>
            </div>
            <div className={`col-md-3 col-xs-6 col-sm-6 ${style.aboutUs}`}>
                 <div className={style.text}>
                 <div className={style.heading}>ABOUT COMPANY</div>
                 <br />
                 <Nav.Link href="/About"><div className={style.links}>About Us </div></Nav.Link >
                 <Nav.Link  href="/FAQs"><div className={style.links}>FAQ</div></Nav.Link >
                 <Nav.Link  href="/blog"><div className={style.links}>Blog &amp; Articles</div></Nav.Link >
                 <Nav.Link  href="/Contact"><div className={style.links}>Contact Us</div></Nav.Link >
                 </div>
             </div>
             <div className={`col-md-3 col-xs-6 col-sm-6 ${style.aboutUs}`}>
             <div className={style.text}>
                 <div className={style.heading}>USES &amp; LISTING</div>
                 <br />
                 <Nav.Link  href="/terms-and-condition"><div className={style.links}>Terms &amp; Conditions </div></Nav.Link >
                 <Nav.Link  href="/privacy-policy"><div className={style.links}>Privacy Policy </div></Nav.Link >
                 <Nav.Link  href="/Refund-and-cancellation"><div className={style.links}>Refund &amp; Cancellation </div></Nav.Link >
                 <Nav.Link  href="/cookie-policy"><div className={style.links}>Cookie Policy </div></Nav.Link >
                 </div>
             </div>

             <div className={`col-md-3 col-xs-12 col-sm-6 ${style.aboutUs}`}>
           <div className={style.text}>
           <div className={style.heading}>Contact Us</div>
               <br />
               <div className="flex">
                   <div><LocationOnIcon sx={{fontSize:25}} /></div>
                   <div className={style.marginLeft}>{footerData.address && footerData.address}</div>
               </div>
               <br />
               <div className="flex">
                   <div><PhoneInTalkRoundedIcon sx={{fontSize:25}} /></div>
                   <div className={style.marginLeft}>{footerData.tollfree_number && footerData.tollfree_number}</div>
               </div>
               <br />
               <div className="flex">
                   <div><MailOutlineRoundedIcon sx={{fontSize:25}} /></div>
                   <div className={style.marginLeft}>{footerData.email && footerData.email}</div>
               </div>
               <br />
               <div className="col-md-12 col-xs-12 col-sm-12 nopadding">
               <div className="col-md-2 col-xs-2 ">  
              <a href={links.fb_name} target="_blank"> <img src="/Icons/facebook.png" className={style.socialMedia} /> </a>
               </div>
               <div className="col-md-2 col-xs-2">
               <a href={links.twit_name} target="_blank"> <img src="/Icons/twitter.png" className={style.socialMedia} /> </a>
               </div>
               <div className="col-md-2 col-xs-2">
               <a href={links.linkedin_name} target="_blank"> <img src="/Icons/linkedin.png" className={style.socialMedia} /> </a>
               </div>
               <div className="col-md-2 col-xs-2">
               <a href={links.youtube_name} target="_blank"><img src="/Icons/youtube.png" className={style.socialMedia} /> </a>
               </div>
               <div className="col-md-2 col-xs-2">
               <a href={links.insta_name} target="_blank"><img src="/Icons/instagram.png" className={style.socialMedia} /> </a>
               </div>
               <div className="col-md-2 col-xs-2">
               <a href={`https://wa.me/${footerData.whatsapp_number}`} target="_blank"> <img src="/Icons/whatsapp.png" className={style.socialMedia} /> </a>
               </div>
              </div>
              </div>
              
           </div>
           </div>
           <div className='col-md-12 col-xs-12'> 
                    <div className={style.copyrightText}>
                    <p className='whiteText'>Copyright ©</p><p className={style.Izharson}>Izharson Perfumers.</p> <p className={style.reserve}>All Right Reserved. </p>                     
                    </div>
            </div>
            {/* </div> */}
           
        </div>
    )
}

export default Footer;







