import React, { useState, useEffect, useContext } from 'react';
import style from '../../../styles/ProductDetails.module.css';
import { Button, Input, FormControl, MenuItem, Select, InputLabel, Box, Modal, Icon } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ProductDetailsTab from '../../ProductDetailsTab';
import RelatedProducts from '../Homepage/RelatedProducts';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Dropdown, Form } from "react-bootstrap";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { BASE_URL } from '../../../config';
import axios from 'axios';
import Swal from 'sweetalert2';
import { UserContext } from '../../../App';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const Style = {
  position: 'absolute',
  top: '300px',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid lightgray',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const LongText = ({ content, limit }) => {
  const [showAll, setShowAll] = useState(false);

  const showMore = () => setShowAll(true);
  const showLess = () => setShowAll(false);

  if (content && content.length <= limit) {
    // there is nothing more to show
    return <div>{content}</div>
  }
  if (showAll) {
    // We show the extended text and a link to reduce it
    return <div>
      {content}
      <button onClick={showLess} className='show-more'>Read less <img src='/Icons/up-arrow.jpg' height="15px" width="15px" style={{ marginLeft: 5 }} /> </button>
    </div>
  }
  // In the final case, we show a text with ellipsis and a `Read more` button
  const toShow = content ? content.substring(0, limit) + "..." : '';
  return <div>
    {toShow}
    <button onClick={showMore} className='show-more'>Read more <img src='/Icons/748063.png' height="15px" width="15px" style={{ marginLeft: 5 }} /></button>
  </div>
}

export default function ProductDetails() {

  const [counter, setCounter] = useState(1);

  const handleDec = () => {
    if (counter > 0) {
      setCounter(counter - 1)
    }
    else {
      setCounter(counter)
    }
  }

  const handleInc = () => {
    if (counter > 0) {
      setCounter(counter + 1)
    }
    else {
      setCounter(counter)
    }
  }


  let { slug } = useParams();
  // console.log(slug)
  const [productData, setProductData] = useState('');
  const [variant, setVariant] = useState([]);
  const [fregrance, setFregrance] = useState([])
  const [freg, setFreg] = useState();
  const [variantData, setVariantData] = useState();
  const [featured, setFeatured] = useState([]);
  const [relatedProduct, setRelatedProduct] = useState([]);
  const [error, setError] = useState();
  const token = localStorage.getItem('token');
  let machineId = localStorage.getItem('MachineId');
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState();
  const { item, setItem, setWishCount, wishCount } = useContext(UserContext);
  const [countproduct, setCountProduct] = useState(1);
  const [homeData, setHomeData] = useState([]);
  const navigate = useNavigate();

  console.log(freg, variantData)


  useEffect(() => {
    fetchproduct();
    getMachineId();
    fetchVariant();
    fetchCount();
    FetchItemsAuth();
    FetchWishCounts();
    fetchHomeWidgets();
    FetchWishlistUnauth()
  }, [])

  const fetchCount = async () => {
    if (!token) {
      const data = { "device_id": machineId }
      await axios.post(BASE_URL + '/api/get-cart-unauth', data).then(({ data }) => {
        setItem(data.data.cart.item)

      })
    }
  }

  const fetchHomeWidgets = async () => {
    await axios.get(BASE_URL + '/api/homepagewidget').then(({ data }) => {
      setHomeData(data.data.data[0]);
      // console.log(data.data)
    })
  }


  const FetchItemsAuth = async () => {
    if (token) {
      axios.get(BASE_URL + '/api/get-cart', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then(({ data }) => {
        setItem(data.data.cart.item)
      })
    }
  }

  const FetchWishCounts = async () => {
    if (token) {
      axios.get(BASE_URL + '/api/get-wishlist', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then(({ data }) => {
        setWishCount(data.data.length)
        // console.log('hyd', data.data)

      })
    }
  }

  const FetchWishlistUnauth = async () => {
    if (!token) {
      const data = { "device_id": machineId }
      await axios.post(BASE_URL + '/api/get-wishlist-unauth', data).then(({ data }) => {
        setWishCount(data.data.length)
      })
    }
  }

  const fetchproduct = async () => {
    await axios.get(BASE_URL + '/api/manage-product/' + slug).then(({ data }) => {
      setProductData(data.data);
      setFregrance(data.data.fragrance)
      console.log(data)
      if (data.status == true) {
        // alert('bdh')
        axios.get(BASE_URL + '/api/manage-product-with-category/' + data.data.category_id).then(({ data }) => {
          setFeatured(data.data);
        });
      }
    });
  }

  const fetchVariant = async () => {
    await axios.get(BASE_URL + '/api/manage-volume').then(({ data }) => {
      setVariant(data.data);
    });
  }

  function AddWishlist(id) {
    if (token) {
      setError('');
      axios.post(BASE_URL + '/api/add-to-wishlist', {
        "product_id": id,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then(({ data }) => {
        FetchWishCounts();
        if (data.status === true) {
          setError('')
          Swal.fire({
            text: data.message,
            icon: "success"
          })
          document.getElementById("ThankYou").reset();
        }
        if (data.status === false) {
          Swal.fire({
            text: data.message,
            icon: "error"
          })
        }
      }).catch(({ response }) => {
        setError(response.data)
        if (response.status === "422") {
          setError(response.data.message)
        }
      });
    } else { Swal.fire("Kindly Login First") }
  }

  function AddWishlistUnauth(id) {
    if (!token) {
      setError('');
      axios.post(BASE_URL + '/api/add-to-wishlist-unauth', {
        "product_id": id,
        "device_id": machineId
      }).then(({ data }) => {
        FetchWishlistUnauth();
        if (data.status === true) {
          setError('')
          Swal.fire({
            text: data.message,
            icon: "success"
          })
          document.getElementById("ThankYou").reset();
        }
        if (data.status === false) {
          Swal.fire({
            text: data.message,
            icon: "error"
          })
        }
      }).catch(({ response }) => {
        setError(response.data)
        if (response.status === "422") {
          setError(response.data.message)
        }
      });
    } else { Swal.fire("Kindly Login First") }
  }

  function handleSubmit(id, optionId) {
    setOpen(true)
    setError('');
    axios.post(BASE_URL + '/api/store-cart', { "product_id": id, "product_option_id": optionId, "quantity": countproduct },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then(({ data }) => {
        FetchItemsAuth()
        if (data.success === true) {
          setError('')
          Swal.fire({
            text: data.meassge,
            icon: "success"
          })
          document.getElementById("loginpage").reset();
        }
        if (data.success === false) {
          Swal.fire({
            text: data.meassge,
            icon: "error"
          })
        }
      }).catch(({ response }) => {
        setError(response.data.message)
        if (response.status_code === "422") {
          setError(response.data.message)
        }
      });
  }

  function getMachineId() {

    let machineId = localStorage.getItem('MachineId');

    if (!machineId) {
      machineId = crypto.randomUUID();
      localStorage.setItem('MachineId', machineId);
    }
  }

  function handleSubmitOffline(id, optionId) {
    setOpen(true)
    setError('');
    axios.post(BASE_URL + '/api/store-cart-unauth', { "product_id": id, "product_option_id": optionId, "device_id": machineId, "quantity": countproduct }, {}).then(({ data }) => {
      fetchCount()
      if (data.success === true) {
        setError('')
        Swal.fire({
          text: data.meassge,
          icon: "success"
        })
        document.getElementById("loginpage").reset();
      }
      if (data.success === false) {
        Swal.fire({
          text: data.meassge,
          icon: "error"
        })
      }
    }).catch(({ response }) => {
      setError(response.data.message)
      if (response.status_code === "422") {
        setError(response.data.message)
      }
    });
  }

  function AddCart(id, optionId) {
   
      setOpen(true)
      setError('');
      axios.post(BASE_URL + '/api/store-cart', { "product_id": id, "product_option_id": optionId, "quantity": countproduct },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          }
        }).then(({ data }) => {
          FetchItemsAuth()
          if (data.success === true) {
            setError('')
            Swal.fire({
              text: data.meassge,
              icon: "success"
            })
            navigate('/cart')
          }
          if (data.success === false) {
            Swal.fire({
              text: data.meassge,
              icon: "error"
            })
          }
        }).catch(({ response }) => {
          setError(response.data.message)
          if (response.status_code === "422") {
            setError(response.data.message)
          }
        });
  }

  function AddCartOffline(id, optionId) {
      setOpen(true)
      setError('');
      axios.post(BASE_URL + '/api/store-cart-unauth', { "product_id": id, "product_option_id": optionId, "device_id": machineId, "quantity": countproduct }, {}).then(({ data }) => {
        fetchCount()
        if (data.success === true) {
          setError('')
          Swal.fire({
            text: data.meassge,
            icon: "success"
          })
          navigate('/cart')
        }
        if (data.success === false) {
          Swal.fire({
            text: data.meassge,
            icon: "error"
          })
        }
      }).catch(({ response }) => {
        setError(response.data.message)
        if (response.status_code === "422") {
          setError(response.data.message)
        }
      });
  }


  return (
    <div>
      <div className='col-md-12 col-xs-12 nopadding'>
        <nav aria-label="breadcrumb" >
          <ol className='breadcrumb'>
            <li class="breadcrumb-item  breadcrumb-padding"><a href="/"><span>Home</span></a><img src="/Icons/SigleSideArrow.png" className="breadcrumb-icon" /> </li>
            <li class="breadcrumb-item  breadcrumb-padding"><a href="#"><span>Shop</span></a><img src="/Icons/SigleSideArrow.png" className="breadcrumb-icon" /> </li>
            {/* <li class="breadcrumb-item  breadcrumb-padding"><a href="#"><span>Dryfruits</span></a><img src="/Icons/SigleSideArrow.png" className="breadcrumb-icon" /> </li> */}
            <li className="breadcrumb-item-active" aria-current="page">{productData && productData.name}</li>
          </ol>
        </nav>
      </div>
      <div className={`col-md-12 col-sm-12 col-xs-12 ${style.container}`}>
        <div className='col-md-9 col-xs-12'>
          <div className='col-md-6 col-sm-6 col-xs-12 nopadding'>
            <div className={`col-md-12 col-xs-12 col-sm-12 nopadding ${style.ImgCon}`}>
              <img src={BASE_URL + '/storage/' + productData.image} alt="perfume" className={style.mainImg} />
            </div>
            <div className={`col-md-12 col-sm-12 col-xs-12 nopadding hiddenMobile ${style.marginTop10}`}>

              <div className="col-md-3 nopadding flex" >
                {productData && productData.product_option_images.map((data) => (
                  <div className={style.prodSmall}>
                    <img src={BASE_URL + '/storage/' + data.image} alt="perfume" className={style.prodSmallImg} />
                  </div>
                ))}

              </div>

            </div>
          </div>
          <div className='col-md-6 col-sm-12 col-xs-12'>
            <div className='mt-2 space-between' >
              <div className='product-name'>{productData && productData.name}</div>
              <div className={style.wishCont}> <img src="/Icons/heart.png" alt="Add to wishList" className={`pointer ${style.wishIcon}`} onClick={() => token ? AddWishlist(productData.product_options[0].product_id) : AddWishlistUnauth(productData.product_options[0].product_id)} /> </div>
            </div>

            {productData.rating == '0' ?
              '' : productData.rating == '1' ?
                <div className="flex">
                  <img src="/Icons/entypo_star.png" alt="rating" className="margin-left-right5" />
                  <img src="/Icons/star.png" alt="rating" className="margin-left-right5" />
                  <img src="/Icons/star.png" alt="rating" className="margin-left-right5" />
                  <img src="/Icons/star.png" alt="rating" className="margin-left-right5" />
                  <img src="/Icons/star.png" alt="rating" className="margin-left-right5" />
                </div>
                : productData.rating == '2' ?
                  <div className="flex">
                    <img src="/Icons/entypo_star.png" alt="rating" className="margin-left-right5" />
                    <img src="/Icons/entypo_star.png" alt="rating" className="margin-left-right5" />
                    <img src="/Icons/star.png" alt="rating" className="margin-left-right5" />
                    <img src="/Icons/star.png" alt="rating" className="margin-left-right5" />
                    <img src="/Icons/star.png" alt="rating" className="margin-left-right5" />
                  </div> : productData.rating == '3' ?
                    <div className="flex">
                      <img src="/Icons/entypo_star.png" alt="rating" className="margin-left-right5" />
                      <img src="/Icons/entypo_star.png" alt="rating" className="margin-left-right5" />
                      <img src="/Icons/entypo_star.png" alt="rating" className="margin-left-right5" />
                      <img src="/Icons/star.png" alt="rating" className="margin-left-right5" />
                      <img src="/Icons/star.png" alt="rating" className="margin-left-right5" />
                    </div> : productData.rating == '4' ?
                      <div className="flex">
                        <img src="/Icons/entypo_star.png" alt="rating" className="margin-left-right5" />
                        <img src="/Icons/entypo_star.png" alt="rating" className="margin-left-right5" />
                        <img src="/Icons/entypo_star.png" alt="rating" className="margin-left-right5" />
                        <img src="/Icons/entypo_star.png" alt="rating" className="margin-left-right5" />
                        <img src="/Icons/star.png" alt="rating" className="margin-left-right5" />
                      </div> : productData.rating == '5' ?
                        <div className="flex">
                          <img src="/Icons/entypo_star.png" alt="rating" className="margin-left-right5" />
                          <img src="/Icons/entypo_star.png" alt="rating" className="margin-left-right5" />
                          <img src="/Icons/entypo_star.png" alt="rating" className="margin-left-right5" />
                          <img src="/Icons/entypo_star.png" alt="rating" className="margin-left-right5" />
                          <img src="/Icons/entypo_star.png" alt="rating" className="margin-left-right5" />
                        </div> : ''

            }

            <div className="flex mt-2">
              <div className={style.disPrize}> INR {productData.product_options && productData.product_options[0].mrp} </div>
              <div className={style.prize}> INR {productData.product_options && productData.product_options[0].price} </div>
              {productData.product_options && productData.product_options[0].discount_percentage == '0' && null ? '' :
                <div className={style.discount}>{productData.product_options && productData.product_options[0].discount_percentage} %</div>
              }
            </div>

            <div className="mt-2"> <LongText content={productData.short_description} limit={180} /> </div>
            <br />
            <div >
              <div className='flex'>Category : <div className={`bold ${style.ml5}`}>  {productData.categories && productData.categories.name}</div> </div>
              <div className='mt-2 flex'>Product Id : <div className={`bold ${style.ml5}`}>  {productData.sku && productData.sku}</div> </div>
            </div>
            <div className='col-md-12 col-xs-12 col-xs-12 nopadding '>
              <div className='col-md-6 col-xs-12 col-sm-12 nopadding mt-2'>
                {fregrance && fregrance.length == 0 ?
                  <div className='flex'>
                    <div>Fregrance : </div>


                    <div className={style.marginLeft}>
                      {fregrance && fregrance.map((data) => (
                        <div>{data.title && data.title}</div>
                      ))}
                    </div>
                  </div>
                  :

                  <div className='flex'>
                    <div>Select Fregrance: </div>


                    <div className={style.marginLeft}>
                      {/* <Dropdown>
                        <Dropdown.Toggle variant="primary" id="dropdown-basic" className={style.dropdown} >
                          {freg} <ArrowDropDownIcon />
                        </Dropdown.Toggle>

                        <Dropdown.Menu >
                          {fregrance && fregrance.map((data) => (

                            <Dropdown.Item onClick={() => setFreg(data.title)} className="p10 flex">
                              {data.title && data.title}
                            </Dropdown.Item>
                          ))}


                        </Dropdown.Menu>
                      </Dropdown> */}
                      <Form.Select onClick={(e) => setFreg(e.target.value)}>
                        <option value={fregrance[0] && fregrance[0].title}>{fregrance[0] && fregrance[0].title}</option>
                        {fregrance && fregrance.map((data) => (

                          <option value={data.title}>
                            {data.title && data.title}
                          </option>
                        ))}

                      </Form.Select>
                    </div>
                  </div>
                }
              </div>
              <div className='col-md-6 col-xs-12 col-sm-12 mt-2'>
                <div className='flex'>
                  <div>Select Weight : </div>


                  <div className={style.marginLeft}>
                  <Form.Select onClick={(e) => setVariantData(e.target.value)}>
                        <option >
                        <div value={variant[0] && variant[0].quantity && variant[0] && variant[0].quantity_in}> {variant[0] && variant[0].quantity} </div>
                            <div className='ml2'>  {variant[0] && variant[0].quantity_in} </div>
                          </option>
                        {variant && variant.map((data) => (

                          <option value={variant[0] && variant[0].quantity && variant[0] && variant[0].quantity_in}>
                              <div> {data.quantity && data.quantity} </div>
                            <div className='ml2'>  {data.quantity_in && data.quantity_in} </div>
                          </option>
                        ))}

                      </Form.Select>
                    {/* <Dropdown>
                      <Dropdown.Toggle variant="primary" id="dropdown-basic" className={style.dropdown}>{variantData}<ArrowDropDownIcon /></Dropdown.Toggle>
                      <Dropdown.Menu>
                        {variant && variant.map((data) => (

                          <Dropdown.Item onClick={() => setVariantData(data.quantity, data.quantity_in)} className="flex center p10">

                            <div> {data.quantity && data.quantity} </div>
                            <div className='ml2'>  {data.quantity_in && data.quantity_in} </div>


                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown> */}
                  </div>
                </div>
              </div>
            </div>

            {/* <div className={`mt-2 col-md-12 col-xs-12 hiddenMobile  ${style.borderTop}`}></div> */}

            <div className='col-md-12 col-xs-12 col-sm-12 nopadding mt-2'>
              <div className='flex'>
                <div className={`flex ${style.counter}`}>
                  <div className={`margin-left-right5 pointer  ${style.counterFont}`} onClick={() => setCountProduct(countproduct == 1 ? countproduct : countproduct - 1)}>-</div>
                  <div className={style.counterNum}>{countproduct}</div>
                  <div className={`margin-left-right5 pointer ${style.counterFont}`} onClick={() => setCountProduct(countproduct + 1)}>+</div>
                </div>
                <button className={` ${style.CartBtn}`} onClick={() => token ? handleSubmit(productData.product_options[0].product_id && productData.product_options[0].product_id, productData.product_options[0].id && productData.product_options[0].id) : handleSubmitOffline(productData.product_options[0].product_id && productData.product_options[0].product_id, productData.product_options[0].id && productData.product_options[0].id)}> Add to Cart</button>
                <button className={style.buyBtn} onClick={() => token ? AddCart(productData.product_options[0].product_id && productData.product_options[0].product_id, productData.product_options[0].id && productData.product_options[0].id) : AddCartOffline(productData.product_options[0].product_id && productData.product_options[0].product_id, productData.product_options[0].id && productData.product_options[0].id)}>Buy Now</button>
              </div>
            </div>

            <div className='col-md-12 nopadding '>
              <div className={`mt-2 ${style.borderTop}`}></div>
              <div className='col-md-6 nopadding'> <div className={`mt-2 ${style.cod}`}><img src="/Icons/true.png" alt="" className={style.codImg} /> Cash On Delivery</div> </div>
              <div className='col-md-6 nopadding'> <div className={`mt-2 ${style.cod}`}><img src="/Icons/true.png" alt="" className={style.codImg} /> Quality Assured  </div> </div>
            </div>
            <div className='col-md-12 nopadding'>
              <div className='col-md-6 nopadding'> <div className={`mt-2 ${style.cod}`}><img src="/Icons/close.png" alt="" className={style.codImg} /> Cancellation Allowed</div> </div>
              <div className='col-md-6 nopadding'> <div className={`mt-2 ${style.cod}`}><img src="/Icons/true.png" alt="" className={style.codImg} /> Free Shipping </div> </div>
            </div>
            {/*             
            <div>Tags: Hot</div> */}
            <div className={`mt-2 col-md-12 col-xs-12 ${style.borderTop}`}></div>
          </div>
          <div className={`col-md-12 col-xs-12 ${style.Tabs}`}>
            <ProductDetailsTab
              Discription={
                <div dangerouslySetInnerHTML={{ __html: productData.description && productData.description }}></div>
              }
              Info={
                <div dangerouslySetInnerHTML={{ __html: productData.additional_information && productData.additional_information }}></div>
              }
              ShippingInfo={
                <div dangerouslySetInnerHTML={{ __html: productData.shipping_information && productData.shipping_information }}></div>
              }
              Reviews={
                productData.product_review && productData.product_review.map((data) => (
                  <>
                    <div className='flex marginTop'>
                      <div className='bold'>Customer Name : </div>
                      <div sclassName="ml2"> {data.customer_name && data.customer_name}</div>
                    </div>
                    <div className='flex marginTop'>
                      <div className='bold'>Review : </div>
                      <div sclassName="ml2"> {data.review && data.review}</div>
                    </div>
                    <div className='marginTop'>
                      {data.rating == '0' ?
                        '' : data.rating == '1' ?
                          <div className="flex">
                            <img src="/Icons/entypo_star.png" alt="rating" className="margin-left-right5" />
                            <img src="/Icons/star.png" alt="rating" className="margin-left-right5" />
                            <img src="/Icons/star.png" alt="rating" className="margin-left-right5" />
                            <img src="/Icons/star.png" alt="rating" className="margin-left-right5" />
                            <img src="/Icons/star.png" alt="rating" className="margin-left-right5" />
                          </div>
                          : data.rating == '2' ?
                            <div className="flex">
                              <img src="/Icons/entypo_star.png" alt="rating" className="margin-left-right5" />
                              <img src="/Icons/entypo_star.png" alt="rating" className="margin-left-right5" />
                              <img src="/Icons/star.png" alt="rating" className="margin-left-right5" />
                              <img src="/Icons/star.png" alt="rating" className="margin-left-right5" />
                              <img src="/Icons/star.png" alt="rating" className="margin-left-right5" />
                            </div> : data.rating == '3' ?
                              <div className="flex">
                                <img src="/Icons/entypo_star.png" alt="rating" className="margin-left-right5" />
                                <img src="/Icons/entypo_star.png" alt="rating" className="margin-left-right5" />
                                <img src="/Icons/entypo_star.png" alt="rating" className="margin-left-right5" />
                                <img src="/Icons/star.png" alt="rating" className="margin-left-right5" />
                                <img src="/Icons/star.png" alt="rating" className="margin-left-right5" />
                              </div> : data.rating == '4' ?
                                <div className="flex">
                                  <img src="/Icons/entypo_star.png" alt="rating" className="margin-left-right5" />
                                  <img src="/Icons/entypo_star.png" alt="rating" className="margin-left-right5" />
                                  <img src="/Icons/entypo_star.png" alt="rating" className="margin-left-right5" />
                                  <img src="/Icons/entypo_star.png" alt="rating" className="margin-left-right5" />
                                  <img src="/Icons/star.png" alt="rating" className="margin-left-right5" />
                                </div> : data.rating == '5' ?
                                  <div className="flex">
                                    <img src="/Icons/entypo_star.png" alt="rating" className="margin-left-right5" />
                                    <img src="/Icons/entypo_star.png" alt="rating" className="margin-left-right5" />
                                    <img src="/Icons/entypo_star.png" alt="rating" className="margin-left-right5" />
                                    <img src="/Icons/entypo_star.png" alt="rating" className="margin-left-right5" />
                                    <img src="/Icons/entypo_star.png" alt="rating" className="margin-left-right5" />
                                  </div> : ''

                      }
                    </div>
                  </>
                ))
              }
            />
          </div>
        </div>
        <div className='col-md-3 col-xs-12'>
          <div className='col-md-12 col-xs-12'>
            <div className={style.discountBanner} style={{ backgroundImage: `url("/Image/PerfumeBackground.jpg")` }}>
              <div className={style.innerCard}>
                <div className={style.cardContent}>
                  <div className={style.cardMargin}>
                    <h1> {homeData && homeData.heading}</h1><br />
                    <h3 dangerouslySetInnerHTML={{ __html: homeData && homeData.content }}></h3>
                    <br />
                    <a href={homeData.url}><button className={style.shopButton}>Shop Now <ArrowForwardIcon /> </button> </a>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className='col-md-12 col-xs-12'>
            {/* {featured.length == null ? "" :  */}
            <div className={style.FeatureBox}>
              <div className="featured-heading">Featured Product</div>
              <div className={style.borderTop}></div>
              {featured && featured.map((i) => (
                <div className={`flex ${style.marginTop10}`}>
                  <div>
                    <img src={BASE_URL + '/storage/' + i.image} className={style.featureImg} />
                  </div>
                  <div className={style.marginLeft}>
                    <div className='bold'>{i.name && i.name}</div>
                    <div>{i.short_description && i.short_description.length > 25 ? i.short_description.substring(0, 25) + '...' : i.short_description}</div>
                    <div className='bold'>INR {i.product_options && i.product_options[0].mrp}</div>
                  </div>
                  <div></div>
                </div>
              ))}
            </div>
            {/* } */}
          </div>
        </div>
        {/* <Modal open={open} onClose={() => setOpen(false)} >

<Box sx={{ ...Style, width: 200 }}>
  <div>{modalData ? modalData.meassge && modalData.meassge : ''}</div>
</Box>

</Modal> */}
      </div>
    </div>
  )
}
