import style from '../../styles/header.module.css';
import axios from 'axios';
import { BASE_URL } from '../../config';
import { useState, useEffect } from 'react';
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 280,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export default function CustomizedMenus() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [expanded, setExpanded] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = panel => (event, isExpanded) => {

    setExpanded(isExpanded ? panel : false);
  };

  const [categories, setCategories] = useState([])

  useEffect(() => {
    FetchCategories();
  }, [])

  const FetchCategories = async () => {
    await axios.get(BASE_URL + '/api/product-categories').then(({ data }) => {
      setCategories(data.data)
      // console.log(data.data)
    })
  }

  return (
    <div>
      <div className={` pointer ${style.perfumerButton}`}>
        <li onClick={handleClick} className={style.listStyleNone}>
          <div >
            <div className={`flex ${style.space}`}>
              <div className='flex'>
                <div className={style.buttonContent}><img src="/Icons/feather_bar-chart-2.png" /></div>
                <div className={style.buttonText}> Our Products </div>
              </div>
              <div>
                <div className={style.downArrow}><img src="/Icons/downArrow.png" /></div>

              </div>
            </div>
          </div>
        </li>
      </div>
      {/* <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Options
      </Button> */}
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      // className={style.padding20}
      >
        {categories && categories.map((data, key) => (
          <>

            <Accordion
              expanded={expanded == data.id}
              key={data.id}
              onChange={data && data.direct_childs.length == 0 ? "" : handleChange(data.id)}
              className={`${style.drop} ${style.margin5}`}
            >
              <Link to= {data && data.direct_childs.length == 0 ? "/our-products" : ""} state={{ data: data.id }}>
                <AccordionSummary
                  expandIcon={data && data.direct_childs.length == 0 ? "" : <ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  onClick={data.direct_childs.length ? "" : handleClose}
                >
                                   <h4>{data.name && data.name}</h4>

                </AccordionSummary>
              </Link>
              <AccordionDetails>
                {data && data.direct_childs && data.direct_childs.map((subcategory, keym) => (
                  <Link to="/our-products" state={{ data: subcategory.id }}>
                    <div className='pointer'>
                      <div onClick={handleClose}> <Link to="/our-products" state={{ cate: subcategory.id }}>{subcategory.name && subcategory.name} </Link> </div>
                    </div>
                  </Link>
                ))}
              </AccordionDetails>

            </Accordion>
            {/* } */}
          </>
        ))}

      </StyledMenu>
    </div>
  );
}

