import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import style from '../../../styles/testimonial.module.css';
import axios from 'axios';
import { BASE_URL } from '../../../config';
import { faTrashAlt, faEdit, faEye, faComment, faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const data = [
    {
        id: 1,
        name: 'Dorothy Greer',
        review: 'For me personally, a healthy way of living was a mysterious and unrealistic theory that I was never in a mood to Explore. No, with your help and guidance I am to start a new life!',
        img: "/Image/UserImage.png"
    },
    {
        id: 2,
        name: 'Dorothy',
        review: 'For me personally, a healthy way of living was a mysterious and unrealistic theory that I was never in a mood to Explore. No, with your help and guidance I am to start a new life!',
        img: "/Image/UserImage.png"
    },
    {
        id: 3,
        name: 'Dorothy',
        review: 'For me personally, a healthy way of living was a mysterious and unrealistic theory that I was never in a mood to Explore. No, with your help and guidance I am to start a new life!',
        img: "/Image/UserImage.png"
    },
]

function Testimonial() {

    const [homeData, setHomeData] = useState([]);
    const [imageUrl, setImagaUrl] = useState([]);
    const [testimonials, setTestimonials] = useState([]);


    useEffect(() => {
        fetchHomeWidgets();
        fetchTestomials();
    }, [])


    const fetchHomeWidgets = async () => {
        await axios.get(BASE_URL + '/api/homepagewidget').then(({ data }) => {
            setHomeData(data.data.data[5]);
            setImagaUrl(data.data.image_base_url)
        })
    }

    const fetchTestomials = async () => {
        await axios.get(BASE_URL + '/api/manage-teams').then(({ data }) => {
            setTestimonials(data.data);
            console.log(data.data);
        })
    }


    return (
        <div className={` col-md-12 col-xs-12 ${style.mainDiv}`} style={{ backgroundImage: `url(${BASE_URL + '/storage/' + homeData.image})` }}>
            <Carousel
                showThumbs={false}
                autoPlay={false}
                interval={2500}>
                {testimonials && testimonials.map((data) => (
                    <div>
                        <div className={`col-md-12 col-xs-12 ${style.container}`}>
                            <div className={style.containerPadding}>
                                <div className={style.heading}>
                                    <div className={style.client}> {homeData.heading && homeData.heading} </div>
                                    {/* <div className={`${style.marginLeft} ${style.client}`}>Clients Says</div>  */}
                                </div>
                                <div className={style.storyText} dangerouslySetInnerHTML={{ __html: homeData.content && homeData.content }}></div> <br />
                                {data.image ?
                                    <div className={`  ${style.clientImage}`}>
                                        <img src={BASE_URL + "/storage/" + data.image} className="image-test" />
                                    </div> : ""}
                                <div className={`mt-1 ${style.review}`}>{data.message}</div>
                                <div className={`mt-1 ${style.name}`}>{data.first_name} {data.last_name}</div>
                                <div className={`mt-1 ${style.position}`}>
                                    {data && data.rating == '0' ? "" : <div> {data && data.rating == '1' ? <FontAwesomeIcon icon={faStar} color="orange" /> : data && data.rating == '2' ? <><FontAwesomeIcon icon={faStar} color="orange" /> <FontAwesomeIcon icon={faStar} color="orange" /></> :
                                        data && data.rating == '3' ? <> <FontAwesomeIcon icon={faStar} color="orange" /> <FontAwesomeIcon icon={faStar} color="orange" /> <FontAwesomeIcon icon={faStar} color="orange" /> </> : data && data.rating == '4' ?
                                            <><FontAwesomeIcon icon={faStar} color="orange" /> <FontAwesomeIcon icon={faStar} color="orange" /> <FontAwesomeIcon icon={faStar} color="orange" /> <FontAwesomeIcon icon={faStar} color="orange" /> </> : <><FontAwesomeIcon icon={faStar} color="orange" /> <FontAwesomeIcon icon={faStar} color="orange" /> <FontAwesomeIcon icon={faStar} color="orange" /> <FontAwesomeIcon icon={faStar} color="orange" /> <FontAwesomeIcon icon={faStar} color="orange" /> </>} </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </Carousel>

        </div>
    )
}

export default Testimonial;