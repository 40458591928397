import React, { useEffect, useState, useContext }  from 'react';
import style from '../../../styles/Banner.module.css';
import axios from 'axios';
import { BASE_URL } from '../../../config';



function IzharsonBanner(){

    const [homeData, setHomeData] = useState([]);

    useEffect(() => {
        fetchHomeWidgets();
      }, [])
    

    const fetchHomeWidgets = async () => {
        await axios.get(BASE_URL + '/api/homepagewidget').then(({ data }) => {
          setHomeData(data.data.data[1]);
          // console.log(data.data)
        })
      }


    return(
        <div className='col-md-12 col-xs-12 nopadding'>
            <div className={`col-md-12 col-xs-12 ${style.bannerContainer}`} style={{backgroundImage:`url(${BASE_URL+'/storage/'+homeData.image})`}}>
                <div className='col-md-6 col-xs-12'>
                    <div className={style.mensPerfume}>{homeData.heading && homeData.heading}</div>
                    <div className={style.bannerDetail} dangerouslySetInnerHTML={{__html:homeData.content&& homeData.content}}></div>
                    <div className='bannerBtn'>
                   <a href={homeData.url}> <button className={` ${style.button}`}>{homeData.url_txt}</button> </a>
                   </div>
                </div>
            </div>
        </div>
    )
}

export default IzharsonBanner;