import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation, Navigate } from 'react-router-dom';

import { Row, Container, Col, Form, Dropdown, Button, Tab, Tabs } from 'react-bootstrap';
import { NavLink, Link } from 'react-router-dom';
import axios from 'axios'
import { BASE_URL } from '../../../config';


const OrderDetail = () => {
    const [orderDetails, setOrderDetails] = useState([]);
    const token = localStorage.getItem('token');
    const navigate = useNavigate();

    useEffect(() => {
        OrderDetails()
    }, [])

    const {state} = useLocation();
    // console.log(state)

    const OrderDetails = async () => {
        axios.get(BASE_URL + '/api/order/'+state.OrderID, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }).then(({ data }) => {
            setOrderDetails(data.data);
        })
    }
 


    return (
        <>
       
            
                        <div className='p-3' >
                            <div className="col-md-12 col-xs-12 col-sm-12">
                                <div className="">
                                    <div className="">
                                        <h1>Order Details</h1>


                                    </div>

                                    <div className='col-md-12 col-xs-12 col-sm-12 order-detail-border'>
                                      <div className='mt-4 '>
                                        <div className='col-md-4 col-xs-4 col-sm-4'>
                                        <p className='text-muted bold'>Order Date <span className='text-link'>{orderDetails && orderDetails.created_at}</span></p>   
                                        </div>
                                        <div className='col-md-4 col-xs-4 col-sm-4'>
                                        <p className='text-muted bold'>Order Number<span className='text-link'> #{orderDetails.order && orderDetails.order.order_number}</span></p>
                                        </div>
                                      </div>
                                    


                                    <div >
                                        <div >
                                            <div className='col-md-12 col-xs-12 col-sm-12 '>
                                                <div className='col-md-3 col-xs-12 col-sm-6 mb-2 mt-2'> 
                                             <span className='bold'>Billing to</span>
                                                    <div className='pt-2'>
                                                    {orderDetails.order && orderDetails.order.billingaddress.name}
                                                        <br />{orderDetails.order && orderDetails.order.billingaddress.address}
                                                        <br />{orderDetails.order && orderDetails.order.billingaddress.citys.name}
                                                        <br />{orderDetails.order && orderDetails.order.billingaddress.states.name},{orderDetails.order && orderDetails.order.billingaddress.pincode}
                                                        <br />{orderDetails.order && orderDetails.order.billingaddress.countries.name}

                                                    </div>

                                                </div>
                                                <div className='col-md-3 col-xs-12 col-sm-6 mb-2 mt-2' ><span className='bold'>Shipping to</span>
                                                <div className='pt-2'>
                                                {orderDetails.order && orderDetails.order.shippingaddress.name}
                                                        <br />{orderDetails.order && orderDetails.order.shippingaddress.address}
                                                        <br />{orderDetails.order && orderDetails.order.shippingaddress.citys.name}
                                                        <br />{orderDetails.order && orderDetails.order.shippingaddress.states.name},{orderDetails.order && orderDetails.order.billingaddress.pincode}
                                                        <br />{orderDetails.order && orderDetails.order.shippingaddress.countries.name}

                                                    </div>
                                                </div>
                                                <div className='col-md-3 col-xs-12 col-sm-6 mb-2 mt-2'><span className='bold'>Payment Method</span>
                                                <div className='pt-2'>
                                                {orderDetails.order && orderDetails.order.payment_method}
                                                
                                                {/* {t("productTab.shipDetail.deliPay")}
                                                        <br />{t("productTab.shipDetail.mode")}
                                                        <br />{t("productTab.shipDetail.cash")}
                                                        <br />{t("productTab.shipDetail.cod")} */}
                                                       
                                                    </div>
                                                </div>
                                               
                                                <div className='col-md-3 col-xs-12 col-sm-6 mb-2 mt-2'><span className='bold'>Order Summary</span>
                                                <div className='pt-2'>
                                                Sub Total: <span>$ {orderDetails.order && orderDetails.order.order_amount_with_gst}</span>
                                                        
                                                        <br />Shipping: <span>${orderDetails.order && orderDetails.order.shipping_type_price}</span>
                                                        <br />Order Amount: <span>${orderDetails.order && orderDetails.order.order_amount_with_shipping}</span>
                                                        
                                                       
                                                    </div>
                                                </div>

                                            </div>
                                        </div>


                                    </div>
                                    </div>
                                    <div className='col-md-12 col-xs-12 col-sm-12 text-right'>
                                        <button onClick={() => navigate('/dashboard')} className='dash-btn'>Back to Dashboard</button>

                                    </div>
                                </div>
                            </div>
                        </div> 
        </>
    );
};
export default OrderDetail;
