import React, { useState } from "react";
import { Tabs, Tab } from "@mui/material";
import style from '../styles/ProductDetails.module.css';

export default function DetailTab({Info,ShippingInfo,Discription,Reviews}) {
    const [tabvalue, setTabValue] = useState(0);

    const handleTabsChange = (event, newValue) => {
        setTabValue(newValue);
    };
    return (
        <div className="App">

            <Tabs
                onChange={handleTabsChange}
                value={tabvalue}
                indicatorColor="red"
                textColor="secondary"

                className={`bold ${style.borderBottom}`}
            >
                <Tab label="Description" sx={{fontSize: 14 , fontWeight:'bold'}} />
                <Tab className="bold" label="Additional Information"  sx={{ fontSize: 14,fontWeight:'bold' }} />
                <Tab className="bold" label="Shipping & Returns" sx={{ fontSize: 14 , fontWeight:'bold'}} />
                <Tab className="bold" label="Reviews" sx={{ fontSize: 14,fontWeight:'bold' }} />
            </Tabs>

            {tabvalue === 0 && <div className={style.TabContent}>{Discription}</div>}
            {tabvalue === 1 && <div className={style.TabContent}>{Info}</div>}
            {tabvalue === 2 && <div className={style.TabContent}>{ShippingInfo}</div>}
            {tabvalue === 3 && <div className={style.TabContent}>{Reviews}</div>}
        </div>
    );
}

