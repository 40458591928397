import React, { useState, useEffect } from 'react';
import style from '../../../styles/aboutus.module.css'
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Testimonial from '../Homepage/Testimonial';
import About from './About';
import axios from 'axios';
import { BASE_URL } from '../../../config';


function AboutUsDetail() {

  const [expanded, setExpanded] = React.useState(false);
  const [aboutdata, setaboutdata] = useState('');
  const [faqs, setFaqs] = useState();
  const [faqs2, setFaqs2] = useState();
  const [count, setCount] = useState();


  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    fetchaboutus();
    fetchFaqs();
    fetchFaqs2();
  }, [])

  const fetchaboutus = async () => {
    await axios.get(BASE_URL + '/api/about-us').then(({ data }) => {
      setaboutdata(data.data);

    })
  }

  const fetchFaqs = async () => {
    await axios.get(BASE_URL + '/api/faqs').then(({ data }) => {
      setFaqs(data.data);
      var leftSide = data.data.splice(0, Math.floor(data.data.length / 2));
      setCount(leftSide)
    })
  }

  const fetchFaqs2 = async () => {
    await axios.get(BASE_URL + '/api/faqs').then(({ data }) => {
      setFaqs2(data.data);
      var leftSide = data.data.splice(count, Math.floor(data.data.length / 2));

    })
  }



  return (
    <div className='col-md-12 col-xs-12 nopadding'>
      <div className={`col-md-12 col-xs-12 ${style.header}`}>
        <div className={style.heading}> About Us</div>
        <div >
          <nav aria-label="breadcrumb" >
            <ol className={`breadcrumb ${style.breadcrumb}`}>
              <li class="breadcrumb-item  breadcrumb-padding"><a href="#"><span>home</span></a><img src="/Icons/SigleSideArrow.png" className="breadcrumb-icon" /> </li>
              <li className="breadcrumb-item-active" aria-current="page">About Us</li>
            </ol>
          </nav>
        </div>
      </div>
      <div className={`col-md-12 col-xs-12 ${style.content}`}>
        <div className={`col-md-12 col-xs-12 ${style.aboutHeading}`}>{aboutdata.title && aboutdata.title}</div>
        <div className='col-md-12 col-xs-12 mt-4'>
          <div className={`col-md-6 col-xs-12 col-sm-12 ${style.contentData}`}>
            {aboutdata.description && aboutdata.description}

          </div>
          <div className='col-md-6'>
            <img src={BASE_URL + '/storage/' + aboutdata.image} className={style.aboutImg} />
          </div>
        </div>

        <div className={`col-md-12 col-xs-12 col-sm-12 ${style.contentData} ${style.p15}`} dangerouslySetInnerHTML={{ __html: aboutdata.content && aboutdata.content }}></div>
        <div>
        </div>


      </div>
      <div className={`col-md-6 col-xs-12 ${style.content}`}>
        {faqs && faqs.map(accordion => {
          const { id, question, answer } = accordion;
          return (
            <Accordion
              expanded={expanded === id}
              key={id}
              onChange={handleChange(id)}
              className={style.AccordionMargin}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"


              >
                <h5>{question && question}</h5>
              </AccordionSummary>
              <AccordionDetails >
                <div>{answer && answer}</div>
              </AccordionDetails>
            </Accordion>
          );
        })}

      </div>
      <div className={`col-md-6 col-xs-12 ${style.content}`}>
        {faqs2 && faqs2.map(accordion => {
          const { id, question, answer } = accordion;
          return (
            <Accordion
              expanded={expanded === id}
              key={id}
              onChange={handleChange(id)}
              className={style.AccordionMargin}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"


              >
                <h5>{question && question}</h5>
              </AccordionSummary>
              <AccordionDetails >
                <div>{answer && answer}</div>
              </AccordionDetails>
            </Accordion>
          );
        })}

      </div>
      <Testimonial />
      <About />
    </div>
  )
}

export default AboutUsDetail;