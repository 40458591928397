import React, { useState } from "react";
import { Tabs, Tab } from "@mui/material";
import style from '../../styles/Sidebar.module.css'
import { Nav } from "react-bootstrap";
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate, Link } from "react-router-dom";


export default function App() {
    const [tabvalue, setTabValue] = useState(0);

    const handleTabsChange = (event, newValue) => {
        setTabValue(newValue);
    };

    function Logout() {
        localStorage.removeItem("token");
        localStorage.removeItem("userdata");
        navigate('/')
    }

    const navigate = useNavigate();

    return (
        <div>

            <div className={`col-md-12 col-xs-12 nopadding ${style.container}`}>
                <div className="col-md-12 col-xs-8 col-sm-6 nopadding">
                    <div
                        className={`card`}
                    >
                        <Link className="sidebar-links" to="/dashboard">
                            <HomeIcon className={style.navbarMargin} /> Dashboard
                        </Link>


                        <Link className="sidebar-links" to="/my-orders">
                            <HomeIcon className={style.navbarMargin} /> My Orders
                        </Link>

                        <Link className="sidebar-links" to="/my-wishList">
                            <HomeIcon className={style.navbarMargin} /> My Wish List
                        </Link>

                        <Link className="sidebar-links" to="/my-address">
                            <HomeIcon className={style.navbarMargin} />My Address Book
                        </Link>

                        <Link className="sidebar-links" to="/change-password">
                            <HomeIcon className={style.navbarMargin} /> Change Password
                        </Link>
                        <Link className="sidebar-links" to="/settings">
                            <HomeIcon className={style.navbarMargin} /> My Account Setting
                        </Link>

                        {/* <Nav.Link className="sidebar-links" to='/settings'>
                            <HomeIcon className={style.navbarMargin} /> 
                        </Nav.Link> */}

                        <Nav.Link className="sidebar-links" onClick={() => Logout()}>
                            <HomeIcon className={style.navbarMargin} /> Logout
                        </Nav.Link>


                        {/* <Link icon={<img src="/Icons/Home.png" alt="Dashboard" />} iconPosition="start" sx={{display:'flex', fontSize: 12, borderBottom: '1px solid lightgray', justifyContent:'flex-start', minHeight: 0, padding: '10px'}} className={style.tabName}> Dashboard </Link>
                        <Tab icon={<img src="/Icons/Home.png" alt="Orders" />} iconPosition="start" label="My Orders" sx={{display:'flex', fontSize: 12, borderBottom: '1px solid lightgray', justifyContent:'flex-start', minHeight: 0, padding: '10px'}} className={style.tabName} />

                        <Tab icon={<img src="/Icons/Home.png" alt="Wish List" />} iconPosition="start" label=" My Wish List" sx={{display:'flex', fontSize: 12, borderBottom: '1px solid lightgray', justifyContent:'flex-start', minHeight: 0, padding: '10px'}} className={style.tabName} />
                        <Tab icon={<img src="/Icons/Home.png" alt="Address" />} iconPosition="start" label="My Address Book" sx={{display:'flex', fontSize: 12, borderBottom: '1px solid lightgray', justifyContent:'flex-start', minHeight: 0, padding: '10px'}} className={style.tabName} />
                        <Tab icon={<img src="/Icons/Home.png" alt="Account" />} iconPosition="start" label="My Account Setting" sx={{display:'flex', fontSize: 12, borderBottom: '1px solid lightgray', justifyContent:'flex-start', minHeight: 0, padding: '10px'}} className={style.tabName} />
                        <Tab icon={<img src="/Icons/Home.png" alt="Change Password" />} iconPosition="start" label="Change Password" sx={{display:'flex', fontSize: 12, borderBottom: '1px solid lightgray', justifyContent:'flex-start', minHeight: 0, padding: '10px'}} className={style.tabName} />
                        <Tab icon={<img src="/Icons/Home.png" alt="Invite Friends" />} iconPosition="start" label="Invite Friends" sx={{display:'flex', fontSize: 12, borderBottom: '1px solid lightgray', justifyContent:'flex-start', minHeight: 0, padding: '10px'}} className={style.tabName} />
                        <Tab icon={<img src="/Icons/Home.png" alt="Logout" />} onClick={() => Logout()} iconPosition="start" label="Logout" sx={{display:'flex', fontSize: 12, borderBottom: '1px solid lightgray', justifyContent:'flex-start', minHeight: 0, padding: '10px'}} className={style.tabName} /> */}
                    </div>

                </div>


            </div>
        </div>
    );
}
