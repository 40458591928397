import React,{useState, useEffect} from 'react';
import style from '../../../styles/footerlinks.module.css';
import axios from 'axios';
import { BASE_URL } from '../../../config';

function CookiePolicy(){
  
  const [ policy, setPolicy ] = useState('')

  useEffect(() => {
    fetchPolicy()
  },[])

  const fetchPolicy = async() => {
    await axios.get(BASE_URL+"/api/policy/cookie_policy").then(({data}) => {
      setPolicy(data.data)
    })
  }

    return(
        <div>
            <div className='col-md-12 col-xs-12 nopadding'>
            <nav aria-label="breadcrumb" >
          <ol className='breadcrumb'>
          <li class="breadcrumb-item  breadcrumb-padding"><a href="#"><span>Home</span></a><img src="/Icons/SigleSideArrow.png" className="breadcrumb-icon" /> </li>
            <li className="breadcrumb-item-active" aria-current="page">Cookie Policy</li>
          </ol>
        </nav>
                </div>
                <br /><br />
        <div className={style.padding30}>
            <h1 className={style.heading}>COOKIE POLICY</h1> <br /><br />
            {/* <ul className={style.list}>
                <li>
                There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look 
even slightly believable.
                </li>
                <li>If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.</li>
                <li>All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</li>
                <li>It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</li>
                <li>The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.</li>
                <li>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</li>
                <li>It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker 
including versions of Lorem Ipsum.</li>
                <li>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro 
quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat 
voluptatem.</li>
                <li>But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual 
teachings of the great explorer of the truth, the master-builder of human happiness.</li>
                <li>No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are 
extremely painful.</li>
            </ul> */}
            <div dangerouslySetInnerHTML={{__html: policy.content && policy.content}}></div>
        </div>
        </div>
    )
}

export default CookiePolicy;