import React, { useState, useEffect } from 'react';
import { Button, Checkbox } from '@mui/material'
import IconButton from "@mui/material/IconButton";
import FormControlLabel from '@mui/material/FormControlLabel';
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import style from '../../../styles/login.module.css';
import { Link, useNavigate } from 'react-router-dom';
import GoogleIcon from '@mui/icons-material/Google';
import { useLocation } from 'react-router-dom'
import { BASE_URL } from '../../../config';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useGoogleLogin } from '@react-oauth/google';
import { Box, Modal } from '@mui/material';
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const Style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const Login = () => {
  const [error, setError] = useState({});
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [open, setopen] = useState(false)
  const [googleUrl, setGoogleUrl] = useState();
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const { state } = useLocation();
  const path = window.location.origin
  const [veryModal, setVeryModal] = useState(false)

  let machineId = localStorage.getItem('MachineId');
  console.log(machineId);

  useEffect(() => {
    getMachineId();
    RegisteredOnGoogle();
  }, [])


  function getMachineId() {

    let machineId = localStorage.getItem('MachineId');

    if (!machineId) {
      machineId = crypto.randomUUID();
      localStorage.setItem('MachineId', machineId);
    }
  }

  const RegisteredOnGoogle = async () => {
    await axios.get(BASE_URL + '/api/auth').then(({ data }) => (
      setGoogleUrl(data.url)
    ))
  }

  function handleSubmit(e) {
    e.preventDefault();
    setError('');
    axios.post(BASE_URL + '/api/login', { 'email': email, 'password': password, 'device_id': machineId }).then(({ data }) => {
      if (data.status === "success") {
        setError('')
        localStorage.setItem('token', data.token)
        localStorage.setItem('userdata', JSON.stringify(data.data))
        //   console.log('userdata',JSON.stringify(data.data))
        Swal.fire({
          text: data.message,
          icon: "success"
        })
        navigate(state ? state.path : '/dashboard')
        document.getElementById("loginpage").reset();
      }
      if (data.status === "error") {
        Swal.fire({
          text: data.message,
          icon: "error"
        })
      }
      if (data.status === false) {
        Swal.fire({
          text: data.message,
          icon: "error"
        })
      }
    }).catch(({ response }) => {
      setError(response.data.message)
      if (response.status == 422) {
        setError(response.data.message)
      }
      if (response.status == 401) {
        setVeryModal(true)
        setError(response.data.message)
        // Swal.fire({
        //   text:response.data.message,
        //   icon:"error"

        // })

      }
    });
  }
  const login = useGoogleLogin({
    onSuccess: response => {
      axios.get("https://www.googleapis.com/oauth2/v3/userinfo", {
        headers: {
          "Authorization": `Bearer ${response.access_token}`
        }
      }).then(({ data }) => {
        axios.post(BASE_URL + '/api/google/login', { 'email': data.email, 'name': data.given_name, 'google_id': data.sub, 'device_id': machineId }).then(({ data }) => {
          localStorage.setItem('token', data.data.token)
          localStorage.removeItem('userdata');
          localStorage.setItem('userdata', JSON.stringify(data.data.user))
          navigate("/dashboard")
        })
      })


    },
  });

  function handleForgotPassword(e) {

    e.preventDefault();
    setError('');
    const data = {
      'email': email,
      'url': path + '/reset-password/'
    }

    axios.post(BASE_URL + '/api/forget-password', data).then(({ data }) => {
      if (data.status === true) {
        setError('')
        Swal.fire({
          text: data.meassge,
          icon: "success"
        })
      }
      if (data.success === false) {
        setError('')
        Swal.fire({
          text: data.errors.email,
          icon: "success"
        })
      }
    }).catch(({ response }) => {
      setError(response.data.message)
      if (response.data.code == 422) {
        Swal.fire({
          text: 'hdgvu'
        })
        setError(response.data.message)
      }
    });
  }

  
  function SendVerifyLink(e) {

    e.preventDefault();
    setError('');
    const data = {
      'email': email,
      'url': path + '/verify-email/'}
    axios.post(BASE_URL + '/api/send-email-verification', data).then(({ data }) => {
      setVeryModal(false)
      if(data.status == true){
        setError('')
        Swal.fire({
          text:data.message,
          icon:"success"
        })
      }
    }).catch(({response})=>{
      setError(response.data.message)
      if(response.status_code==="422"){
        setError(response.data.message)
        // //console.log(response.data.message);
      }
    });
  }



  return (
    <div>
      <div className={`col-md-4 col-sm-6  col-xs-12 col-md-offset-4 col-sm-offset-4 col-sm-offset-right-4 col-md-offset-right-4 ${style.container}`}>
        <div className={`card ${style.card}`}>
          <div className={`${style.loginText}`}>Login Account!</div>
          <div className='small-text'> Please login to your account to access your Dashboard </div>
          <div className={style.border}></div>
          <form>
            <input
              className={`mt-4 ${style.input}`}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email address"
              type="email"
              name="email"
              required

            />

            <div>
              <div className={` ${style.LoginInputCont}`}>
                <input
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                  type={open == true ? "text" : "password"}
                  name="name"
                  className={style.PassInput}
                />
                <div>
                  <IconButton >
                    <VisibilityOff onClick={() => setopen(!open)} sx={{ marginTop: 1 }} />
                  </IconButton>
                </div>

              </div>
            </div>
            <br />
            <small className={style.forgotPass} onClick={handleForgotPassword}> Forgot Password? </small>
            <br />
            <FormControlLabel control={<Checkbox
              {...label}
              defaultChecked
              sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
            />} label={<h6>I agree to the following terms and conditions</h6>} />
            <Link onClick={handleSubmit}> <button className={style.button}>Login</button> </Link> <br />  <br />
            <smal className={`${style.smal} ${style.small}`}>OR</smal> <br />
            <a className={style.googleButton} onClick={() => login()}>  <div className={style.GoogleIconCont}><img src="/Icons/google.png" className={style.GoogleIcon} /></div><div className={style.googleText}>Login with google</div> </a>

          </form>
          <br />
          <div className="flex center"><div className={style.member}>Not a Member</div> <Link to="/signup"><div className={style.account}>Create Account</div></Link></div>
        </div>
      </div>
      <div>

        <Modal open={veryModal} onClose={() => setVeryModal(false)}>
          <Box sx={Style} className="track-modal" >
            <div className='verify-email-text'>Your email id is not verified yet, Please verify your email id</div>
            <div className={`${style.loginText}`}>Verify Email!</div>
            <input
              className={`mt-1 ${style.input}`}
              // onChange={(e) => setEmail(e.target.value)}
              placeholder="Email address"
              type="email"
              name="email"
              required
              value={email}

            />
               <Link onClick={SendVerifyLink}> <button className={style.button}>Verify Now</button> </Link>
          </Box>
        </Modal>
      </div>
    </div>
  )
}

export default Login;